import React, { useCallback, useEffect, useReducer, useRef } from 'react'
import ChatIntro from '../../../Chat/MessagesArea/ChatIntro'
import { lockedReducer } from '../../../../utils/defaultReducer'
import api from '../../../../api'
import useNotification from '../../../Notification'
import { getSession } from '../../../../auth'
import { groupIcons, transformTimeData } from '../ChatList'
import {
    Box, Avatar, List, ListItem, Tooltip, Button
} from '@mui/material'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ExportIcon from '@mui/icons-material/GetApp';


import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ShowComponent } from '../../../../utils/isVisible'
import EmailDialog from '../EmailDialogs.js'
import EditList from '../EditList/index.js'

import ScoutMessageComponent from '../ScoutMessageComponent/index.js'

import { chatName } from '../../../../utils/scout'


const defaultLimit = 20

let timeout = 0

const loadMore = async (activeChat, messages, setState, windowRef, cursor = 0) => {
    if ([-1, 0].includes(cursor)) return
    const enterprise = getSession().profile.enterprise;
    const params = new URLSearchParams({
        limit: defaultLimit,
        skip: cursor,
        sort: '-messageTimestamp'
    });
    const previousScrollHeight = windowRef.current?.scrollHeight || 0
    clearTimeout(timeout)
    setState({ lock: true })
    const res = await api.get(`api/enterprises/${enterprise.id}/scout_accounts/${activeChat.account_id}/messages/${activeChat.id}/?${params.toString()}`);
    if (res.status === 200) {
        setState({ messages: [...res.data.toSorted((x, y) => x.timestamp - y.timestamp), ...messages], cursor: res.data.length > 0 ? defaultLimit + cursor : -1 })

        const nextScrollHeight = windowRef.current?.scrollHeight || 0
        if (previousScrollHeight && nextScrollHeight && windowRef && windowRef.current && cursor !== -1) {
            windowRef.current.scrollBy(0, Math.abs(nextScrollHeight - previousScrollHeight))
        }
    }

    timeout = setTimeout(() => { setState({ lock: false }) }, 1000)
}

const loadChat = async (activeChat, setParent, setState, windowRef, cursor = 0, chatMessageId = null) => {
    if (cursor === -1) return
    const enterprise = getSession().profile.enterprise;
    clearTimeout(timeout)
    setParent({ loading: true, messages: [], lock: true, chatMessageId: null })
    const paramsObj = {
        limit: defaultLimit,
        skip: cursor,
        sort: '-messageTimestamp',
    }
    if (chatMessageId) {
        paramsObj.chatMessageId = chatMessageId;
    }
    const params = new URLSearchParams(paramsObj);

    const res = await api.get(`api/enterprises/${enterprise.id}/scout_accounts/${activeChat.account_id}/messages/${activeChat.id}/?${params.toString()}`, { timeout: 50000 });

    setParent({ loading: false })
    if (res.status === 200) {
        setState({ messages: res.data.toSorted((x, y) => x.timestamp - y.timestamp), cursor: res.data.length > 0 ? defaultLimit + cursor : -1 })
        await imagesHaveLoaded()
        dragScrollDown(windowRef)
    }

    if (res.status !== 200) {

        useNotification(
            'Não encontrada',
            'Conversa não encontrada.',
            'danger'
        );
    } 
    timeout = setTimeout(() => { setState({ lock: false }) }, 1000)
}

const imagesHaveLoaded = async () => {
    return Array.from(document.querySelectorAll("img")).every(
        (img) => img.complete && img.naturalWidth
    );
}

const submit = async (activeChat, state, handleClose) => {
    const enterprise = getSession().profile.enterprise;

    const chatName = activeChat?.name ? `(${activeChat.name})` : ''

    const res = await api.post(`api/enterprises/${enterprise.id}/scout_accounts/${activeChat.account_id}/exportmessages/${activeChat.id}/`, {
        ...state,
        dateini: state.dateini.unix(),
        dateend: state.dateend.unix(),
        numbertext: activeChat.type === 'GROUP' ? `para o grupo ${activeChat?.name || ''}` : `para o número ${activeChat.phone_num} ${chatName}`
    })
    if (res.status === 200) {
        useNotification(
            'Sucesso!',
            'A exportação foi iniciada e em breve estará disponível na caixa de e-mail selecionada.',
            'success'
        );

        handleClose()
    }
    else if (res.status === 409) {
        useNotification(
            'Recurso ocupado',
            'Já existe uma exportação em andamento para sua empresa, aguarde até que ela seja concluída, ou tente novamente em 1 hora.',
            'danger'
        );
    }
    else {
        useNotification(
            'Não encontrada',
            'Conversa não encontrada. Portanto o relatório não pôde ser gerado.',
            'danger'
        );
    }
}

const threshhold = 100

const calcDistBottom = (windowRef) => {
    return (windowRef.current.scrollTop + windowRef.current.offsetHeight + threshhold) >= windowRef.current.scrollHeight
}

const dragScrollDown = (windowRef) => {
    if (windowRef.current)
        windowRef.current.scrollTop = windowRef.current.scrollHeight
}

let gluedTimeout = 0
let gluedHold = 200

const calcGlued = (glued, setState, windowRef) => {
    clearTimeout(gluedTimeout)
    gluedTimeout = setTimeout(() => {
        const isGlued = windowRef.current && calcDistBottom(windowRef)
        const newState = {}
        if (isGlued !== glued) {
            newState.glued = isGlued
            if (isGlued) newState.messageAmount = 0
        }
        setState(newState)
    }, gluedHold)

}

const ChatWindow = ({ parent, setParent, innerRef }) => {

    const windowRef = useRef()

    const [state, setState] = useReducer(lockedReducer, {
        messages: [],
        confirmEmail: false,
        cursor: 0,
        lock: false,
        messageAmount: 0,
        glued: true,
        editList: null
    })

    innerRef.addMessages = useCallback(async (message) => {
        const glued = windowRef.current && calcDistBottom(windowRef)
        state.messages.push(message)
        setState({ messages: state.messages, glued: glued, messageAmount: state.messageAmount + 1 })
        if (glued) {
            await imagesHaveLoaded()
            dragScrollDown(windowRef)
        }

    }, [state.messages, state.messageAmount])

    useEffect(() => {
        if (parent.activeChat) loadChat(parent.activeChat, setParent, setState, windowRef, 0, parent.chatMessageId)
        return () => {
            setState({ cursor: 0, messages: [], lock: true })
        }
    }, [parent.activeChat])

    const chat = parent.activeChat

    return (
        <>
            {state.editList && <EditList data={state.editList} handleClose={()=> setState({editList: null})}/>}
            {state.confirmEmail && <EmailDialog activeChat={parent.activeChat} handleClose={() => setState({ confirmEmail: false })} submit={submit} />}
            {parent.activeChat && !parent.loading ?
                <Box class='scout-window-main-frame'>
                    <Box className='scout-window-header'>
                        <Box className='left'>
                            <span><Avatar alt={chat.id} src={chat.profile_picture} /></span>
                            <span className='icon'>{groupIcons[chat.type].icon}</span>
                            <span className='text' onClick={() => setParent({ showCRMSidebar: true }) } >{chatName(chat)}</span>
                        </Box>
                        <Box className='right'>
                            <span>
                                <Tooltip title="Exportar histórico de mensagens" placement="top" disableInteractive>
                                    <Button className="action-button"
                                        onClick={() => setState({ confirmEmail: true })}
                                    >
                                        <ExportIcon />
                                    </Button>
                                </Tooltip>
                            </span>
                            <span className='scout-align-flex'>
                                {chat.phone_num &&
                                    <>
                                        <span className='icon'><PhoneIphoneIcon /></span>
                                        <span className='text'>{chat.phone_num}</span>
                                    </>
                                }
                            </span>
                        </Box>
                    </Box>
                    <Box className='scout-window-content'>
                        <List
                            onScroll={() => calcGlued(state.glued, setState, windowRef)}
                            ref={windowRef}
                            className='scout-window-content-list'
                            key={`unique-key-by-activechat-${parent.activeChat.id}`}>
                            {(state.cursor !== -1 && !parent.loading && !state.lock) &&
                                <ShowComponent
                                    key={`show-component-${state.cursor}`}
                                    parent={windowRef}
                                    loading={(state.cursor !== -1)}
                                    isVisibleHook={(e) => {
                                        if (e) loadMore(parent.activeChat, state.messages, setState, windowRef, state.cursor)
                                    }}>
                                </ShowComponent>}
                            {state.messages.filter(message => !message.type).map((message, index) => (
                                <ScoutMessageComponent message={message} index={index} chatType={chat.type} messages={state.messages} setPrevState={setState} />
                            ))}
                        </List>
                        {(!state.glued) &&
                            <Box className='scrolldown-box scout' onClick={() => dragScrollDown(windowRef)}>
                                <Box sx={{ display: 'flex', alignItems: 'bottom' }}>
                                    <ArrowDownwardIcon />
                                </Box>
                                {state.messageAmount > 0 &&
                                    <Box
                                        className='messageAmount-badge'>
                                        {state.messageAmount}
                                    </Box>
                                }
                            </Box>
                        }
                    </Box>
                </Box>
                :
                <ChatIntro />
            }
        </>
    )
}

export default ChatWindow