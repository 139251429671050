import react, { useEffect, useReducer, useState } from 'react'
import SettingsIntermediate from '../SettingsV2/SettingsIntermediate'
import { Box, TextField, Button, DialogActions, Dialog, DialogTitle, DialogContent, Tooltip, Typography } from '@mui/material'
import Loading from '../Loading'
import useNotification from '../Notification'
import { lockedReducer } from '../../utils/defaultReducer'
import ReactHtmlParser from 'react-html-parser';
import api from '../../api'
import CloseIcon from '@mui/icons-material/Close';
import { validateEmail } from '../Dialogs/AutomatedReportDialog'
import './styles.css'

const initialize = async (template_name, setState) => {
    setState({ loading: true })
    const res = await api.get(`/api/emailtemplates/?template_name=${template_name}`)
    const response = { loading: false }
    if (res.status === 200) {
        response.template = res.data.content
        response.subject = res.data.subject
    } else {
        useNotification(
            'Ops!',
            'Não foi possível encontrar o template.',
            'danger'
        );
    }
    setState(response)
}

const submitTemplate = async (state, template_name, setState) => {
    setState({ loading: true })
    const res = await api.post('/api/emailtemplates/', { template: state.template, subject: state.subject, name: template_name })
    const response = { loading: false }
    if (res.status === 200) {
        response.template = res.data.content
        response.subject = res.data.subject
        useNotification(
            'Sucesso!',
            'Template atualizado com sucesso!',
            'success'
        );
    } else {
        useNotification(
            'Ops!',
            'Não foi possível salvar o template.',
            'danger'
        );
    }
    setState(response)
}

const testEmail = async (template, subject, email, setState) => {

    setState({ loading: true })
    const res = await api.post('/api/emailtemplates/test/', { template: template, subject: subject, email: email })
    const response = { loading: false }
    if (res.status === 200) {
        useNotification(
            'Sucesso!',
            'E-mail enviado com sucesso!',
            'success'
        );
    } else {
        useNotification(
            'Ops!',
            'Não foi possível enviar o e-mail.',
            'danger'
        );
    }
    setState(response)
}

const TestEmailDialog = ({ template, subject, setParent, handleClose }) => {

    const [state, setState] = useReducer(lockedReducer, {
        email: ''
    })

    const validatedEmail = validateEmail(state.email)

    return (
        <Dialog
            key='special-dialog-template-create'
            open={true}
            disablePortal
            disableEnforceFocus
            sx={{ position: "absolute" }}
        >
            <DialogTitle className={'group-flexbox'}>
                <span>Testar template</span>
                <Tooltip title="Fechar" >
                    <Button className="action-button"  >
                        <CloseIcon onClick={handleClose} />
                    </Button>
                </Tooltip>
            </DialogTitle>
            <DialogContent>
                <Typography fontSize="12px" fontWeight="500" color="#21446c">
                    Digite um e-mail abaixo para receber o template.
                </Typography>
                <TextField
                    autoComplete='off'
                    id='destination-test-template'
                    key='destination-test-template'
                    size='small'
                    label="Destinatário"
                    variant="outlined"
                    type='text'
                    fullWidth
                    sx={{ mt: 2, mb: 2 }}
                    value={state.email}
                    onChange={e => setState({ email: e.target.value })}
                    error={!validatedEmail}
                    helperText={state.email && !validatedEmail && "Por favor, insira um e-mail válido"}
                    inputProps={{
                        maxLength: 100,
                    }}
                />
            </DialogContent>
            <DialogActions sx={{ padding: '0rem 1.25rem 0.5rem 0rem' }}>
                <Button disabled={state.email.length === 0 } // ? || !validatedEmail}
                    onClick={() => { testEmail(template, subject, state.email, setParent) }}>
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const EditTemplateComponent = ({ template_name }) => {

    const [state, setState] = useReducer(lockedReducer, {
        subject: '',
        template: '',
        loading: false,
        test: false
    })

    useEffect(() => {
        initialize(template_name, setState)
    }, [])

    return (
        <>
            <Loading loading={state.loading} />
            <Box className='template-email-wrapper'>
                <TextField
                    label='Assunto'
                    fullWidth
                    size='small'
                    variant='outlined'
                    value={state.subject}
                    onChange={(e) => setState({ subject: e.target.value })}
                    sx={{ margin: '1rem 0rem', background: 'white' }}
                />
                <Box className="add-template-dialog-content dispersed" sx={{ paddingTop: '0.5rem' }}>
                    <TextField
                        multiline
                        rows={5}
                        size="small"
                        autoFocus={false}
                        label="Template (HTML)"
                        fullWidth
                        type="text"
                        key='template-text-field'
                        id='template-text-field'
                        variant="outlined"
                        className='special-template-textarea'
                        value={state.template}
                        onChange={(event) => {console.log(event); setState({ template: event.target.value })}}
                        InputProps={{
                            style: {
                                background: 'white',
                                flex: 1
                            }
                        }}
                    />
                    <Box className="add-template-viewer dispersed">
                        {ReactHtmlParser(state.template)}
                    </Box>
                </Box>
                <Box className='email-template-padding'>
                    <Button className="button-created" variant='contained'
                        onClick={() => {
                            setState({ test: true })
                        }}
                    >
                        Testar
                    </Button>
                    <Button className="button-created" variant='contained'
                        onClick={() => {
                            submitTemplate(state, template_name, setState)
                        }}
                    >
                        Salvar
                    </Button>
                </Box>
            </Box>
            {state.test && <TestEmailDialog template={state.template} subject={state.subject} handleClose={() => setState({ test: false })} setParent={setState} />}
        </>
    )
}

const EmailTemplatesComponent = () => {
    return (
        <Box className="plans-container">
            <SettingsIntermediate
                title='Templates de e-mail'
                components={() => ({
                    comp1: {
                        permissions: true,
                        style: 'title',
                        label: 'Templates de e-mail'
                    },
                    comp2: {
                        permissions: true,
                        style: 'list',
                        label: 'Cadastro de Clientes',
                        sublabel: 'Cadastro nos clientes',
                        redirect: (<EditTemplateComponent template_name='create_clients' />),
                        isVisible: true
                    },
                    comp25: {
                        permissions: true,
                        style: 'list',
                        label: 'Alteração de Clientes',
                        sublabel: 'Alterações nos clientes',
                        redirect: (<EditTemplateComponent template_name='update_clients' />),
                        isVisible: true
                    },
                    comp3: {
                        permissions: true,
                        style: 'list',
                        label: 'Cadastro de Planos e Produtos',
                        sublabel: 'Cadastro em planos e produtos',
                        redirect: (<EditTemplateComponent template_name='create_products_plans' />),
                        isVisible: true
                    },
                    comp35: {
                        permissions: true,
                        style: 'list',
                        label: 'Alteração de Planos e Produtos',
                        sublabel: 'Alteração em planos e produtos',
                        redirect: (<EditTemplateComponent template_name='update_products_plans' />),
                        isVisible: true
                    },
                    comp4: {
                        permissions: true,
                        style: 'list',
                        label: 'Cobrança',
                        sublabel: 'Cobrança com descrição dos serviços e boleto anexo',
                        redirect: (<EditTemplateComponent template_name='charges' />),
                        isVisible: true
                    },
                    comp5: {
                        permissions: true,
                        style: 'list',
                        label: 'Confirmação de pagamento',
                        sublabel: 'Confirmação de pagamento com NFS-e anexa',
                        redirect: (<EditTemplateComponent template_name='payments' />),
                        isVisible: true
                    },
                    comp6: {
                        permissions: true,
                        style: 'list',
                        label: 'Pendência financeira',
                        sublabel: 'Aviso de pendência financeira',
                        redirect: (<EditTemplateComponent template_name='pending' />),
                        isVisible: true
                    },
                    comp7: {
                        permissions: true,
                        style: 'list',
                        label: 'Bloqueio da plataforma',
                        sublabel: 'Aviso de bloqueio da plataforma',
                        redirect: (<EditTemplateComponent template_name='block' />),
                        isVisible: true
                    },
                    comp8: {
                        permissions: true,
                        style: 'list',
                        label: 'CNPJ Não Cadastrado',
                        sublabel: 'Aviso ao departamento financeiro para CNPJ não cadastrado.',
                        redirect: (<EditTemplateComponent template_name='invalid_cnpj' />),
                        isVisible: true
                    },
                })}
            />
        </Box>
    )
}

export default EmailTemplatesComponent