import react, { useEffect, useReducer } from 'react'
import api from '../../api'
import { lockedReducer } from '../../utils/defaultReducer'
import {
    DataGridPro,
    ptBR,
} from '@mui/x-data-grid-pro';
import {
    gridClasses,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridToolbarExportContainer,
} from '@mui/x-data-grid-pro';
import { ptBR as corePtBR } from '@mui/material/locale';
import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';
import { Box, IconButton, Tooltip, Button } from '@mui/material';
import dayjs from 'dayjs'
import Loading from '../Loading';

import { dateOperators, stringOperators, choiceOperators } from '../../utils/filterOperators';

import CancelIcon from '@mui/icons-material/Cancel';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ConfirmationDialogCaptcha from '../Dialogs/ConfimationDialogCaptcha';

import useNotification from '../Notification';

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const defaultLimit = 25

const columns = (setState) => {
    return [
        { field: 'NOTA', headerName: 'Nota', flex: .5, filterOperators: stringOperators, sortable: false },
        { field: 'RPS', headerName: 'RPS', flex: .5, filterOperators: stringOperators, sortable: false },
        { field: 'serie', headerName: 'Série', flex: .5, filterOperators: stringOperators, sortable: false },
        { field: 'cnpj', headerName: 'CPF/CNPJ', flex: 1, filterOperators: stringOperators, sortable: false },
        { field: 'status', headerName: 'Status', flex: 1, filterOperators: choiceOperators, valueOptions: ['EMITIDA', 'CANCELADA'], sortable: false },
        { field: 'emission', headerName: 'Emissão', filterOperators: dateOperators, flex: 1, renderCell: ({ value }) => dayjs(value).format('DD/MM/YYYY HH:mm'), sortable: false },
        { field: 'cancel_date', headerName: 'Cancelamento', filterOperators: dateOperators, flex: 1, renderCell: ({ value }) => value ? dayjs(value).format('DD/MM/YYYY HH:mm') : '', sortable: false },
        {
            field: 'actions', headerName: 'Ações', filterable: false, sortable: false,
            renderCell: ({ row }) =>
                <Box className='report-action-buttons'>
                    <Tooltip title="Link para nota fiscal" placement="top" disableInteractive>
                        <a href={row.url} target='_blank'>
                            <Button className="action-button" >
                                <ReceiptIcon />
                            </Button>
                        </a>
                    </Tooltip>
                    {row.status !== 'CANCELADA' &&
                        <Tooltip title="Cancelar nota fiscal" placement="top" disableInteractive>
                            <Button className="action-button delete" onClick={() => setState({ cancelModal: row })}>
                                <CancelIcon />
                            </Button>
                        </Tooltip>}
                </Box>
        },
    ]
}


const CustomToolbar = (state, setState) => {
    return (
        <GridToolbarContainer className={gridClasses.toolbarContainer} >
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    )
}

const fetchRows = async (limit, page, filterModel, setState) => {
    setState({ loading: true })
    const url = new URLSearchParams()
    url.append('limit', limit)
    url.append('offset', page * limit)
    url.append('sort', 'id__desc')
    if (filterModel.linkOperator === 'or')
        url.append('OR', true)
    filterModel.items.forEach((item) => {
        url.append(`${item.columnField}__${item.operatorValue}`, item.value)
    })

    const res = await api.get(`api/payments/fetchnfse/?${url.toString()}`)
    const state = { limit: limit, page: page, filterModel: filterModel, loading: false }
    if (res.status === 200) {
        state.rows = res.data.results
        state.count = res.data.count
    }
    setState(state)
}

const cancelarNota = async (data, state, setState) => {
    setState({loading:true})
    const res = await api.post(`api/payments/cancelnfse/`, {numero: data.NOTA})
    const newState = {loading: false}
    if (res.status === 200){
        newState.cancelModal = null
        useNotification(
            'Sucesso!',
            'Nota fiscal cancelada com sucesso.',
            'success'
        )
        await fetchRows(state.limit, state.page, state.filterModel, setState)
    } else {
        useNotification(
            'Ops!',
            'Não foi possível cancelar a nota fiscal.',
            'danger'
        )
        
    }
    setState(newState)
}

let changeTimeout = 0
const changeTimeoutTime = 1000

const NfseComponent = () => {

    const [state, setState] = useReducer(lockedReducer, {
        rows: [],
        limit: defaultLimit,
        page: 0,
        loading: false,
        count: 0,
        filterModel: { items: [] },
        cancelModal: null
    })

    useEffect(() => {
        fetchRows(defaultLimit, 0, { items: [] }, setState)
    }, [])

    return (
        <Box className="plans-container">
            <Loading loading={state.loading} />
            {state.cancelModal &&
                <ConfirmationDialogCaptcha
                    captcha={'cancelar'}
                    description={<Box>Para cancelar a nota {state.cancelModal.NOTA}, digite <b>cancelar</b> no campo abaixo.</Box>}
                    alternateYes='Confirmar'
                    alternateNo='Fechar'
                    open={true}
                    title={`Cancelar nota ${state.cancelModal.NOTA}`}
                    handleClose={() => setState({ cancelModal: null })}
                    handleSubmit={() => { cancelarNota(state.cancelModal, state, setState) }}
                />}
            <StyledEngineProvider>
                <ThemeProvider theme={theme}>
                    <DataGridPro
                        disableSelectionOnClick
                        disableSorting
                        disableColumnSorting
                        disableMultipleColumnsSorting
                        disableDensitySelector
                        density="compact"
                        components={{
                            Toolbar: () => CustomToolbar(state, setState)
                        }}
                        columns={columns(setState)}
                        rows={state.rows}
                        pagination
                        paginationMode='server'
                        page={state.page}
                        rowCount={state.count}
                        rowsPerPageOptions={[25, 50, 100]}
                        pageSize={state.limit}
                        onPageSizeChange={(size) => {
                            fetchRows(size, state.page, state.filterModel, setState)
                        }}
                        initialState={{
                            pagination: {
                                pageSize: Number(state.limit)
                            }
                        }}
                        onPageChange={async (page) => {
                            fetchRows(state.limit, page, state.filterModel, setState)
                        }}
                        onFilterModelChange={(e) => {
                            clearTimeout(changeTimeout)
                            changeTimeout = setTimeout(() => fetchRows(state.limit, state.page, e, setState), changeTimeoutTime)
                        }}
                        filterModel={state.filterModel}
                        filterMode='server'
                    />
                </ThemeProvider>
            </StyledEngineProvider>
        </Box>
    )
}


export default NfseComponent