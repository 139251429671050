import React, { useState, useMemo, createContext, useEffect } from "react";
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import PageVisibility from 'react-page-visibility/dist/umd/PageVisibility'
import UserRegister from "./pages/UserRegister";
import Trial from "./pages/Trial";
import Login from "./pages/Login";
import Plans from "./pages/Plans";
import Products from "./pages/Products";
import ServiceSettings from "./pages/ServiceSettings";
import Dashboard from "./pages/Dash";
import Chat from "./pages/Chat";
import Report from "./pages/Report";
import Template from "./pages/Template";
import CreatePassword from "./pages/CreatePassword";
import ResetPassword from "./pages/ResetPassword";
import ScanScoutQR from "./pages/ScanScoutQR";
import Settings from "./pages/Settings";
import ManageCalls from "./pages/ManageCalls";
import Clients from "./pages/Clients";
import Metrics from "./components/Metrics";
import Onboarding from "./pages/Onboarding";
import Crm from "./pages/Crm";
import AdminLogin from "./pages/AdminLogin";

// import SocketQueueComponent from "./SocketQueueComponent";
import UploadLoaderComponent from "./components/UploadLoaderComponent";
import { Box, Button } from "@mui/material";
import IdentificateAgentDialog from "./components/Dialogs/IdentificateAgentDialog";
import InstallKwik from "./utils/InstallKwik";
import isMobile, {lowResolution} from "./utils/isMobile";

import InternalChatMenu from "./components/Menu/InternalChatMenu";
import WebphoneMenu from "./components/Menu/WebphoneMenu";
import SocketQueueComponent from "./SocketQueueComponent";

import CortexLogo from './assets/images/cortex-logo-vertical.png'

import IconButton from '@mui/material/IconButton';

import { isAuthenticated, isSuperAdminAuthenticated, getSession } from "./auth";

import userPermissions from './components/User/Permissions';

import useNotification from "./components/Notification";
import KwikAssistant from "./components/KwikAssistant";
import BuilderPage from "./pages/Builder";
import ChannelsPage from "./pages/Channels";
import AutomationPage from "./pages/Automation";
import ScoutPage from "./pages/Scout"
import NFSe from "./pages/Nfse";
import Enterprises from "./pages/Enterprises";
import Managers from "./pages/Managers";
import Payments from "./pages/Payments";
import Sequences from './pages/Sequences'
import FBTrial from "./fbtrial";
import WBTrial from "./wbtrial";
import EmailTemplates from "./pages/EmailTemplates";
import Boletos from "./pages/Boletos";


const groups = userPermissions();

var ext_visible = false

export const QueueSocket = createContext()

export const CallSocket = createContext();

const checkPermission = (permission) => {
    if (!permission)
        return true;

    return ((permission === 'admin' && groups.isAdmin) ||
        (permission === 'supervisor' && groups.isSupervisor));
}

const AdminPrivateRoute = ({ component: Component, ...rest }) => {
    if (! isSuperAdminAuthenticated)
        return (
            <Route
                exact {...rest}
                render={props => (<Redirect to={{
                    pathname: '/superadmin',
                    state: { from: props.location }
                }} />)}
            />
        )

    return (
        <Route exact {...rest}
            render={props => (<Component {...props} />)}
        />
    );
}

const PrivateRoute = ({ permission: permission, component: Component, ...rest }) => {

    const hasPermission = checkPermission(permission);

    const platforms = getSession()?.profile.enterprise.platforms;
    const channels = getSession()?.profile.enterprise.channels;

    // DEPRECATED
    // const isEnterpriseSetup = !platforms?.whatsapp || platforms?.whatsapp && channels?.length !== 0;
    
    const isEnterpriseSetup = true

    if (!isAuthenticated) {
        return (
            <Route
                exact {...rest}
                render={props => (<Redirect to={{
                    pathname: '/',
                    state: { from: props.location }
                }} />)}
            />
        );
    }

    if (isAuthenticated && !isEnterpriseSetup && rest.path != '/onboarding') {
        return (
            <Route
                exact {...rest}
                render={props => (<Redirect to={{
                    pathname: '/onboarding',
                    state: { from: props.location }
                }} />)}
            />
        );
    }

    if (isAuthenticated && !hasPermission ||
        isAuthenticated && isEnterpriseSetup && rest.path == '/onboarding') {
        return (
            <Route
                exact {...rest}
                render={props => (<Redirect to={{
                    pathname: '/chat',
                    state: { from: props.location }
                }} />)}
            />
        );
    }

    return (
        <Route exact {...rest}
            render={props => (<Component {...props} />)}
        />
    );
}

const ChangeNotificationTexts = (type, numberChannels, unreadMessages) => {
    let channelPlural = '';
    let messagePlural = {
        title: '',
        body: ''
    };

    if (type === 'newMessage') {
        if (unreadMessages > 1) {
            messagePlural.title = 'novas mensagens';
            messagePlural.body = 'Novas mensagens';

        } else {
            messagePlural.title = 'nova mensagem';
            messagePlural.body = 'Nova mensagem';
        }

        if (numberChannels > 1)
            channelPlural = 'conversações';
        else
            channelPlural = 'conversação';

    } else if (type === 'newChat') {
        if (numberChannels > 1) {
            messagePlural.title = 'novos Chats';
            messagePlural.body = 'Novos Chats';

        } else {
            messagePlural.title = 'novo Chat';
            messagePlural.body = 'Novo Chat';
        }
    }

    return {
        channelPlural: channelPlural,
        messagePlural: messagePlural
    };
}

const browserNotification = async (type, numberChannels, unreadMessages, extraText='') => {

    let title = '';
    let body = '';

    if (type === 'newMessage') {
        // Change notification texts
        const descriptions = ChangeNotificationTexts(type, numberChannels, unreadMessages);

        title = `${unreadMessages} ${descriptions.messagePlural.title}`;
        body = `${descriptions.messagePlural.body} ${extraText}.`;

    } else if (type === 'newChat') {
        // Change notification texts
        const descriptions = ChangeNotificationTexts(type, numberChannels);

        title = `${numberChannels} ${descriptions.messagePlural.title}`;
        body = `${descriptions.messagePlural.body} aguardando na fila.`;
    }

    else if (type === 'newCall') {
        // Change notification texts
        if (ext_visible)
            return
        title = `Nova chamada de voz`;
        body = `Você recebebeu uma nova ligação.`;
    }


    const options = {
        body: body,
        lang: 'pt-BR',
        icon: `${window.location.origin}/kwik_favicon.png`,
        silent: true,
    }

    //Open the notification
    // const notification = new Notification(title, options);

    const registrations = await navigator.serviceWorker.getRegistrations()

    let audio = new Audio(`${window.location.origin}/new_message.mp3`);

    const notificationAudio = getSession()?.profile?.enterprise?.audio_notification

    if (notificationAudio) {
        audio = new Audio(`${window.location.origin}/export/${notificationAudio.split('/export/')[1]}`);
    }

    try {
        audio.play();
    } catch (e) { console.error(e) }

    registrations[0].showNotification(title, options);

}


const deniedNotification = (permission) => {
    if (permission === "denied") {
        useNotification(
            'Permissão negada!',
            'Você não permitiu que o Kwik envie notificações de novas mensagens para você.',
            'danger'
        );
    }
}

const isSupported = () =>
    'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window

const requestPermission = () => {
    if (!isSupported()) return
    return new Promise((resolve, reject) => {
        Notification.requestPermission().then(permission => {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
                resolve();
            } else {
                reject(permission);
            }
        });
    });
}

// Detects if device is on iOS 
const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
}

const sendNotification = (type, numberChannels, unreadMessages, extraText='') => {

    if (isIos()) {
        return;
    }

    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
        useNotification(
            'Atenção!',
            'Este navegador não suporta notificação na área de trabalho.',
            'warning'
        );
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
        // Open new message notification
        try {
            browserNotification(type, numberChannels, unreadMessages, extraText);
        }
        catch (e) {
            console.error(e)
                ;
        }

    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
        requestPermission()
            .then(() => {
                // Open new message notification
                try {
                    browserNotification(type, numberChannels, unreadMessages, extraText);
                }
                catch (e) {
                    console.error(e)
                        ;
                }
            })
            .catch(permission => {
                deniedNotification(permission);
            });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
}

const AssistantDOM = () => {
    return (
        <>
            {(isAuthenticated) && <KwikAssistant />}
        </>
    )
}

const Routes = () => {

    const webphoneBacklistedPaths = [
        '', // login page
        'metrics',
        'register',
        'createPassword',
        'resetPassword',
        'plans',
        'serviceSettings',
        'onboarding',
        'assistant',
        'trial',
        'fbtrial',
        'wbtrial',
        'plans', 
        'products', 
        'enterprises', 
        'managers', 
        'nfse', 
        'paymenthistory', 
        'sequences', 
        'templateemail',
        'superadmin'
    ];

    const shouldRenderWidgets = () => {

        const url = window.location.href;
        const parts = url.split('/');
        const pathOne = parts[parts.length - 1];
        const path = pathOne.split('?')[0]

        return !webphoneBacklistedPaths.includes(path);
    }

    //Calling this hook will cause state to update, you can use it to refresh any internal component
    //with a useEffect

    const [chatState, updateChatState] = useState()
    const [notifications, setNotifications] = useState(false)
    const [connections, updateConnections] = useState([])
    const [visible, setVisible] = useState(true)
    const [refresh, setRefresh] = useState(false)
    const [showAssistant, setShowAssistant] = useState(false)
    const [cache, setCache] = useState()

    const reloadState = () => { setRefresh(p => !p) }

    const user = getSession()

    const agentIdentification = user?.profile.enterprise.settings.agent_identification
    const isAgentIdentificated = user?.profile.enterprise.settings.isAgentIdentificated

    useEffect(() => {
        ext_visible = visible
    }, [visible, notifications])

    useEffect(async () => {
        requestPermission()

        const registrations = await navigator.serviceWorker.getRegistrations()

        if (registrations && registrations.length > 0)
            registrations.map(async each => { await each.unregister() })

        await navigator.serviceWorker.register('/sw.js');

        window.onresize = reloadState
    }, [])



    useEffect(() => {
        if (location.pathname == '/chat' || location.pathname == '/manageCalls' || !chatState)
            return
        const message = chatState.message
        switch (message) {
            case 'NEW_CHAT':
                if (!notifications) {
                    if (!visible) {
                        const audio = new Audio(`${window.location.origin}/new_message.mp3`);
                        audio.play();
                        sendNotification('newChat', 1, 1);
                    }
                    setNotifications(true)
                }

                break;

            default:
                break;
        }

    }, [chatState])

    const route = useMemo(() => {

        return (
            <BrowserRouter >
                {(shouldRenderWidgets() && !isMobile()) &&
                    <Box id="widgets-container" className="widgets-container">
                        <WebphoneMenu />
                        <InternalChatMenu />
                    </Box>
                }
                <Switch >
                    <Route exact path='/metrics' component={Metrics} />
                    <Route exact path='/register' component={UserRegister} />
                    <Route exact path='/trial' component={Trial} />
                    <Route exact path='/' component={Login} />
                    <Route exact path='/fbtrial' component={FBTrial} />
                    <Route exact path='/wbtrial' component={WBTrial} />
                    <Route exact path='/superadmin' component={AdminLogin} />
                    <Route path='/createPassword' component={CreatePassword} />
                    <Route path='/resetPassword' component={ResetPassword} />
                    <Route path='/scanScoutQR' component={ScanScoutQR} />
                    <AdminPrivateRoute path='/products' component={Products} />
                    <AdminPrivateRoute path='/plans' component={Plans} />
                    <AdminPrivateRoute path='/nfse' component={NFSe} />
                    <AdminPrivateRoute path='/boletos' component={Boletos} />
                    <AdminPrivateRoute path='/paymenthistory' component={Payments} />
                    <AdminPrivateRoute path='/sequences' component={Sequences} />
                    <AdminPrivateRoute path='/managers' component={Managers} />
                    <AdminPrivateRoute path='/enterprises' component={Enterprises} />
                    <AdminPrivateRoute path='/templateemail' component={EmailTemplates} />
                    <PrivateRoute path='/serviceSettings' component={ServiceSettings} />
                    <PrivateRoute path='/dashboard' component={Dashboard} permission="supervisor" />
                    <PrivateRoute path='/chat' component={Chat} />
                    <PrivateRoute path='/report' component={Report} permission="supervisor" />
                    <PrivateRoute path='/template' component={Template} permission="supervisor" />
                    <PrivateRoute path='/settings' component={Settings} permission="supervisor" />
                    <PrivateRoute path='/automation' component={AutomationPage} permission="admin" />
                    <PrivateRoute path='/scout' component={ScoutPage} permission="supervisor" />
                    <PrivateRoute path='/builder' component={BuilderPage} permission="admin" />
                    <PrivateRoute path='/channels' component={ChannelsPage} permission="admin" />
                    <PrivateRoute path='/manageCalls' component={ManageCalls} permission="supervisor" />
                    <PrivateRoute path='/crm' component={Crm} />
                    <PrivateRoute path='/onboarding' component={Onboarding} />
                    <PrivateRoute path='/assistant' component={KwikAssistant} />
                </Switch>
            </BrowserRouter>)
    }, [visible])

    return (
        <>
            <PageVisibility onChange={(event) => { setVisible(event); setNotifications(event) }}></PageVisibility>

            {!isIos() &&
                <InstallKwik />
            }

            <QueueSocket.Provider value={{
                chatState, updateChatState, notifications,
                setNotifications, updateConnections, connections,
                sendNotification, visible, cache, setCache, setShowAssistant
            }}>
                <Box className='kwik-mainWrapper'>
                    <Box className='kwik-mainRoute' >
                        {route}
                    </Box>
                    {(isAuthenticated && shouldRenderWidgets() && !(lowResolution())) &&
                        <Box sx={{ 
                            flex: showAssistant ? 0.25 : .02, 
                            maxWidth:'25%',
                            backgroundColor: "white", 
                            position:'relative',
                            zIndex:'2000',
                            display: "flex", 
                            flexDirection: 'row', 
                            alignItems: "center", 
                            justifyContent: "center", 
                            borderLeft: "1px solid rgba(0, 0, 0, 0.12)" 
                        }}>
                            {! showAssistant &&
                                <IconButton className="kwik-cortex-open-btn" onClick={() => setShowAssistant(p => !p)}>
                                    <img src={CortexLogo} className="kwik-cortex-logo"/>
                                </IconButton>
                            }
                            <span
                                style={{ display: showAssistant ? 'block' : 'none', height: "100%", width: '100%' }}>
                                {AssistantDOM()}
                            </span>
                        </Box>
                    }
                </Box>
                {agentIdentification && !isAgentIdentificated ? <IdentificateAgentDialog refresh={reloadState} /> : null}
                <UploadLoaderComponent />
                <SocketQueueComponent />
            </QueueSocket.Provider>
        </>
    );
}

export default Routes;
