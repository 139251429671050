import React from "react"

import AdminDrawer from "../../components/AdminDrawer"
import PlansComponent from "../../components/Plans"

const Plans = () => {

    return (
        <AdminDrawer child={<PlansComponent/>}/>
    );
};

export default Plans
