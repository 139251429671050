import react, { useReducer, useEffect, useState } from "react"

import {
    Box,
    Button,
    Chip,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    FormControlLabel,
    Switch,
    TextField,
    Alert
} from "@mui/material"

import { createTheme, ThemeProvider } from "@mui/material/styles"

import * as moment from "moment"

import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    gridClasses,
    ptBR
} from "@mui/x-data-grid-pro"

import { ptBR as corePtBR } from "@mui/material/locale"

import ReceiptIcon from "@mui/icons-material/Receipt"
import InvoiceIcon from "@mui/icons-material/Description"
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import CloseIcon from "@mui/icons-material/Close"
import CancelIcon from "@mui/icons-material/Cancel"
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong"

import ConfirmationDialogCaptcha from "../Dialogs/ConfimationDialogCaptcha"
import Loading from "../Loading"

import { lockedReducer } from "../../utils/defaultReducer"

import useNotification from "../Notification"

import api from "../../api"

import "./styles.css"

const theme = createTheme({
    palette: {
        primary: { main: "#21446C" },
    },
}, ptBR, corePtBR)

const translate_status = {
    'CANCELLED': 'Cancelado'
}

const CustomToolbar = (gridClasses) => (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box className='toolbarLeft'>
            <GridToolbarColumnsButton size="small" />
            <GridToolbarFilterButton size="small" className={'filterControl'} />
        </Box>
    </GridToolbarContainer>
)

const getPayments = async (setState) => {
    setState({ loading: true })
    const res = await api.get("/api/payment_history/")
    if (res.status === 200) {
        setState({
            history: res.data,
            loading: false
        })
        return
    }
    setState({ loading: false })
}

const getPaymentStatus = (status, row) => {
    if (row.payed_at && row.payed_value == row.total_value_number)
        return { color: "success", status: "Pago" }
    else if (row.payed_at && row.payed_value < row.total_value_number)
        return { color: "info", status: "Pago Parcial" }
    else if (status in translate_status)
        return { color: "error", status: translate_status[status] }
    else if ((new Date(row.due_at)) <= (new Date()).setHours(0, 0, 0, 0))
        return { color: "warning", status: "Atrasado" }
    return { color: "error", status: 'Pendente' }
}

const historyColumns = (setParent) => {
    return [
        { field: "enterprise_name", headerName: "Empresa", flex: 1 },
        {
            field: "created_at", headerName: "Data da Cobrança", flex: 1,
            renderCell: (params) => (
                <Box>
                    {params.value
                        ? moment(params.value).format("DD/MM/YYYY").toString()
                        : ""
                    }
                </Box>
            )
        },
        {
            field: "monthly_value", headerName: "Mensalidade", flex: 1,
            renderCell: (params) => new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(params.value)
        },
        {
            field: "monthly_exceeding", headerName: "Excedente", flex: 1,
            renderCell: (params) => new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(params.value)
        },
        {
            field: "taxes", headerName: "ISS", flex: 1,
            renderCell: ({value}) => {
                return new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(value)
            }
        },
        {
            field: "total_value_number", headerName: "Valor Total", flex: 1,
            renderCell: ({value}) => 
                new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(value)
        },
        {
            field: "due_at", headerName: "Data do Vencimento", flex: 1,
            renderCell: (params) => (
                <Box>
                    {params.value
                        ? moment(params.value).format("DD/MM/YYYY").toString()
                        : ""
                    }
                </Box>
            )
        },
        {
            field: "status", headerName: "Status", flex: 1,
            renderCell: ({value, row}) => {
                const { color, status } = getPaymentStatus(value, row)
                return (
                    <Chip label={status} color={color} size="small" />
                )
            }
        },
        {
            field: "payed_at", headerName: "Data do Pagamento", flex: 1,
            renderCell: (params) => (
                <Box>
                    {params.value
                        ? moment(params.value).format("DD/MM/YYYY").toString()
                        : ""
                    }
                </Box>
            )
        },
        {
            field: "payed_value", headerName: "Valor Pago", flex: 1,
            renderCell: (params) => (params.value)
                ? new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(params.value)
                : ""
        },
        {
            field: "action", headerName: "Ações", flex: 1, filterable: false,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Ver boleto" placement="bottom">
                        <Button className="action-button"
                            onClick={() => (params.row.invoice)
                                ? window.open(params.row.invoice)
                                : useNotification(
                                    "Indisponível!",
                                    "O boleto não está disponível.",
                                    "info"
                                )
                            }>
                            <InvoiceIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Reenviar boleto" placement="bottom">
                        <Button className="action-button"
                            onClick={() => setParent({ historyRow: params.row })}>
                            <ForwardToInboxIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Ver nota fiscal" placement="bottom">
                        <Button className="action-button"
                            onClick={() => (params.row.nfse)
                                ? window.open(params.row.nfse)
                                : useNotification(
                                    "Indisponível!",
                                    "A nota fiscal não está disponível.",
                                    "info"
                                )
                            }>
                            <ReceiptIcon />
                        </Button>
                    </Tooltip>
                    {params.row.nfse_status !== "EMITIDA" &&
                        <Tooltip title="Emitir nota fiscal" placement="bottom" disableInteractive>
                            <Button className="action-button"
                                onClick={() => setParent({ createNfseRow: params.row })}>
                                <ReceiptLongIcon />
                            </Button>
                        </Tooltip>
                    }
                    {params.row.nfse_status === "EMITIDA" &&
                        <Tooltip title="Cancelar nota fiscal" placement="bottom" disableInteractive>
                            <Button className="action-button delete"
                                onClick={() => setParent({ cancelNfseRow: params.row })}>
                                <CancelIcon />
                            </Button>
                        </Tooltip>
                    }
                </Box>
            )
        }
    ]
}

const resendInvoice = async (historyRow, extraData, setParent) => {
    setParent({ loading: true })
    const res = await api.post("/api/payment_history/resend_invoice/", {
        history_row: historyRow,
        include_managers: extraData["includeManagers"],
        extra_emails: extraData["extraEmails"]
    })

    if (res.status === 200) {
        useNotification(
            "Sucesso!",
            "A cobrança foi reenviada com sucesso.",
            "success"
        )
        setParent({ historyRow: null, loading: false })
        return
    }

    setParent({ loading: false })
    useNotification(
        "Ops!",
        "Não foi possível reenviar a cobrança, tente novamente em alguns instantes.",
        "danger"
    )
}

const ResendInvoiceComponent = ({ historyRow, setParent }) => {

    const [state, setState] = useState({
        includeManagers: true,
        extraEmails: ""
    })

    return (
        <Dialog fullWidth maxWidth="sm" open={historyRow !== null} >
            <DialogTitle className={'group-flexbox'}>
                <span>Reenviar cobrança</span>
                <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={() => setParent({ historyRow: null })}>
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ paddingTop: "2rem" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <FormControlLabel
                        control={<Switch checked={state.includeManagers} onClick={() => setState(prevState => ({ ...prevState, includeManagers: !prevState.includeManagers }))} />}
                        label="Gerentes de conta"
                    />
                    <TextField
                        type="text"
                        label="E-mails adicionais"
                        size='small'
                        value={state.extraEmails}
                        onChange={(event) => setState(prevState => ({ ...prevState, extraEmails: event.target.value }))}
                        fullWidth
                    />
                    <Alert severity="info">E-mails adicionais separados por vírgula.</Alert>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setParent({ historyRow: null })}>Cancelar</Button>
                <Button onClick={() => resendInvoice(historyRow, state, setParent)}>
                    Reenviar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const createNfse = async (row, setParent, history) => {
    setParent({ loading: true })
    const res = await api.post("api/payment_history/createnfseauto/", {
        "valor_servico": Math.floor((row.monthly_value + row.monthly_exceeding)/.95 * 100) / 100,
        "descricao": "Licenciamento de uso de programa de computação",
        "cnpj_cliente": row.enterprise_cnpj,
        "payment_history_id": row.id
    })

    if (res.status === 200) {
        setParent({
            createNfseRow: null,
            history: history.map(historyRow => historyRow.id === row.id ? res.data : historyRow),
            loading: false
        })
        useNotification(
            "Sucesso!",
            "Nota fiscal emitida com sucesso.",
            "success"
        )
        return
    }

    setParent({ loading: false })
    useNotification(
        "Ops!",
        res.data || "Não foi possível emitir a nota fiscal.",
        "danger"
    )
}

const cancelNfse = async (row, setParent, history) => {
    setParent({ loading: true })
    const res = await api.post("api/payments/cancelnfse/", { numero: row.nfse_number })

    if (res.status === 200) {
        setParent({
            cancelNfseRow: null,
            history: history.map(historyRow => historyRow.id === row.id ? { ...historyRow, nfse_status: "CANCELADA" } : historyRow),
            loading: false
        })
        useNotification(
            "Sucesso!",
            "Nota fiscal cancelada com sucesso.",
            "success"
        )
        return
    }

    setParent({ loading: false })
    useNotification(
        "Ops!",
        "Não foi possível cancelar a nota fiscal.",
        "danger"
    )
}

const PaymentsComponent = () => {

    const [filterModel, setFilterModel] = useState({ items: [] })
    const [state, setState] = useReducer(lockedReducer, {
        history: [],
        historyRow: null,
        createNfseRow: null,
        cancelNfseRow: null,
        loading: false
    })

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const value = queryParams.get("enterprise")
        if (value) {
            setFilterModel({
                items: [{
                    columnField: "enterprise_name",
                    operatorValue: "contains",
                    value: value
                }]
            })
        }
    }, [location.search])

    useEffect(() => {
        getPayments(setState)
    }, [])

    return (
        <Box className="payments-container">
            <Loading loading={state.loading} />
            {state.historyRow &&
                <ResendInvoiceComponent
                    historyRow={state.historyRow}
                    setParent={setState}
                    handleClose={() => setState({ historyRow: null })}
                />
            }
            {state.createNfseRow &&
                <ConfirmationDialogCaptcha
                    captcha={'emitir'}
                    description={<Box>Para emitir a nota, digite <b>emitir</b> no campo abaixo.</Box>}
                    alternateYes='Confirmar'
                    alternateNo='Fechar'
                    open={true}
                    title="Emitir nota fiscal"
                    handleClose={() => setState({ createNfseRow: null })}
                    handleSubmit={() => createNfse(state.createNfseRow, setState, state.history)}
                />
            }
            {state.cancelNfseRow &&
                <ConfirmationDialogCaptcha
                    captcha={'cancelar'}
                    description={<Box>Para cancelar a nota {state.cancelNfseRow.nfse_number}, digite <b>cancelar</b> no campo abaixo.</Box>}
                    alternateYes='Confirmar'
                    alternateNo='Fechar'
                    open={true}
                    title={`Cancelar nota ${state.cancelNfseRow.nfse_number}`}
                    handleClose={() => setState({ cancelNfseRow: null })}
                    handleSubmit={() => cancelNfse(state.cancelNfseRow, setState, state.history)}
                />
            }
            <ThemeProvider theme={theme}>
                <DataGridPro
                    rows={state.history}
                    columns={historyColumns(setState)}
                    density={"compact"}
                    pagination
                    paginationMode="client"
                    pageSize={20}
                    rowsPerPageOptions={[20, 50, 100]}
                    disableSelectionOnClick
                    components={{ Toolbar: CustomToolbar }}
                    filterModel={filterModel}
                    onFilterModelChange={(newModel) => setFilterModel(newModel)}
                />
            </ThemeProvider>
        </Box>
    )
}


export default PaymentsComponent
