import React, { useState, useContext, useMemo, useEffect } from 'react'
import { ScreenContext } from '../..'

import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    ptBR,
    getGridStringOperators,
    getGridBooleanOperators,
    getGridSingleSelectOperators,
    getGridDateOperators
} from '@mui/x-data-grid-pro';
import { ptBR as corePtBR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Button, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import HistoryIcon from '@mui/icons-material/History';
import MessageIcon from '@mui/icons-material/Message';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import CampaignHistory from './CampaignHistory'
import ConfirmationDialog from '../../../Dialogs/Confirmation';
import CreateCampaignModal from '../../CreateCampaignModal'

import userPermissions from '../../..//User/Permissions';
import useNotification from '../../../Notification';

import api from '../../../../api';

const userGroups = userPermissions();

const CampaignTable = () => {
    const {
        isSmallScreen,
        fetchNotifications,
        updateSelectedTab,
        updateStartDateTime,
        updateEndDateTime,
        groups,
        updateCampaigns,
        updateReady,
        fetchCampaigns,
        queues,
        originNumbers,
        templates,
        enterpriseId,
        campaigns,
        permissions,
        settings,
        rmvObj,
        ServerData,
        updateLimitOffset,
        limitOffset,
        filterModel,
        sortModel,
        updateFilterModel } = useContext(ScreenContext)

    const [campaignData, updateCampaignData] = useState([])
    const [showCampaignHistory, updateShowCampaignHistory] = useState(false)
    const [showCampaignDelete, updateShowCampaignDelete] = useState(false)
    const [showCampaignEdit, updateShowCampaignEdit] = useState(false)
    const [pageLimit, updatePageLimit] = useState(0)
    const [campaignsFilter, updateCampaignsFilter] = useState({ items: [] })
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ items: [] })
    const [campaignSortModel, updateCampaignSortModel] = useState([])

    useEffect(() => {
        updateCampaignsFilter(filterModel.campaigns)
        updateCampaignSortModel(sortModel.campaigns)
    }, [])

    useEffect(() => {
        setColumnVisibilityModel({
            sender: false,
            groups: !isSmallScreen,
            created_at: false,
            processed_at: !isSmallScreen,
            used: !isSmallScreen,
            template: false,
            queue_name: false,
            periodic: false,
            actions: !isSmallScreen,
            historic: !isSmallScreen,
            notifications: !isSmallScreen
        })
    }, [isSmallScreen])

    const handleHistory = (value) => {
        updateCampaignData(value)
        updateShowCampaignHistory(true)
    }

    const handleDelete = (value) => {
        updateCampaignData(value)
        updateShowCampaignDelete(true)
    }

    const handleEdit = (value) => {
        updateCampaignData(value)
        updateShowCampaignEdit(true)
    }

    const deleteCampaign = async () => {
        updateReady(false)
        const url = `/api/enterprises/${enterpriseId}/campaign/${campaignData.id}/`;
        const response = await api.delete(url)

        if (response.status !== 204){
            useNotification(
                'Ops!',
                'Você não tem permissão para realizar essa ação',
                'danger'
            );
            updateReady(true)
            return
        }

        updateCampaigns(rmvObj(campaigns, [campaignData.id]))
        updateReady(true)
    }

    const submitCampaign = async () => {
        updateShowCampaignEdit(false)
        return(true)
    }

    const resendNotifications = (notifications, campaignId, dateRange) => {
        api.post('api/notification/resend/', {ids: notifications})
        .then((res) => {
            if (res.status === 201) {
                setupNotification(campaignId, dateRange)
                return
            }
            throw error
        })
        .catch((err) => { console.error(err) })
    }

    const setupNotification = async (campaignId, dateRange) => {

        updateStartDateTime(dateRange[0])
        updateEndDateTime(dateRange[1])

        updateSelectedTab('notifications')

        let limit = 25
        let offset = 0
        if (limitOffset.notifications.length > 0) {
            limit  = limitOffset.notifications[0]
            offset = limitOffset.notifications[1]
        }

        let filter = {
            items: [{
                columnField: 'campaign_id',
                operatorValue: 'equals', value: String(campaignId)
            }]
        }

        fetchNotifications(dateRange[0], dateRange[1], 'filter', limit, offset, filter)

        // The code below is not working due to report changes

        // if (userGroups.isAdmin || userGroups.isSupervisor) {
        //     window.location.href = `?startDate=${dateRange[0]}&endDate=${dateRange[1]}&campaignId=${campaignId}`
        // } else {
        //     updateStartDateTime(dateRange[0])
        //     updateEndDateTime(dateRange[1])

        //     updateSelectedTab('notifications')

        //     let limit = 25
        //     let offset = 0
        //     if (limitOffset.notifications.length > 0) {
        //         limit  = limitOffset.notifications[0]
        //         offset = limitOffset.notifications[1]
        //     }

        //     let filter = {
        //         items: [{
        //             columnField: 'campaign_id',
        //             operatorValue: 'equals', value: String(campaignId)
        //         }]
        //     }

        //     fetchNotifications(dateRange[0], dateRange[1], 'filter', limit, offset, filter)
        // }
    }

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer >
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        )
    }

    const table = useMemo(() => {

        const canCreateCampaigns = (permissions.isAdmin || permissions.isSupervisor) ? true : settings.agent_can_create_campaigns

        const theme = createTheme({
            palette: {
                primary: { main: '#21446C' },
            },
        }, ptBR, corePtBR);

        const filterOperators = getGridStringOperators().filter(
            (operator) => ['contains', 'equals'].includes(operator.value) 
        )
        const dateOperators = getGridDateOperators().filter(
            (operator) => ['after', 'before'].includes(operator.value)
        )

        const booleanOperators = getGridBooleanOperators()

        const choiceOperators = getGridSingleSelectOperators().filter(
            (operator) => ['is', 'not'].includes(operator.value)
        )

        const initializeColumns = () => {
            const thisColumns = (
                [
                    { field: 'name', headerName: 'Campanha', flex: 1, filterOperators: filterOperators },
                    { field: 'template', headerName: 'Template', flex: 1, hide: true, filterOperators: filterOperators },
                    { field: 'sender', headerName: 'Notificador', flex: 0.3, hide: true, filterOperators: filterOperators },
                    {
                        field: 'groups', headerName: 'Segmentos', flex: 1, hide: isSmallScreen, filterOperators: filterOperators,
                        sortable: false,
                        renderCell: ({ value }) => {
                            if (value) {
                                return value.map(group => group.name).join(', ')
                            }
                        }
                    },
                    {
                        field: 'created_at', headerName: 'Data', flex: 0.3, hide: true, type: 'dateTime', filterOperators: dateOperators,
                        valueGetter: ({ value }) => {
                            if (value) {
                                const date = new Date(value);
                                date.setHours(date.getHours() - 3);
                                return date;
                            }
                        },
                        renderCell: (params) => (
                            <Box>
                                {params.value ? params.value.toLocaleString('pt-BR') : ''}
                            </Box>
                        )
                    },
                    {
                        field: 'next_execution', headerName: 'Próxima execução', flex: 0.5, type: 'dateTime', filterOperators: dateOperators,
                        valueGetter: ({ value }) => {
                            if (value) {
                                const date = new Date(value);
                                date.setHours(date.getHours() - 3);
                                return date;
                            }
                        },
                        renderCell: (params) => (
                            <Box>
                                {params.value ? params.value.toLocaleString('pt-BR') : ''}
                            </Box>
                        )
                    },
                    { field: 'queue_name', headerName: 'Fila', flex: 0.5, hide: true, filterOperators: filterOperators},
                    {
                        field: 'periodic', headerName: 'Periódico', flex: 0.2, hide: true, filterOperators: booleanOperators,
                        renderCell: (params) => {
                            if (params.value) {
                                return (<CheckCircleIcon className="icon-answered" />);
                            }
                            return (<CancelIcon className="icon-not-answered" />);
                        }
                    },
                    {
                        field: 'active', headerName: 'Status', flex: 0.2, 
                        filterOperators: choiceOperators,  
                        valueOptions: ['Finalizado', 'Agendado', 'Edição'],
                        renderCell: (params) => (
                            <Chip 
                                label={! params.value ? "Edição" : params.row.next_execution ? "Agendado" : "Finalizado" }
                                color={! params.value ? "warning" : params.row.next_execution ? "info" : "success" }
                                size="small"
                            />
                        )
                    },
                    {
                        field: 'actions', headerName: 'Ações', flex: 0.2, hide: isSmallScreen, filterable: false, sortable: false,
                        renderCell: (params) => (
                            <Box>
                                <Button className="action-button"
                                    onClick={() => handleEdit(params.row)}>
                                    <EditIcon />
                                </Button>
                                <Button className="action-button delete"
                                    onClick={() => handleDelete(params.row)}>
                                    <DeleteIcon />
                                </Button>
                            </Box>
                        )
                    },
                    {
                        field: 'historic', headerName: 'Histórico', flex: 0.2, hide: isSmallScreen,  filterable: false, sortable: false,
                        renderCell: (params) => (
                            <Box>
                                <Button className="action-button"
                                    onClick={() => { handleHistory(params.row) }}>
                                    <HistoryIcon />
                                </Button>
                            </Box>
                        )
                    },
                    {
                        field: 'notifications', headerName: 'Disparos', flex: 0.2, hide: isSmallScreen,  filterable: false, sortable: false,
                        renderCell: (params) => (
                            <Box>
                                <Button className="action-button"
                                    onClick={() => setupNotification(params.id, params.row.date_range)}>
                                    <MessageIcon />
                                </Button>
                            </Box>
                        )
                    },
                ]
            )
            if (!canCreateCampaigns)
                delete thisColumns[9]

            return thisColumns
        }

        const getPage = (limit, offset, direction) => {
            if (direction == 'next')
                return Math.round(offset / limit) - 1
            return Math.round(offset / limit) + 1

        }
        const getQueryset = (string, search, delimiter) => {
            const queryset = string.split(search)[1]
            if (queryset) {
                const result = queryset.split(delimiter)
                return result ? result[0] : ''
            }
            return ''
        }
        const getLimitOffset = (url) => {
            const queryset = url.split('?').pop()
            limit = getQueryset(queryset, 'limit=', '&')
            offset = getQueryset(queryset, 'offset=', '&')
            if (pageLimit)
                limit = pageLimit
            return [limit, offset]
        }

        const handlePageChange = (event) => {
            if (event > page) {
                fetchCampaigns(...next)
                return
            }
            fetchCampaigns(...previous)
        }

        const handlePageSizeChange = (newPage) => {
            updatePageLimit(newPage)
            fetchCampaigns('auto', newPage, actual)
        }

        let count = 0
        let page = 0
        let next = ''
        let previous = ''
        let limit = 25
        let oldlimit = 0
        let offset = 0
        let actual = 0

        if (limitOffset.campaigns.length > 0) {
            limit = limitOffset.campaigns[0]
        }

        if (ServerData) {
            count = ServerData.campaigns.count
            previous = ServerData.campaigns.previous
            next = ServerData.campaigns.next


            if (next) {
                const limitOffset = getLimitOffset(next)
                next = ['auto', ...limitOffset]
                page = getPage(...limitOffset, 'next')
                offset = limitOffset.pop()
                oldlimit = limitOffset.pop()
                actual = Number(offset) - Number(limit)
                updateLimitOffset(prevState => ({ ...prevState, campaigns: [oldlimit, actual] }))
            }

            if (previous) {
                const limitOffset = getLimitOffset(previous)
                previous = ['auto', ...limitOffset]
                if (page == 0) {
                    page = getPage(...limitOffset, 'previous')
                    offset = limitOffset.pop()
                    oldlimit = limitOffset.pop()
                    actual = Number(offset) - Number(limit)
                    updateLimitOffset(prevState => ({ ...prevState, campaigns: [oldlimit, actual] }))
                }

            }



        }

        const handleFilterChange = event => {
            fetchCampaigns('filter', limit, actual, event)
            updateCampaignsFilter(event)
        }

        const handleSortChange = event => {
            fetchCampaigns('sort', limit, actual, { items: [] }, event)
            updateCampaignSortModel(event)
        }

        return (
            <>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <ThemeProvider theme={theme}>
                        <DataGridPro
                            columns={initializeColumns()}
                            rows={campaigns}
                            disableSelectionOnClick
                            components={{ Toolbar: CustomToolbar }}
                            density={'compact'}

                            // columnVisibilityModel={columnVisibilityModel}
                            // onColumnVisibilityModelChange={(newModel) =>
                            //     setColumnVisibilityModel(newModel)
                            // }

                            //pagination
                            pagination
                            paginationMode="server"
                            rowsPerPageOptions={[25, 50, 100]}
                            page={page}
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            rowCount={count}
                            keepNonExistentRowsSelected
                            initialState={{
                                pagination: {
                                    pageSize: Number(limit)
                                }
                            }}

                            //filter
                            filterMode='server'
                            onFilterModelChange={handleFilterChange}
                            filterModel={campaignsFilter}

                            sortingMode="server"
                            sortModel={campaignSortModel}
                            onSortModelChange={handleSortChange}
                        />
                    </ThemeProvider>
                </Box>
            </>
        )

    }, [campaigns, columnVisibilityModel, campaignsFilter])

    return (
        <>
            {table}
            <CreateCampaignModal open={showCampaignEdit}
                title="Nova campanha"
                buttonText="Adicionar"
                selectedKey={campaignData.id}
                queues={queues}
                senders={originNumbers}
                templates={templates}
                enterpriseId={enterpriseId}
                handleClose={() => { }}
                submit={submitCampaign}
            />
            <ConfirmationDialog open={showCampaignDelete}
                title="Confirmação"
                description={`Tem certeza que deseja excluir a campanha ${campaignData.name}?`}
                handleClose={() => { updateShowCampaignDelete(false) }}
                handleSubmit={deleteCampaign}
            />

            <CampaignHistory open={showCampaignHistory}
                title="Histórico da campanha"
                data={campaignData}
                enterpriseId={enterpriseId}
                buttonText="Fechar"
                handleClose={() => { updateShowCampaignHistory(false) }}
                setupNotification={setupNotification}
                resendNotifications={resendNotifications}
            />
        </>
    )
}

export default CampaignTable