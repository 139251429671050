export const KWIKAPI_TOKEN            = '@kwikapi-token';
export const KWIKAPI_ADMIN_TOKEN      = '@kwikapi-admin-token';
export const KWIKAPI_SUPERADMIN_TOKEN = '@kwikapi-superadmin-token';

export const SESSION = '';

export const isAuthenticated = sessionStorage.getItem(KWIKAPI_TOKEN) !== null;
export const isSuperAdminAuthenticated = sessionStorage.getItem(KWIKAPI_SUPERADMIN_TOKEN) !== null;

export const getToken           = () => sessionStorage.getItem(KWIKAPI_TOKEN);
export const getAdminToken      = () => sessionStorage.getItem(KWIKAPI_ADMIN_TOKEN);
export const getSuperAdminToken = () => sessionStorage.getItem(KWIKAPI_SUPERADMIN_TOKEN);

export const getSession = () => JSON.parse(sessionStorage.getItem(SESSION));

export const saveToken           = (token) => sessionStorage.setItem(KWIKAPI_TOKEN, token);
export const saveAdminToken      = (token) => sessionStorage.setItem(KWIKAPI_ADMIN_TOKEN, token);
export const saveSuperAdminToken = (token) => sessionStorage.setItem(KWIKAPI_SUPERADMIN_TOKEN, token);

export const saveSession = (session) => sessionStorage.setItem(SESSION, JSON.stringify(session));

export const clearSession = () => sessionStorage.removeItem(SESSION);
export const clearToken   = () => sessionStorage.removeItem(KWIKAPI_TOKEN);
