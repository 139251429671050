import React, { useState } from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import Button from '@mui/material/Button';
import { Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { IMaskInput } from 'react-imask';
import IntegerOnly from '../../../../utils/IntegerOnly';

const validateTelephoneMask = (number) => {
  let response = []

  if (!number){
    response.push(<Box>Digite um número.</Box>)
    return response
  }

  const clear_number = String(IntegerOnly(number))

  if (clear_number?.length < 10)
    response.push(<Box>Números de telefone precisam ter pelo menos 10 digitos.</Box>)
  if (clear_number?.length === 11 && clear_number[2] !== '9')
    response.push(<Box>Para esse formato de telefones, é necessário que o numero após o DDD seja um 9.</Box>)

  if (response.length === 0)
    return ''
  return response
}

const telephoneMask = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={[
        {
          mask: '(00) 0000-0000',
          overwrite: true,
        }, {
          mask: '(00) 00000-0000',
          overwrite: true,
        },
      ]}
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const CampaignSummary = (props) => {

  const selectedQueue = props.queues.filter(item => item[1] == props.queue)[0]

  const groupNameList = props.groupkey.map(each => each.label).join(' ,')

  const templateName = props.template ? props.template.components[0].text : 'Template Excluído'

  const literal = props.sendNow ? 'Enviar agora' : props.datetime.LITERAL

  let queueName = ''
  let templateText = ''
  let senderName = ''
  if (props.platform === 'WHATSAPP') {
    queueName = selectedQueue[0]
    senderName = props.sender
    templateText = props.template ? ((props.template.components[0] &&
      props.template.components[0]?.type) === 'BODY' ?
      props.template.components[0]?.text :
      '[MIDIA] ' + props.template?.components[1]?.text) : 'TEMPLATE INVÁLIDO/REMOVIDO'
  }
  else if (props.platform === 'SMS') {
    queueName = props.state.queue ? props.state.queue.split('_').pop() : 0
    templateText = props.state.msg
    senderName = props.state.phone_name
  }


  let period = props.time
  if (period == 'once') {
    period = props.sendNow ? 'Uma vez' : 'Uma vez na data'
  }
  else {
    period = 'Periodo'
  }

  let parameterText = ''
  const deployParameters = props.mediaParameters

  if ('headerfile' in deployParameters) {
    if (typeof (deployParameters.headerfile) == 'object')
      parameterText += `Cabeçalho: ${deployParameters.headerfile.name}.\n`
    else {
      parameterText += `Cabeçalho: ${deployParameters.headerfile.split('/').pop()}.\n`
    }
  }

  let headerstring = deployParameters.headerbody
  if (!('headerfile' in deployParameters) && 'headerURL' in deployParameters)
    headerstring = deployParameters.headerURL

  headerstring ? parameterText += `Cabeçalho: ${headerstring}.                                                  \n` : null
  deployParameters.body ? parameterText += `Corpo:     ${Object.values(deployParameters.body).join(', ')}.      \n` : null
  deployParameters.buttons ? parameterText += `Botões:    ${deployParameters.buttons}.                          \n` : null

  const campaignTestSubmit = () => {
    props.setTestCampaignModal(false)
    props.onSubmit('test-activate')
  }

  const phoneValid = validateTelephoneMask(props?.testCampaignNumber)

  return (
    <>
      {props.testCampaignModal &&
        <Dialog open={true}>
          <DialogTitle id="customized-dialog-title">
            Testar campanha
          </DialogTitle>
          <Divider />
          <DialogContent>
            <TextField
              label="Digite o número"
              size="small"
              value={props.testCampaignNumber}
              onChange={(e) => props.setTestCampaignNumber(e.target.value)}
              InputProps={{
                inputComponent: telephoneMask
              }}
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton onClick={() => props.setTestCampaignModal(false)}>
              Cancelar
            </LoadingButton>
            <Tooltip title={phoneValid}>
              <span>
                <LoadingButton
                  disabled={phoneValid}
                  onClick={() => campaignTestSubmit()}>
                  Enviar
                </LoadingButton>
              </span>
            </Tooltip>
          </DialogActions>
        </Dialog>
      }
      <h4>Resumo da campanha.</h4>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>

        <nav aria-label="secondary mailbox folders">
          <List sx={{ width: '100%', maxHeight: '25rem', overflow: 'auto' }} >

            <ListItem sx={{ backgroundColor: "#0080008f", color: "white" }} disablePadding>
              <ListItemButton>
                <ListItemText primary={`${period}`} secondary={`${literal}`} />
              </ListItemButton>
            </ListItem>

            <Divider />

            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="Nome" secondary={props.name} />
              </ListItemButton>
            </ListItem>

            <Divider />

            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="Segmento(s)" secondary={groupNameList} />
              </ListItemButton>
            </ListItem>

            <Divider />

            {queueName ?
              <>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText primary="Fila" secondary={queueName} />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </>
              : null}



            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="Número de origem" secondary={senderName} />
              </ListItemButton>
            </ListItem>

            <Divider />

            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="Template" secondary={
                  templateText
                } />
              </ListItemButton>
            </ListItem>
            <Divider />
            {parameterText &&
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText sx={{ overflowWrap: 'break-word' }} primary="Parâmetros" secondary={
                    parameterText
                  } />
                </ListItemButton>
              </ListItem>}

          </List>
        </nav>
      </Box>
    </>
  )
}

export default CampaignSummary