import React from "react"

import AdminDrawer from "../../components/AdminDrawer"
import ManagersComponent from "../../components/Managers"

const Managers = () => {
  
    return (
        <AdminDrawer child={<ManagersComponent/>}/>
    );
};

export default Managers
