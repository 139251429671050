import React from "react"

import AdminDrawer from "../../components/AdminDrawer"
import EnterprisesComponent from "../../components/Enterprises"

const Enterprises = () => {
  
    return (
        <AdminDrawer child={<EnterprisesComponent/>}/>
    );
};

export default Enterprises
