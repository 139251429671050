import react from 'react'
import { Alert, Tooltip, Box } from '@mui/material'

const MESSAGES = {
    'DEFAULTER5': 'Existem pendências financeiras para esta conta - Aguardamos providências',
    'DEFAULTER10': 'Disparos ativos e campanhas bloqueados',
    'DEFAULTER15': <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <span>Disparos ativos e campanhas bloqueados</span>
        <span>Relatórios bloqueados</span>
    </Box>,
    'DEFAULTER30': <Box sx={{display: 'flex', flexDirection: 'column'}}>
    <span>Disparos ativos e campanhas bloqueados</span>
    <span>Relatórios bloqueados</span>
    <span>Acesso apenas adminsitrador</span>
</Box>
}

const ChargeStatus = ({ status }) => {

    const has_message = MESSAGES[status] || ''


    return (
        <>
            {
                has_message ?
                    <Tooltip title={has_message}>
                        <Alert variant="filled" severity="error" className="trial-info-alert">
                            Existem pendências financeiras para esta conta - Aguardamos providências
                        </Alert >
                    </Tooltip> : null
            }
        </>
    )
}

export default ChargeStatus