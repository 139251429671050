import react, { useEffect, useState } from "react"

import { 
    Box,
    Button,
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    TextField,
    Switch,
    FormGroup,
    FormControlLabel
} from "@mui/material"

import { createTheme, ThemeProvider } from "@mui/material/styles"

import { 
    DataGridPro, 
    GridToolbarContainer, 
    GridToolbarColumnsButton, 
    GridToolbarFilterButton, 
    gridClasses, 
    ptBR 
} from "@mui/x-data-grid-pro"

import { ptBR as corePtBR } from "@mui/material/locale"

import LoadingButton from "@mui/lab/LoadingButton"

import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import AddCircleIcon from "@mui/icons-material/AddCircle"

import useNotification from "../Notification"
import ConfirmationDialogCaptcha from "../Dialogs/ConfimationDialogCaptcha"

import api from "../../api"

import "./styles.css"

const ManagersComponent = () => {

    const [managers, setManagers] = useState([])
    const [manager, setManager] = useState()
    const [toDeleteManager, setToDeleteManager] = useState()

    const theme = createTheme({
        palette: {
            primary: { main: "#21446C" },
        },
    }, ptBR, corePtBR)

    useEffect(() => {
        api.get("/api/managers/").then((res) => {
            if (res.status === 200) {
                setManagers(res.data)
                return
            }
            throw err
        }).catch((err) => {})
    }, [])

    const deleteManager = (manager) => {
        api.delete(`/api/managers/${manager.id}/`).then((res) => {
            if (res.status === 204) {
                setManagers((prevManagers) => prevManagers.filter((item) => item.id !== manager.id))
                setToDeleteManager(null)
                useNotification(
                    "Sucesso!",
                    "Gerente de contas removido com sucesso.",
                    "success"
                )
                return
            }
            throw err
        }).catch((err) => {
            useNotification(
                "Ops!",
                "Não foi possível remover o gerente de contas, tente novamente.",
                "danger"
            )
        })
    }

    const managersColumns = [
        { field: "name", headerName: "Nome", flex: 1 },
        { field: "email", headerName: "E-mail", flex: 1 },
        { field: "is_resale", headerName: "Revenda", flex: .5,
            renderCell: (params) => (params.row.is_resale) ? "Sim" : "Não"
        },
        { field: "action", headerName: "Ações", flex: .25, filterable: false,
            renderCell: (params) => (
                <>
                    <Box>
                        <Button className="action-button"
                            onClick={() => setManager(params.row)}>
                            <EditIcon />
                        </Button>
                    </Box>
                    <Box>
                        <Button className="action-button delete"
                            onClick={() => setToDeleteManager(params.row)}>
                            <DeleteIcon />
                        </Button>
                    </Box>
                </>
            )
        }
    ]

    const CustomToolbar = () => (
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
            <Box className='toolbarLeft'>
                <GridToolbarColumnsButton size="small" />
                <GridToolbarFilterButton size="small" className={'filterControl'} />
                <LoadingButton size="small" component="label" startIcon={<AddCircleIcon/>}
                    onClick={() => {
                        setManager({
                            name      : "",
                            email     : "",
                            is_resale : false
                        })
                    }}
                >
                    Adicionar
                </LoadingButton>
            </Box>
        </GridToolbarContainer>
    )

    const updateManager = (manager) => {
        api.patch(`/api/managers/${manager.id}/`, {
            name      : manager.name,
            email     : manager.email,
            is_resale : manager.is_resale
        }).then((res) => {
            if (res.status === 200) {
                setManagers((prevManagers) => prevManagers.map((item) => item.id === manager.id ? res.data : item))
                setManager(null)
                useNotification(
                    "Sucesso!",
                    "Gerente de contas atualizado com sucesso.",
                    "success"
                )
                return
            }
            throw res
        }).catch((err) => {
            if (err.status === 400) {
                Object.keys(err.data).forEach((item) => {
                    let field = (item === "name") ? "Nome" : (item === "email") ? "E-mail" : null
                    if (field) {
                        useNotification(
                            "Campo Obrigatório!",
                            `O campo ${field} deve ser preenchido.`,
                            "warning"
                        )
                    }
                })
                return
            }
            useNotification(
                "Ops!",
                "Não foi possível atualizar o gerente de contas, tente novamente.",
                "danger"
            )
        })
    }

    const createManager = (manager) => {
        api.post("/api/managers/", {
            name      : manager.name,
            email     : manager.email,
            is_resale : manager.is_resale
        }).then((res) => {
            if (res.status === 201) {
                setManagers((prevManagers) => [...prevManagers, res.data])
                setManager(null)
                useNotification(
                    "Sucesso!",
                    "Gerente de contas adicionado com sucesso.",
                    "success"
                )
                return
            }
            throw res
        }).catch((err) => {
            if (err.status === 400) {
                Object.keys(err.data).forEach((item) => {
                    let field = (item === "name") ? "Nome" : (item === "email") ? "E-mail" : null
                    if (field) {
                        useNotification(
                            "Campo Obrigatório!",
                            `O campo ${field} deve ser preenchido.`,
                            "warning"
                        )
                    }
                })
                return
            }
            useNotification(
                "Ops!",
                "Não foi possível adicionar o gerente de contas, tente novamente.",
                "danger"
            )
        })
    }

    return (
        <Box className="managers-container">
            {toDeleteManager &&
                <ConfirmationDialogCaptcha open={toDeleteManager}
                    title="Remover gerente de contas"
                    description={
                        <span>
                            Para remover o gerente de contas {toDeleteManager.name}, digite: <span style={{ 'fontWeight': 'bold' }}>remover</span> no campo abaixo.
                        </span>
                    }
                    handleClose={() => setToDeleteManager(null)}
                    handleSubmit={() => deleteManager(toDeleteManager)}
                    captcha={'remover'}
                    alternateNo="Cancelar"
                    alternateYes="Confirmar"
                    alternateName=""
                />
            }
            {manager &&
                <Dialog fullWidth maxWidth="sm" open={manager !== null} onClose={() => setManager(null)}>
                    <DialogTitle>{(manager.id) ? "Editar gerente de contas" : "Adicionar gerente de contas"}</DialogTitle>
                    <Divider/>
                    <DialogContent style={{ paddingTop: "2rem" }}>
                    {manager && (
                        <Box component="form" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            <TextField
                                type="text"
                                label="Nome"
                                size='small'
                                value={manager.name}
                                onChange={(event) => setManager((prevState) => ({
                                    ...prevState, 
                                    name: event.target.value
                                }))}
                                fullWidth
                            />
                            <TextField
                                type="text"
                                label="E-mail"
                                size='small'
                                value={manager.email}
                                onChange={(event) => setManager((prevState) => ({
                                    ...prevState, 
                                    email: event.target.value
                                }))}
                                fullWidth
                            />
                            <FormGroup>
                                <FormControlLabel control={
                                    <Switch
                                        color="primary"
                                        checked={manager.is_resale}
                                        onChange={(event) => setManager((prevState) => ({
                                            ...prevState, 
                                            is_resale: event.target.checked
                                        }))}
                                    />
                                }
                                label="Revenda"/>
                            </FormGroup>
                        </Box>
                    )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setManager(null)}>Cancelar</Button>
                      <Button onClick={() => (manager.id) ? updateManager(manager) : createManager(manager)}>Salvar</Button>
                    </DialogActions>
                </Dialog>
            }
            <ThemeProvider theme={theme}>
                <DataGridPro
                    rows={managers}
                    columns={managersColumns}
                    density={"compact"}
                    pagination
                    paginationMode="client"
                    pageSize={20}
                    rowsPerPageOptions={[20, 50, 100]}
                    disableSelectionOnClick
                    components={{ Toolbar: CustomToolbar }}
                />
            </ThemeProvider>
        </Box>
    )
}


export default ManagersComponent