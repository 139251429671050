import react, { useEffect, useReducer, useState } from 'react'
import api from '../../api'
import { lockedReducer } from '../../utils/defaultReducer'
import {
    DataGridPro,
    ptBR,
} from '@mui/x-data-grid-pro';
import {
    gridClasses,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridToolbarExportContainer,
} from '@mui/x-data-grid-pro';
import { ptBR as corePtBR } from '@mui/material/locale';
import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';
import { Box, IconButton, Tooltip, Button, Dialog, DialogTitle, DialogContent, TextField } from '@mui/material';
import dayjs from 'dayjs'
import Loading from '../Loading';

import InfoIcon from '@mui/icons-material/Info';

import { dateOperators, stringOperators, choiceOperators } from '../../utils/filterOperators';

import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ConfirmationDialogCaptcha from '../Dialogs/ConfimationDialogCaptcha';
import CloseIcon from '@mui/icons-material/Close'
import useNotification from '../Notification';
import ArchiveIcon from '@mui/icons-material/Archive';

import './styles.css'

const translate_status = {
    'CREATED': 'Emitida',
    'FINALIZED': 'Baixada'
}

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const defaultLimit = 25

const columns = (setState) => {
    return [
        { field: 'sequencial', headerName: 'Sequencial', flex: 1, filterOperators: stringOperators, sortable: false },
        { field: 'cnpj', headerName: 'CPF/CNPJ', flex: 1, filterOperators: stringOperators, sortable: false },
        { field: 'status', headerName: 'Status', flex: 1, filterOperators: stringOperators, sortable: false, renderCell: ({value}) => translate_status[value] || value },
        { field: 'emission', headerName: 'Emissão', filterOperators: dateOperators, flex: 1, renderCell: ({ value }) => dayjs(value).format('DD/MM/YYYY HH:mm'), sortable: false },
        { field: 'finalized_at', headerName: 'Data de Baixa', filterOperators: dateOperators, flex: 1, renderCell: ({ value }) => value ? dayjs(value).format('DD/MM/YYYY HH:mm') : '', sortable: false },
        {
            field: 'actions', headerName: 'Ações', filterable: false, sortable: false,
            renderCell: ({ row }) =>
                <Box className='report-action-buttons'>
                    <Tooltip title="Link para boleto" placement="top" disableInteractive>
                        <a href={row.boleto} target='_blank'>
                            <Button className="action-button" >
                                <ReceiptIcon />
                            </Button>
                        </a>
                    </Tooltip>
                    <Tooltip title="Informações do boleto" placement="top" disableInteractive>
                        <Button className="action-button" onClick={() => setState({ seeBoleto: row })}>
                            <InfoIcon />
                        </Button>
                    </Tooltip>
                    {row.status === 'CREATED' &&
                        <Tooltip title="Baixar boleto" placement="top" disableInteractive>
                            <Button className="action-button delete" onClick={() => setState({ cancelModal: row })}>
                                <ArchiveIcon />
                            </Button>
                        </Tooltip>}
                </Box>
        },
    ]
}


const CustomToolbar = (state, setState) => {
    return (
        <GridToolbarContainer className={gridClasses.toolbarContainer} >
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    )
}

const fetchRows = async (limit, page, filterModel, setState) => {
    setState({ loading: true })
    const url = new URLSearchParams()
    url.append('limit', limit)
    url.append('offset', page * limit)
    url.append('sort', 'id__desc')
    if (filterModel.linkOperator === 'or')
        url.append('OR', true)
    filterModel.items.forEach((item) => {
        url.append(`${item.columnField}__${item.operatorValue}`, item.value)
    })

    const res = await api.get(`api/payments/fetchboletos/?${url.toString()}`)
    const state = { limit: limit, page: page, filterModel: filterModel, loading: false }
    if (res.status === 200) {
        state.rows = res.data.results
        state.count = res.data.count
    }
    setState(state)
}

const cancelarBoleto = async (data, state, setState) => {
    setState({ loading: true })
    const res = await api.post(`api/payments/payment_slip/finalize/`, { nossoNumero: data.sequencial, codigoBaixa: 57 })
    const newState = { loading: false }
    if (res.status === 200) {
        newState.cancelModal = null
        useNotification(
            'Sucesso!',
            'Boleto baixado com sucesso.',
            'success'
        )
        await fetchRows(state.limit, state.page, state.filterModel, setState)
    } else {
        useNotification(
            'Ops!',
            'Não foi possível cancelar o boleto.',
            'danger'
        )

    }
    setState(newState)
}

const seeBoleto = async (data, setParent, setState) => {
    setParent({ loading: true })
    const res = await api.post(`api/payments/payment_slip/check_one/`, { nossoNumero: data.sequencial })
    const newState = { loading: false }
    if (res.status === 200) {
        const {titulo, cedente, sacado, sacador, baixa, ...return_data} = {...res.data, ...res.data.titulo, ...res.data.cedente, ...res.data.sacado, ...res.data.sacador, ...res.data.baixa}
        setState(return_data)
    } else {
        useNotification(
            'Ops!',
            'Não foi possível encontrar o boleto.',
            'danger'
        )

    }
    setParent(newState)
}

const SeeModal = ({ data, setParent }) => {

    const [state, setState] = useState()

    useEffect(() => {
        seeBoleto(data, setParent, setState)
    }, [])

    return (
        <>
            <Dialog
                open={true}
                disablePortal
                fullWidth
                maxWidth='md'
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle className={'group-flexbox'}>
                    <span>Informações do boleto</span> <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={() => { setParent({ seeBoleto: null }) }} ><CloseIcon /></Button>
                </DialogTitle>
                <DialogContent>
                    <Box className='automatic-flex-box'>
                        {state && Object.entries(state).map(([key, value]) => (
                            <TextField
                                size='small'
                                label={key}
                                defaultValue={value}
                            />
                        ))}
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

let changeTimeout = 0
const changeTimeoutTime = 1000

const BoletoComponent = () => {

    const [state, setState] = useReducer(lockedReducer, {
        rows: [],
        limit: defaultLimit,
        page: 0,
        loading: false,
        count: 0,
        filterModel: { items: [] },
        cancelModal: null,
        seeBoleto: null
    })

    useEffect(() => {
        fetchRows(defaultLimit, 0, { items: [] }, setState)
    }, [])

    return (
        <Box className="plans-container">
            <Loading loading={state.loading} />
            {state.seeBoleto && <SeeModal data={state.seeBoleto} setParent={setState} />}
            {
                state.cancelModal &&
                <ConfirmationDialogCaptcha
                    captcha={'cancelar'}
                    description={<Box>Para cancelar o boleto {state.cancelModal.sequencial}, digite <b>cancelar</b> no campo abaixo.</Box>}
                    alternateYes='Confirmar'
                    alternateNo='Fechar'
                    open={true}
                    title={`Cancelar boleto ${state.cancelModal.sequencial}`}
                    handleClose={() => setState({ cancelModal: null })}
                    handleSubmit={() => { cancelarBoleto(state.cancelModal, state, setState) }}
                />
            }
            <StyledEngineProvider>
                <ThemeProvider theme={theme}>
                    <DataGridPro
                        disableSelectionOnClick
                        disableSorting
                        disableColumnSorting
                        disableMultipleColumnsSorting
                        disableDensitySelector
                        density="compact"
                        components={{
                            Toolbar: () => CustomToolbar(state, setState)
                        }}
                        columns={columns(setState)}
                        rows={state.rows}
                        pagination
                        paginationMode='server'
                        page={state.page}
                        rowCount={state.count}
                        rowsPerPageOptions={[25, 50, 100]}
                        pageSize={state.limit}
                        onPageSizeChange={(size) => {
                            fetchRows(size, state.page, state.filterModel, setState)
                        }}
                        initialState={{
                            pagination: {
                                pageSize: Number(state.limit)
                            }
                        }}
                        onPageChange={async (page) => {
                            fetchRows(state.limit, page, state.filterModel, setState)
                        }}
                        onFilterModelChange={(e) => {
                            clearTimeout(changeTimeout)
                            changeTimeout = setTimeout(() => fetchRows(state.limit, state.page, e, setState), changeTimeoutTime)
                        }}
                        filterModel={state.filterModel}
                        filterMode='server'
                    />
                </ThemeProvider>
            </StyledEngineProvider>
        </Box >
    )
}


export default BoletoComponent