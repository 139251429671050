import React from "react"

import AdminDrawer from "../../components/AdminDrawer"
import NfseComponent from "../../components/Nfse"

const Nfse = () => {
  
    return (
        <AdminDrawer child={<NfseComponent/>}/>
    );
};

export default Nfse
