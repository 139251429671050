
import { Dialog, DialogContent, DialogTitle, Button, Box, Tooltip, List, ListItem } from '@mui/material'
import ReactAudioPlayer from 'react-audio-player'
import ChatDocument from '../../../ChatDocument'
import Contact from '../../../Contact'
import Location from '../../../Location'
import Poll from '../../../Poll'
import dayjs from 'dayjs'
import { transformTimeData } from '../ChatList'
import api from '../../../../api';

const EditedMessage = ({ children, type, onClick }) => {
    return (
        <span onClick={onClick} className='scout-edited-message-span'>
            {children || 'Mensagem apagada.'}
            {type === 'EDITED' ? <span>Editada</span> : null}
        </span>
    )
}

export const parseMessage = (message, editList, showEditList = () => { }) => {


    if (editList.length > 0) {
        const lastMessage = editList[editList.length - 1]
        return (
            <EditedMessage
                onClick={() => { showEditList([message, ...editList]) }}
                type={lastMessage.type}>
                {lastMessage.message}
            </EditedMessage>)
    }

    switch (message.messageType) {

        case 'extendedTextMessage':
            return <>{message.message}</>
        case 'conversation':
            return <>{message.message}</>
        case 'imageMessage':
            if (message.caption) {
                return <><img height={'10rem'} src={`data:image/jpg;base64,${message.base64}`} alt='imagem' /> <p>{message.caption}</p></>
            } else {
                return <img height={'10rem'} src={`data:image/jpg;base64,${message.base64}`} alt='imagem' />
            }
        case 'videoMessage':
            if (message.caption) {
                return <><video src={`data:video/mp4;base64,${message.base64}`} controls width="600"> Video </video> <p>{message.caption}</p></>
            } else {
                return <video src={`data:video/mp4;base64,${message.base64}`} controls width="600"> Video </video>
            }
        case 'stickerMessage':
            return <img src={`data:image/webp;base64,${message.base64}`} alt='imagem' className='scout-conversation-sticker' />
        case 'documentWithCaptionMessage':
        case 'documentMessage':
            return (
                <ChatDocument user={1}
                    name={message.title}
                    message={message}
                    src={`data:${message.mimetype};base64,${message.base64}`}
                    base64
                />
            )
        case 'audioMessage':
            return (<ReactAudioPlayer
                src={`data:audio/x-wav;base64,${message.base64}`}
                controls
                preload
                style={{ height: '2rem' }}
            />)
        case 'templateMessage':
            return <>{message.templateMessage?.hydratedTemplate?.hydratedContentText || ''}</>
        case 'contactMessage':
            return <Contact
                fullName={message.fullName}
                title={message.title}
                phone={message.phone}
                email={message.email}
                address={message.address}
            />
        case 'locationMessage':
            return <Location
                name={message.name}
                address={message.address}
                latitude={message.latitude}
                longitude={message.longitude}
            />
        case 'pollCreationMessageV3':
            return <Poll
                question={message.name}
                options={message.options}
            />
        case 'protocolMessage':
            return <>{message?.message || message?.protocolMessage?.editedMessage?.conversation || 'Essa mensagem foi apagada.'}</>

    }
    return ""
}


const ScoutMessageComponent = ({message, index, chatType, messages, parent, setPrevState, setParent, searchWidget, owner, conversation}) => {
  return ( 
    <ListItem disableGutters
        key={`individual-message-id-${index}`}
        className={`scout-window-content-item ${message.fromMe ? 'right' : 'left'}`}
        onClick={ async () => { 
            if (!searchWidget) return;

            const account = parent.accounts.find(account => owner.endsWith(account.phone_number));
            const res = await api.get(
                `api/enterprises/${account.enterprise}/scout_accounts/${account.id}/conversation/${conversation.info.id}/`
            );
            if (res.status == 200) {
                const activeChat = { account_id: account.id, ...res.data[0] };
                setParent({
                    activeAccount: account,
                    activeChat: activeChat,
                    chatMessageId: message.id,
                    showSearch: false,
                    showCRMSidebar: false,
                })
            }


        }}
    >
        <Box className='scout-conversation-box'>
            {chatType === 'GROUP' && <Box sx={{ fontSize: '0.6em', fontWeight: 'bold' }}>@{message.pushName}</Box>}
            {parseMessage(
                message, 
                messages.filter(msg => msg.type && msg.protocolId === message.id), 
                searchWidget ? () => {} : (data) => setPrevState({ editList: data })
            )}
            <Tooltip title={dayjs(message.timestamp * 1000).format('DD/MM/YYYY HH:mm')}>
                <Box className='scout-conversation-time'>{transformTimeData(message.timestamp)}
                </Box>
            </Tooltip>
        </Box>
    </ListItem>
  );
};

export default ScoutMessageComponent;