import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import {
    Box,
    Button,
    Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel'
import ErrorIcon from '@mui/icons-material/Error';

export const createColumns = (state, setState, newCrmSelected) => {

    const eventTypes = {
        'startCall'     : 'Chat',
        'queueJoin'     : 'Chat',
        'startHuman'    : 'Chat',
        'newMessage'    : 'Chat',
        'transfer'      : 'Chat',
        'endCall'       : 'Chat',
        'survey'        : 'Chat',
        'inboudCall'    : 'Voz',
        'outboundCall'  : 'Voz',
        'createContact' : 'CRM',
        'updateContact' : 'CRM',
        'deleteContact' : 'CRM'
    }

    return [
        { field: 'type', headerName: 'Tipo', flex: .2,
            renderCell: (params) => {
                return eventTypes[params.row.action]
            }
        },
        { field: 'name', headerName: 'Evento', flex: .4 },
        {
            field: 'builderbot', headerName: 'Bot', flex: .4,
            renderCell: (params) => {
                if (state.builder) {
                    if (state.builder.marketplace === undefined) {
                        state.builder.marketplace = [];
                    }
                    const chatbotName = [...state.builder.botlist, ...state.builder.marketplace].find(each => each.id === params.row.builderbot)?.name
                    const chatbot = state.builder.botlist.find(chatbot => chatbot.id === params.row.builderbot)
                    return (
                        <Box>
                            {chatbotName}
                            {params.row.replaced === true &&
                                <Tooltip title='Você possui uma integração configurada para este evento, ela será sobrescrita ao salvar.'>
                                    <ErrorIcon sx={{ 'color': '#c95f50', marginLeft: '.25rem' }}/>
                                </Tooltip>
                            }
                            {(! params.row.replaced || params.row.replaced === false) && chatbot && ! chatbot.publicId &&
                                <Tooltip title='Este ChatBot ainda não foi publicado.'>
                                    <ErrorIcon sx={{ 'color': 'orange', marginLeft: '.25rem' }}/>
                                </Tooltip>
                            }
                        </Box>
                    )
                } else
                    return params.row.builderbot
            }
        },
        {
            field: 'status', headerName: 'Ativo', flex: .1,
            renderCell: ({ value }) => {
                if (value)
                    return (<Box><CheckCircleIcon sx={{ 'color': '#5F8963' }} /></Box>)
                return (<Box><CancelIcon sx={{ 'color': '#C95F50' }} /></Box>)
            }
        },
        {
            field: 'actions', headerName: 'Ações', flex: .1,
            renderCell: (params) => (
                <Box>
                    <Tooltip title='Editar'>
                        <Button className="action-button"
                            onClick={() => setState({ edit: params.row })}>
                            <EditIcon />
                        </Button>
                    </Tooltip>
                    {
                        [...state?.builder?.botlist, ...state?.builder?.marketplace]?.find(each => each.id === params?.row?.builderbot)?.name && 
                        <Tooltip title='Editar Bot'>
                            <Button className="action-button"
                                onClick={async () => {
                                    setState({ showbot: params.row })
                                }} disabled={newCrmSelected}>
                                <PrecisionManufacturingIcon />
                            </Button>
                        </Tooltip>
                    }
                    {/* <Tooltip title='Excluir'>
                        <Button className="action-button delete"
                            onClick={() => setState({ delete: params.row })}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip> */}
                </Box>
            )
        },
    ]
}