import react, { useEffect, useState, forwardRef, useReducer } from "react"

import { useHistory } from "react-router-dom";

import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    TextField,
    MenuItem,
    Tooltip,
    Chip,
    IconButton,
    Alert
} from "@mui/material"

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"

import { createTheme, ThemeProvider } from "@mui/material/styles"

import * as moment from "moment"

import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    gridClasses,
    ptBR
} from "@mui/x-data-grid-pro"

import { IMaskInput } from "react-imask"

import { ptBR as corePtBR } from "@mui/material/locale"

import LoadingButton from "@mui/lab/LoadingButton"

import EditIcon from "@mui/icons-material/Edit"
import CloseIcon from '@mui/icons-material/Close'
import BusinessIcon from "@mui/icons-material/Business"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import ReportIcon from "@mui/icons-material/Assessment"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"
import InfoIcon from "@mui/icons-material/Info"
import InvoiceIcon from '@mui/icons-material/RequestQuote';

import useNotification from "../Notification"
import MultipleSelections from "../Input/MultipleSelections"

import api from "../../api"

import "./styles.css"
import { lockedReducer } from "../../utils/defaultReducer"
import dayjs from 'dayjs'
import Typography from "../Typography"
import Loading from '../Loading'

const PhoneMask = forwardRef((props, ref) => {
    return (
        <IMaskInput
            {...props}
            mask='(00) 00000-0000'
            inputRef={ref}
            onAccept={(value) => props.onChange({ target: { value } })}
            overwrite
        />
    )
})

const CnpfMask = forwardRef((props, ref) => {
    return (
        <IMaskInput
            {...props}
            mask={[{
                mask: props?.value?.length > 11 ? '00.000.000/0000-00' : '000.000.000-000'
            }]}
            inputRef={ref}
            unmask={true}
            overwrite
        />
    )
})

const ENTERPRISE_STATUS = {
    'NOTCOMPLIANT' : { color: "error"   , status: "Não aderente"         },
    'COMPLIANT'    : { color: "success" , status: "Adimplente"           },
    'DEFAULTER5'   : { color: "warning" , status: "Inadimplente 5 dias"  },
    'DEFAULTER10'  : { color: "warning" , status: "Inadimplente 10 dias" },
    'DEFAULTER15'  : { color: "warning" , status: "Inadimplente 15 dias" },
    'DEFAULTER30'  : { color: "error"   , status: "Inadimplente 30 dias" },
}

const enterprisesColumns = (managers, setState, history) => ([
    { field: "created_at", headerName: "Contratação", flex: 1,
        renderCell: (params) => (
            <Box>
                {params.value 
                    ? moment(params.value).format("DD/MM/YYYY HH:mm").toString() 
                    : ""
                }
            </Box>
        )
    },
    { field: "name", headerName: "Nome", flex: 1 },
    { field: "cnpj", headerName: "CNPJ", flex: 1 },
    { field: "plan", headerName: "Plano", flex: 1, 
        renderCell: (params) => params.row.plan?.name 
    },
    { field: "products", headerName: "Produtos Avulsos", flex: 1, 
        renderCell: (params) => (params.row.products.length > 0) 
            ? params.row.products.map((item) => `${item.quantity}x ${item.product.channel}: ${item.product.name}`).join(", ")
            : ""
    },
    { field: "monthly_cost", headerName: "Mensalidade", flex: 1,
        renderCell: (params) => {
            let planMonthlyCost = 0
            let planMonthlyPercentage = 0
            params.row.plan?.products.forEach((item) => {
                if (item.monthly_value) {
                    if (item.percentual_values)
                        planMonthlyPercentage += item.monthly_value
                    else
                        planMonthlyCost += item.monthly_value
                }
            })
            planMonthlyCost += planMonthlyCost * planMonthlyPercentage / 100
            planMonthlyCost = planMonthlyCost / .95
            let productsMonthlyCost = 0
            let productsMonthlyPercentage = 0
            params.row.products.forEach((item) => {
                if (item.monthly_value) {
                    if (item.percentual_values)
                        productsMonthlyPercentage += item.monthly_value
                    else
                        productsMonthlyCost += item.monthly_value
                }
            })
            productsMonthlyCost += productsMonthlyCost * productsMonthlyPercentage / 100
            productsMonthlyCost = productsMonthlyCost / .95
            const total = planMonthlyCost + productsMonthlyCost
            if (total)
                return (
                    <Box sx={{ display: "flex", flexDirection: "row", gap: .5 }}>
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        }).format(total)}
                        <Tooltip title="Total do plano, dos produtos avulsos, +5% de ISS" placement="right" disableInteractive>
                            <InfoIcon sx={{ color: "#5399db", fontSize: "1.25em", cursor: "pointer" }}/>
                        </Tooltip>
                    </Box>
                )
                return 
            return (<></>)
        }
    },
    { field: "due_date", headerName: "Vencimento", flex: 1 },
    { field: "start_charging", headerName: "Início da Cobrança", flex: 1,
        renderCell: (params) => (
            <Box>
                {params.value 
                    ? moment(params.value).format("DD/MM/YYYY").toString() 
                    : ""
                }
            </Box>
        )
    },
    { field: "end_charging", headerName: "Fim da Cobrança", flex: 1,
        renderCell: (params) => (
            <Box>
                {params.value 
                    ? moment(params.value).format("DD/MM/YYYY").toString() 
                    : ""
                }
            </Box>
        )
    },
    { field: "managers", headerName: "Gerentes de conta", flex: 1,
        renderCell: (params) => params.row.managers?.map((item) => item.name).join(", ")
    },
    { field: "charge_status", headerName: "Status", flex: 1,
        renderCell: ({value}) => {
            const { color, status } = ENTERPRISE_STATUS[value]
            return (
                <Chip label={status} color={color} size="small"/>
            )
        }
    },
    { field: "action", headerName: "Ações", flex: 1, filterable: false,
        renderCell: (params) => (
            <Box>
                <Tooltip title='Editar empresa'>
                    <Button className="action-button"
                        onClick={() => {
                            setState({
                                enterprise: params.row,
                                managersChecked: managers.reduce((obj, item) => {
                                    obj[item.id] = {
                                        name: item.name,
                                        checked: params.row.managers?.map((item2) => item2.id).includes(item.id),
                                    }
                                    return obj
                                }, {})
                            })
                        }}>
                        <EditIcon />
                    </Button>
                </Tooltip>
                <Tooltip title='Mais informações da empresa'>
                    <Button className="action-button"
                        onClick={() => setState({ enterpriseData: params.row })}>
                        <BusinessIcon />
                    </Button>
                </Tooltip>
                <Tooltip title='Histórico de pagamento'>
                    <Button className="action-button"
                        onClick={() => history.push(`/paymenthistory?enterprise=${params.row.name}`)}>
                        <ReportIcon />
                    </Button>
                </Tooltip>
                <Tooltip title='Gerar cobrança'>
                    <Button className="action-button"
                        onClick={() => setState({ 
                            invoiceData: {
                                enterprise     : params.row,
                                dueDate        : null,
                                monthlyValue   : null,
                                exceedingValue : null
                            }
                        })}>
                        <InvoiceIcon />
                    </Button>
                </Tooltip>
            </Box>
        )
    }
])

const isRegistered = async (cnpj) => {
    if (! cnpj) 
        return false
    const res = await api.get(`api/payments/contaazul/cnpj/${cnpj}/`)
    
    return res.status === 200 && (Array.isArray(res.data) ? res.data.length > 0 : true) && hasAllRequiredAddressFields(res.data)
}

const requiredFields = [
    "city",
    "complement",
    "neighborhood",
    "number",
    "state",
    "street",
    "zip_code",
]

const hasAllRequiredAddressFields = (data) => {
    let response = true
    requiredFields.forEach((field) => {
        const value = data.address[field]
        if (value === null || value === undefined || value === "")
            response = false
    });
    return response
}

const updateEnterprise = async (state, enterprises, setParent) => {
    setParent({ loading: true })
    if (! await isRegistered(state.cnpj, setParent)) {
        useNotification(
            "CNPJ Inválido!",
            "O CNPJ não está cadastrado, ou está incompleto, no Conta Azul.",
            "warning"
        )
        await api.post("/api/enterprises/financial_notify/", state)
        useNotification(
            "Atenção!",
            "Um e-mail foi enviado ao departamento financeiro para ajuste, tente novamente em outro momento.",
            "info"
        )
        setParent({ loading: false })
        return
    }
    
    const newManagers = Object.entries(state.managersChecked).filter(([_, value]) => value.checked).map(([key]) => key)
    if (newManagers.length === 0) {
        useNotification(
            "Campo Obrigatório!",
            "A empresa deve ter pelo menos um gerente de contas.",
            "warning"
        )
        setParent({ loading: false })
        return
    }

    const res = await api.patch("/api/enterprises/superadmin/", {
        enterprise_id: state.id,
        name: state.name,
        cnpj: state.cnpj,
        plan_id: state.plan_id,
        due_date: (state.due_date) ? state.due_date : null,
        start_charging: (state.start_charging) ? dayjs(state.start_charging).format("YYYY-MM-DD") : null,
        end_charging: (state.end_charging) ? dayjs(state.end_charging).format("YYYY-MM-DD") : null,
        managers: newManagers,
        products: state.products
    })

    if (res.status === 200) {
        setParent({
            loading: false,
            enterprise: null,
            enterprises: enterprises.map(enterprise => enterprise.id === state.id ? res.data : enterprise)
        })
        useNotification(
            "Sucesso!",
            "Empresa atualizada com sucesso.",
            "success"
        )
        return
    }

    setParent({ loading: false })
    useNotification(
        "Ops!",
        "Não foi possível atualizar a empresa, tente novamente.",
        "danger"
    )
}

const createInvoice = async (invoice, setParent) => {
    if (! invoice.dueDate) {
        useNotification(
            "Atenção!",
            "A data de vencimento da cobrança é obrigatória.",
            "warning"
        )
        return
    }

    const today = dayjs().startOf("day")
    if (! invoice.dueDate.isAfter(today)) {
        useNotification(
            "Atenção!",
            "A data de vencimento de vencimento não pode ser menor, ou igual, a de hoje.",
            "warning"
        )
        return
    }

    if (! invoice.monthlyValue && ! invoice.exceedingValue) {
        useNotification(
            "Atenção!",
            "Pelo menos a mensalidade, ou o excedente, deve ser preenchido.",
            "warning"
        )
        return
    }

    setParent({ loading: true })
    const res = await api.post("/api/payment_history/", {
        enterprise        : invoice.enterprise.id,
        type              : "INVOICE",
        due_at            : dayjs(invoice.dueDate).format("YYYY-MM-DD"),
        monthly_value     : invoice.monthlyValue,
        monthly_exceeding : invoice.exceedingValue
    })

    if (res.status === 201) {
        useNotification(
            "Sucesso!",
            "Cobrança gerada com sucesso.",
            "success"
        )
        setParent({ invoiceData: null, loading: false })
        return
    }

    setParent({ loading: false })
    useNotification(
        "Ops!",
        "Algo deu errado, tente novamente.",
        "danger"
    )
}

const createEnterprise = async (state, enterprises, setParent) => {
    setParent({ loading: true })
    if (! await isRegistered(state.cnpj, setParent)) {
        useNotification(
            "CNPJ Inválido!",
            "O CNPJ não está cadastrado, ou está incompleto, no Conta Azul.",
            "warning"
        )
        await api.post("/api/enterprises/financial_notify/", state)
        useNotification(
            "Atenção!",
            "Um e-mail foi enviado ao departamento financeiro para ajuste, tente novamente em outro momento.",
            "info"
        )
        setParent({ loading: false })
        return
    }
    
    const managers = Object.entries(state.managersChecked).filter(([_, value]) => value.checked).map(([key]) => key)
    if (managers.length === 0) {
        useNotification(
            "Campo Obrigatório!",
            "A empresa deve ter pelo menos um gerente de contas.",
            "warning"
        )
        setParent({ loading: false })
        return
    }

    const res = await api.post("/api/enterprises/superadmin/", {
        name: state.name,
        cnpj: state.cnpj,
        plan_id: state.plan_id,
        due_date: (state.due_date) ? state.due_date : null,
        start_charging: (state.start_charging) ? dayjs(state.start_charging).format("YYYY-MM-DD") : null,
        end_charging: (state.end_charging) ? dayjs(state.end_charging).format("YYYY-MM-DD") : null,
        managers: managers,
        products: state.products
    })

    if (res.status === 201) {
        setParent({
            loading: false,
            enterprise: null,
            enterprises: [...enterprises, ...[res.data]]
        })
        useNotification(
            "Sucesso!",
            "Empresa adicionada com sucesso.",
            "success"
        )
        return
    }

    setParent({ loading: false })
    useNotification(
        "Ops!",
        "Não foi possível adicionar a empresa, tente novamente.",
        "danger"
    )
}

const renderProductRow = (products, item, handleProductChange, removeProduct) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Produto</InputLabel>
                <Select value={item.new_product_id || item.product.id} label="Produto" onChange={(event) => handleProductChange(item, { new_product_id: event.target.value })}>
                    {products.map((product) => (
                        <MenuItem value={product.id}>{product.channel}: {product.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                <TextField
                    label="Quantidade"
                    value={item.quantity}
                    fullWidth
                    size="small"
                    type="number"
                    inputProps={{ min: 0 }}
                    onChange={(event) => handleProductChange(item, { quantity: parseInt(event.target.value) || 0 })}
                />
                <TextField
                    label="Mensalidade"
                    value={item.monthly_value}
                    fullWidth
                    size="small"
                    type="number"
                    inputProps={{ min: 0 }}
                    onChange={(event) => handleProductChange(item, { monthly_value: parseFloat(event.target.value) || 0 })}
                />
            </Box>
            <IconButton color="error" edge="start" onClick={() => removeProduct(item)}>
                <RemoveCircleIcon />
            </IconButton>
        </Box>
    )
}

const requiredParameters = ["name", "cnpj", "plan_id", "due_date", "start_charging"]
const checkMissingParameters = (state) => requiredParameters.some((item) => !state[item])

const EditEnterpriseComponent = ({ plans, enterprise, managers, enterprises, setParent, products }) => {

    const manager_ids = enterprise.managers.map(manager => manager.id)

    const [state, setState] = useReducer(lockedReducer, {
        ...enterprise,
        loading: false,
        plan_id: enterprise.plan?.id || null,
        managersChecked: Object.fromEntries(managers.map(manager => ([manager.id, {...manager, checked: manager_ids.includes(manager.id)}]))),
    })

    const addProductRow = () => {
        const updatedProducts = [...state.products, {
            new_product_id    : 1,
            monthly_value     : 0,
            quantity          : 0,
            percentual_values : 0
        }]
        setState({products: updatedProducts})
    }

    const removeProduct = (product) => {
        const updatedProducts = state.products.filter((item) => item.id !== product.id)
        setState({ products: updatedProducts })
    }

    const handleProductChange = (product, changes) => {
        const updatedProducts = state.products.map((item) =>
            item === product ? { ...item, ...changes } : item
        );
        setState({ products: updatedProducts })
    }

    return (
        <Dialog fullWidth maxWidth="sm" open={enterprise !== null} >
            <DialogTitle className={'group-flexbox'}>
                <span>{(enterprise.id) ? "Editar empresa" : "Adicionar empresa"}</span> 
                <Button 
                    sx={{ 'minWidth': '2rem', 'padding': '0' }} 
                    onClick={() => setParent({ enterprise: null })}
                >
                    <CloseIcon/>
                </Button>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ paddingTop: "2rem" }}>
                {enterprise && (
                    <Box component="form" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <TextField
                            type="text"
                            label="Nome"
                            size='small'
                            value={state.name}
                            onChange={(event) => setState({name: event.target.value})}
                            fullWidth
                            required
                        />
                        <TextField
                            type="text"
                            label="CNPJ"
                            size='small'
                            value={state.cnpj}
                            onChange={(event) => setState({cnpj: event.target.value})}
                            fullWidth
                            InputProps={{
                                inputComponent: CnpfMask
                            }}
                            required
                        />
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            <FormControl fullWidth variant="outlined" size="small">
                                <InputLabel>Plano *</InputLabel>
                                <Select
                                    value={state.plan_id || state.plan?.id}
                                    onChange={(event) => setState({plan_id: event.target.value})}
                                    label="Plano *"
                                >
                                    {plans.map((plan) => (
                                        <MenuItem value={plan.id}>{plan.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth variant="outlined" size="small">
                                <InputLabel>Vencimento *</InputLabel>
                                <Select
                                    value={state.due_date}
                                    onChange={(event) => setState({due_date: event.target.value})}
                                    label="Vencimento *"
                                >
                                    <MenuItem value="05">05</MenuItem>
                                    <MenuItem value="10">10</MenuItem>
                                    <MenuItem value="15">15</MenuItem>
                                    <MenuItem value="20">20</MenuItem>
                                    <MenuItem value="25">25</MenuItem>
                                    <MenuItem value="30">30</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker", "DatePicker"]} sx={{ width: "100%", marginTop: "-8px" }}>
                                    <DatePicker
                                        label="Início da cobrança *"
                                        value={(state.start_charging) ? dayjs(state.start_charging) : null}
                                        onChange={(newValue) => setState({start_charging: newValue})}
                                        slotProps={{ textField: {size: "small"} }}
                                        sx={{ width: "100%" }}
                                    />
                                    <DatePicker
                                        label="Fim da cobrança"
                                        value={(state.end_charging) ? dayjs(state.end_charging) : null}
                                        onChange={(newValue) => setState({end_charging: newValue})}
                                        slotProps={{ textField: {size: "small"} }}
                                        sx={{ width: "100%" }}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                        <Typography text='Gerentes de Conta' variant="h6" color="blue"/>
                        <MultipleSelections
                            title="Gerentes de conta"
                            selectedData={Object.values(state.managersChecked).filter(each => each.checked ).map((value) => String(value.id))}
                            dataIsObject={true}
                            objectDataHasTitle="name"
                            objectDataHasChecked="checked"
                            dataProp={state.managersChecked}
                            getSelect={(selection) => setState({managersChecked: selection})}
                            key={`multiple-selections}`}
                        />
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Typography text="Produtos Avulsos" variant="h6" color="blue" />
                            <IconButton color="primary" edge="start" onClick={() => addProductRow()}>
                                <AddCircleIcon />
                            </IconButton>
                        </Box>
                        {state.products.length === 0 && <Box style={{ marginLeft: ".5rem" }}>Nenhum produto avulso.</Box>}
                        {state.products.map((item) => renderProductRow(products, item, handleProductChange, removeProduct))}
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setParent({enterprise: null})}>Cancelar</Button>
                <Button 
                    disabled={checkMissingParameters(state)}
                    onClick={() => (enterprise.id) ? updateEnterprise(state, enterprises, setParent) : createEnterprise(state, enterprises, setParent)}
                >
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const CreateInvoiceComponent = ({ invoice, enterprises, setParent }) => {

    const [state, setState] = useReducer(lockedReducer, { ...invoice })

    return (
        <Dialog fullWidth maxWidth="sm" open={invoice !== null} >
            <DialogTitle className={'group-flexbox'}>
                <span>Gerar cobrança</span> 
                <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={() => setParent({ invoiceData: null })}>
                    <CloseIcon/>
                </Button>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ paddingTop: "2rem" }}>
                {invoice && (
                    <Box component="form" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <TextField
                            type="text"
                            label="Empresa"
                            size='small'
                            value={state.enterprise.name}
                            fullWidth
                            disabled
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]} sx={{ width: "100%", marginTop: "-8px" }}>
                                <DatePicker
                                    label="Data de Vencimento *"
                                    value={(state.dueDate) ? dayjs(state.dueDate) : null}
                                    onChange={(newValue) => setState({ dueDate: newValue })}
                                    slotProps={{ textField: {size: "small"} }}
                                    sx={{ width: "100%" }}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            <TextField
                                label="Mensalidade"
                                value={state.monthlyValue}
                                fullWidth
                                size="small"
                                type="number"
                                inputProps={{ min: 0 }}
                                onChange={(event) => setState({ monthlyValue: event.target.value })}
                            />
                            <TextField
                                label="Excedente"
                                value={state.exceedingValue}
                                fullWidth
                                size="small"
                                type="number"
                                inputProps={{ min: 0 }}
                                onChange={(event) => setState({ exceedingValue: event.target.value })}
                            />
                        </Box>
                        <Alert severity="info">Não acrescentar 5% nos valores, calculado automaticamente.</Alert>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setParent({ invoiceData: null })}>Cancelar</Button>
                <Button onClick={() => createInvoice(state, setParent)}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const initializeView = async (enterprise, setState) => {
    setState({ loading: true })
    const res = await api.get(`api/payments/contaazul/cnpj/${enterprise.cnpj}/`)
    const newState = { loading: false }
    if (res.status === 200) {
        newState.data = res.data
    } else {
        useNotification(
            "Ops!",
            "Não foi possível carregar os dados da empresa, tente novamente.",
            "danger"
        )
    }
    setState(newState)
}

const TextShowComponent = ({ name, value, ...props }) => {
    return <TextField
        fullWidth
        size={'small'}
        label={name}
        disabled
        value={value || ''}
        {...props}
    />
}

const text_validation = (text, data) => {
    if (!data) return
    const response = {
        helperText: '',
        error: false
    }
    switch (text) {
        case 'name':
            if (data.length === 0) {
                response.helperText = 'Nome não pode estar vazio'
                response.error = true
            }
            break
        case 'email':
            if (data.includes(',')) {
                response.helperText += 'Não adicione mais de um e-mail, ele não será incorporado na nota fiscal.\n'
                response.error = true
            }
            if (!data.includes('@')) {
                response.helperText += 'Adicione um e-mail válido.\n'
                response.error = true
            }
            if (!data.includes('.')) {
                response.helperText += 'Adicione um e-mail válido.\n'
                response.error = true
            }
        case 'endereco':
            if (!data || data.length === 0) {
                response.helperText += 'Campo não pode estar vazio.\n'
                response.error = true
            }
            break

        default:
            return response
    }
    return response
}

const ViewEnterpriseComponent = ({ enterprise, handleClose, setLoading }) => {

    const [state, setState] = useReducer(lockedReducer, {
        loading: false,
        data: {}
    })

    useEffect(() => {
        initializeView(enterprise, setState)
        window.dayjs = dayjs
    }, [])

    return (
        <Dialog fullWidth maxWidth="sm" open={enterprise !== null} >
            <DialogTitle className={'group-flexbox'}>
                <span>{enterprise.name}</span> <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={handleClose} ><CloseIcon /></Button>
            </DialogTitle>
            <Divider />
            <Loading loading={state.loading} />
            {!state.loading && <DialogContent>
                {state.data.length !== 0 ?
                    <Box className='enterprise-view-modal'>
                        <TextShowComponent name='Nome' value={state.data.name} {...text_validation('nome', state.data.name)} />
                        <TextShowComponent name='Nome da empresa' value={state.data.company_name} {...text_validation('nome', state.data.company_name)} />
                        <TextShowComponent name='Email' value={state.data.email} {...text_validation('email', state.data.email)} />
                        <Box className='enterprise-view-flex'>
                            <TextShowComponent name='Telefone empresarial' value={state.data.business_phone} sx={{ flex: .5 }} InputProps={{
                                inputComponent: PhoneMask
                            }} />
                            <TextShowComponent name='Telefone celular' value={state.data.mobile_phone} sx={{ flex: .5 }} InputProps={{
                                inputComponent: PhoneMask
                            }} />
                        </Box>
                        <Box className='enterprise-view-flex'>
                            <TextShowComponent name='CNPJ' value={state.data.document} sx={{ flex: .5 }} InputProps={{
                                inputComponent: CnpfMask
                            }} />
                            <TextShowComponent name='CPF' value={state.data.identity_document} sx={{ flex: .5 }} InputProps={{
                                inputComponent: CnpfMask
                            }} />
                        </Box>
                        <Box className='enterprise-view-flex'>
                            <TextShowComponent name='Inscrição estadual' value={state.data.state_registration_number} sx={{ flex: .5 }} />
                            <TextShowComponent name='Inscrição municipal' value={state.data.city_registration_number} sx={{ flex: .5 }} />
                        </Box>
                        <TextShowComponent name='Data de Fundação' value={dayjs(state.data.date_of_birth + ':00').format("DD/MM/YYYY HH:mm")} sx={{ flex: .5 }} />

                        <Box className='enterprise-adress-modal'>
                            <Typography text='Endereço' variant="h6" color="blue"
                            />
                            <Box className='enterprise-view-flex'>
                                <TextShowComponent name='Rua' value={state.data.address?.street} sx={{ flex: .8 }} {...text_validation('endereco', state.data.address?.street)} />
                                <TextShowComponent name='Número' value={state.data.address?.number} sx={{ flex: .2 }} {...text_validation('endereco', state.data.address?.number)} />
                            </Box>
                            <TextShowComponent name='Complemento' value={state.data.address?.complement} />
                            <TextShowComponent name='Bairro' value={state.data.address?.neighborhood} {...text_validation('endereco', state.data.address?.neighborhood)} />
                            <Box className='enterprise-view-flex'>
                                <TextShowComponent name='CEP' value={state.data.address?.zip_code} sx={{ flex: .4 }} {...text_validation('endereco', state.data.address?.zip_code)} />
                                <TextShowComponent name='Cidade' value={state.data.address?.city?.name} sx={{ flex: .4 }} {...text_validation('endereco', state.data.address?.city?.name)} />
                                <TextShowComponent name='Estado' value={state.data.address?.state?.name} sx={{ flex: .2 }} {...text_validation('endereco', state.data.address?.state?.name)} />
                            </Box>
                        </Box>
                    </Box>
                    :
                    <Box>
                        CNPJ não cadastrado na base de dados ou CNPJ inválido.
                    </Box>
                }
            </DialogContent>}
        </Dialog>
    )
}

const initializeComponents = async (setState) => {
    setState({ loading: true })

    const [enterprises, plans, managers, products] = await Promise.all([api.get("/api/enterprises/superadmin/"), api.get('/api/plans/'), api.get('/api/managers/'), api.get('/api/products/')])
    const response = { loading: false }
    if (enterprises.status === 200) response.enterprises = enterprises.data
    if (plans.status === 200) response.plans = plans.data
    if (managers.status === 200) response.managers = managers.data
    if (products.status === 200) response.products = products.data

    setState(response)
}

const theme = createTheme({
    palette: {
        primary: { main: "#21446C" },
    },
}, ptBR, corePtBR)

const EnterprisesComponent = () => {

    const history = useHistory()

    const [state, setState] = useReducer(lockedReducer, {
        enterprises    : [],
        plans          : [],
        managers       : [],
        enterprise     : null,
        enterpriseData : null,
        loading        : false,
        products       : [],
        invoiceData    : null
    })

    useEffect(() => {
        initializeComponents(setState)
    }, [])

    const CustomToolbar = () => (
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
            <Box className='toolbarLeft'>
                <GridToolbarColumnsButton size="small" />
                <GridToolbarFilterButton size="small" className={'filterControl'} />
                <LoadingButton size="small" component="label" startIcon={<AddCircleIcon/>}
                    onClick={() => {
                        setState({
                            enterprise: {
                                name           : "",
                                cnpj           : "",
                                due_date       : "",
                                start_charging : "",
                                end_charging   : "",
                                managers       : [],
                                products       : []
                            },
                            managersChecked: state.managers.reduce((obj, item) => {
                                obj[item.id] = {
                                    name    : item.name,
                                    checked : false,
                                }
                                return obj
                            }, {})
                        })
                    }}
                >
                    Adicionar
                </LoadingButton>
            </Box>
        </GridToolbarContainer>
    )

    return (
        <Box className="enterprises-container">
            <Loading loading={state.loading} />
            {state.invoiceData &&
                <CreateInvoiceComponent
                    invoice={state.invoiceData}
                    enterprises={state.enterprises}
                    setParent={setState}
                    handleClose={() => setState({ invoiceData: null })}
                />
            }
            {state.enterpriseData &&
                <ViewEnterpriseComponent
                    enterprise={state.enterpriseData}
                    handleClose={() => setState({ enterpriseData: null })}
                />
            }
            {state.enterprise &&
                <EditEnterpriseComponent
                    plans={state.plans}
                    enterprise={state.enterprise}
                    managers={state.managers}
                    enterprises={state.enterprises}
                    setParent={setState}
                    products={state.products}
                />
            }
            <ThemeProvider theme={theme}>
                <DataGridPro
                    rows={state.enterprises}
                    columns={enterprisesColumns(state.managers, setState, history)}
                    density={"compact"}
                    pagination
                    paginationMode="client"
                    pageSize={20}
                    rowsPerPageOptions={[20, 50, 100]}
                    disableSelectionOnClick
                    components={{ Toolbar: CustomToolbar }}
                />
            </ThemeProvider>
        </Box>
    )
}

export default EnterprisesComponent
