import React from "react"

import AdminDrawer from "../../components/AdminDrawer"
import PaymentsComponent from "../../components/Payments"

const Payments = () => {
  
    return (
        <AdminDrawer child={<PaymentsComponent/>}/>
    );
};

export default Payments
