import React from "react"

import AdminDrawer from "../../components/AdminDrawer"
import SequencesComponent from "../../components/Sequences"

const Sequences = () => {
  
    return (
        <AdminDrawer child={<SequencesComponent/>}/>
    );
};

export default Sequences
