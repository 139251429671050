import React from "react"

import AdminDrawer from "../../components/AdminDrawer"
import BoletoComponent from "../../components/Boletos";

const Boletos = () => {
  
    return (
        <AdminDrawer child={<BoletoComponent/>}/>
    );
};

export default Boletos
