import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';

import { 
    Button, 
    IconButton, 
    Menu, 
    MenuItem, 
    CircularProgress, 
    Box, 
    Typography, 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Divider
} from '@mui/material';

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import PauseIcon from '@mui/icons-material/Pause'
import PlayIcon from '@mui/icons-material/PlayArrow'
import CopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

import Webphone from '../../Webphone';
import useNotification from "../../Notification";

import { getSession } from "../../../auth";
import api from "../../../api";

import "./index.css";

import io from 'socket.io-client';

const KwikTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: 15,
        padding: 10
    },
}));

const WebphoneMenu = () => {

    const user = getSession();

    const iconButton = React.useRef(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [alreadyConnected, setAlreadyConnected] = React.useState(false);
    const [lastVoiceSocketResponse, setLastVoiceSocketResponse] = React.useState(null);

    const [anchorElActions, setAnchorElActions] = React.useState(null)
    const [openDialog, setOpenDialog] = React.useState(false)

    const [pauseReasons, setPauseReasons] = React.useState([])
    const [agentStatus, setAgentStatus] = React.useState()
    const [pauseReason, setPauseReason] = React.useState()
    const [showCti, setShowCti] = React.useState(false)
    const [cti, setCti] = React.useState([])

    const reAnchor = async () => {
        handleClose();
        await new Promise(r => setTimeout(r, 500));
        handleClick();
    }

    const handleClick = () => {
        setAnchorEl(iconButton.current);
        const display = document.getElementById('dialpad-input');
        if (display) {
            window.setTimeout(() => {
                display.focus();
            }, 300);
        }
        window.setLink = false;
    };

    const handleClose = () => {        
        setAnchorEl();
    };

    const handleLoading = (val) => {        
        setIsLoading(val);
    };

    const handleActionsClick = (event) => {
        setAnchorElActions(event.currentTarget)
    }

    const handleActionsClose = () => {
        setAnchorElActions()
    }

    const handlePauseReasons = () => {
        setOpenDialog(true)
        handleActionsClose()
    }

    const handleDialogClose = () => {
        setOpenDialog(false)
    }

    const handleReasonSubmit = () => {
        if (! isInstantVoiceIntegrated())
            return

        if (! pauseReason) {
            useNotification(
                'Atenção!',
                'Selecione o motivo da pausa.',
                'warning'
            );
            return
        }

        api.post(`api/IV/agents/${user.profile.id}/instantvoice_agent_pause/`, {
            reason: pauseReason
        }).then((res) => {
            if (res.status === 200) {
                setPauseReason()
                setOpenDialog(false)
            }
        }).catch((err) => {})
    }

    let connectVoiceSocket = () => {
        let socket = null; 
        let keepAliveVoice = null;
        
        const options = { upgrade: false, transports: [ 'websocket' ] };        
        
        let url = `https://${location.host}:443`;
        if (isInstantVoiceIntegrated())
            url = `https://${user.profile.instantvoice_url}:443`

        if (! alreadyConnected) {
            socket = io(url, options);
            
            socket.on('connect', () => {
                let agent = user?.profile.agent.user
                if (isInstantVoiceIntegrated())
                    agent = `${user.profile.instantvoice_accountcode}_${user.profile.instantvoice_agent_code}`
                socket.emit('subscribe', { agent: agent });                
                keepAliveVoice = setInterval(() => {
                    // If server not responding for more than 11 seconds
                    // the connection might have been closed somewhere
                    // we need to close and reopen a new websocket connection
                    let curTime = new Date().getTime();
                    if (lastVoiceSocketResponse && curTime - lastVoiceSocketResponse > 11000) {                        
                        socket.disconnect();                        
                        socket.open();
                        window.socketVoice = socket;
                        return;
                    }

                    // Pinging server every 20secs
                    socket.emit('xping', { agent: user.profile.agent.user });
                }, 10000);
            });

            socket.on('call-event', (event) => {
                const message = (JSON.parse(event.body.trim())).pop()
                switch (message.type) {
                    case 'LOGIN':
                        checkAgentStatus()
                        break
                    case 'LOGOUT':
                        checkAgentStatus()
                        break
                    case 'PAUSE':
                        checkAgentStatus()
                        break
                    case 'UNPAUSE':
                        checkAgentStatus()
                        break
                    case 'NEWCALL':
                        const ctiInfo = message.cti_info
                        setCti(ctiInfo.split('#'))
                        break
                    case 'HANGUP':
                        setCti([])
                        setShowCti(false)
                        break
                    default:
                        break
                }
            })

            socket.on('disconnect', () => {
                console.log('O socket foi desconectado.');
                clearInterval(keepAliveVoice);
                
            });

            socket.on('xpong', (event) => {
                setLastVoiceSocketResponse(new Date().getTime());                
            });
            
            setAlreadyConnected(true);
        }

        if (socket) {
            socket.on('disconnect', () => {
                console.log('O socket foi desconectado.');
                window.socketVoice = null;
                setLastVoiceSocketResponse(null);
            });
        }

        window.socketVoice = socket;
    }

    let closeVoiceSocket = () => {
        if (window.socketVoice?.connected) {
            window.socketVoice.disconnect();
        }
        window.socketVoice = null;
    };

    const checkAgentStatus = () => {
        if (! isInstantVoiceIntegrated())
            return

        api.get(`api/IV/agents/${user.profile.id}/instantvoice_agent_info/`)
            .then((res) => {
                if (res.status === 200) {
                    let status = res.data.AgentInfoResult2.agentstatus
                    switch (status) {
                        case 'LOGIN':
                            status = 'Logado'
                            break
                        case 'LOGOFF':
                            status = 'Deslogado'
                            break
                        case 'LOGOFF_PAUSE':
                            status = 'Deslogado'
                            break
                        case 'PAUSE':
                            status = res.data.AgentInfoResult2.reasons_reason
                            break
                        default:
                            break
                    }
                    setAgentStatus(status)
                }
            })
            .catch((err) => {})
    }

    const handleInstantVoiceAgentLogin = (extension) => {
        if (! isInstantVoiceIntegrated())
            return

        api.post(`api/IV/agents/${user.profile.id}/instantvoice_agent_login/`, {
            extension: extension
        }).then((res) => {
            if (res.status === 200) {
                handleActionsClose()
            }
        }).catch((err) => {})
    }

    const handleInstantVoiceAgentLogoff = () => {
        if (! isInstantVoiceIntegrated())
            return

        api.post(`api/IV/agents/${user.profile.id}/instantvoice_agent_logoff/`)
            .then((res) => {
                if (res.status === 200) {
                    handleActionsClose()
                }
            })
            .catch((err) => {})
    }

    const getPauseReasons = () => {
        if (! isInstantVoiceIntegrated())
            return

        api.get(`api/IV/agents/${user.profile.id}/instantvoice_pause_reasons/`)
            .then((res) => {
                if (res.status === 200) {
                    setPauseReasons(res.data)
                    handleActionsClose()
                }
            })
            .catch((err) => {})
    }

    const handleInstantVoiceAgentUnpause = () => {
        if (! isInstantVoiceIntegrated())
            return

        api.post(`api/IV/agents/${user.profile.id}/instantvoice_agent_unpause/`)
            .then((res) => {
                if (res.status === 200) {
                    handleActionsClose()
                }
            })
            .catch((err) => {})
    }

    useEffect(() => {
        checkAgentStatus()
        getPauseReasons()
        if (! isInstantVoiceIntegrated() && (! user?.profile.enterprise.has_voice || ! user?.profile.agent.preferred_interface || user?.profile.agent.external_username))
            return;
        connectVoiceSocket();
        return () => {
            closeVoiceSocket();
        }
    }, []);

    const isInstantVoiceIntegrated = () => {
        return (user?.profile.instantvoice_url
            && user?.profile.instantvoice_accountcode
            && user?.profile.instantvoice_api_key
            && user?.profile.instantvoice_agent_code
        )
    }

    if (user?.profile.enterprise.has_voice) {        
        
        const telephone = user.profile.enterprise.telephone;
        let extension = null;
        let info = null
        
        if (user.profile.agent.external_username) {
            extension = user.profile.agent.external_username;
            info = 'Ramal (externo): ' + extension
        } else if (user.profile.agent.preferred_interface) {
            extension = user.profile.agent.preferred_interface;
            extension = extension.split('_').pop();
            info = 'Ramal (interno): ' + extension
        }

        let tooltip = 'Ramal não configurado';
        if (extension) {
            tooltip = info;
            if (telephone)
                tooltip += ' [' + telephone.replace('55', '0') + ']';
        }

        if (extension) {
            return (
                <div className="webphone-menu-container">
                    <KwikTooltip 
                        title={extension 
                            ? tooltip 
                            : 'Ramal não configurado'} 
                        placement="left"
                        disableInteractive
                        arrow
                    >
                        <IconButton id="webphone-icon" 
                            ref={iconButton} 
                            disabled={extension && isLoading}
                            onClick={handleClick}
                        >
                            {extension && isLoading
                                ? (<CircularProgress style={{ marginTop: 0, fontSize: "100%" }} size={20} color="primary"/>) 
                                : (<PhoneIcon className="toolbar-icon green"/>)
                            }
                        </IconButton>
                    </KwikTooltip>
                    <span style={{ marginRight: (isInstantVoiceIntegrated()) ? "0" : ".75rem" }}>
                        {extension.split("_").pop()}
                    </span>
                    {isInstantVoiceIntegrated() &&
                        <>
                            <IconButton disabled>
                                <PersonIcon className={(agentStatus === "Logado") ? "toolbar-icon green" : (agentStatus === "Deslogado" || ! agentStatus) ? "toolbar-icon red" : "toolbar-icon yellow"}/>
                            </IconButton>
                            <span>{agentStatus}</span>
                            <IconButton onClick={handleActionsClick}>
                                <MoreVertIcon className="toolbar-icon"/>
                            </IconButton>
                        </>
                    }
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        sx={{zIndex:2001}}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        elevation={0}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        className="webphone-container"
                    >
                        <Webphone
                            reAnchor={() => reAnchor()}
                            handleClose={() => handleClose()}
                            handleLoading={() => handleLoading()}
                        />
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            {! showCti && cti.length !== 0 &&
                                <Box className="show-cti-button">
                                    <IconButton onClick={() => setShowCti(true)}>CTI</IconButton>
                                    <Box className="arrow-right"/>
                                </Box>
                            }
                            {showCti && cti.length !== 0 &&
                                <Box className="show-cti-container">
                                    <div className="cti-data">
                                        <div className="header">
                                            CTI
                                            <IconButton className="copy-btn" onClick={() => navigator.clipboard.writeText(cti.join('\n'))}>
                                                <CopyIcon />
                                            </IconButton>
                                            <IconButton className="close-btn" onClick={() => setShowCti(false)}>
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                        {cti.map((each) => (
                                            <div style={{ whiteSpace: "nowrap" }}>{each}</div>
                                        ))}
                                    </div>
                                    <Box className="arrow-right"/>
                                </Box>
                            }
                        </Box>
                    </Menu>
                    <Menu
                        anchorEl={anchorElActions}
                        keepMounted
                        sx={{zIndex:2001}}
                        open={Boolean(anchorElActions)}
                        onClose={handleActionsClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <MenuItem onClick={() => handleInstantVoiceAgentLogin(extension)}>
                            <LoginIcon style={{ marginRight: 8 }}/>
                            Login
                        </MenuItem>
                        <MenuItem onClick={handleInstantVoiceAgentLogoff}>
                            <LogoutIcon style={{ marginRight: 8 }}/>
                            Logout
                        </MenuItem>
                        <MenuItem onClick={handlePauseReasons}>
                            <PauseIcon style={{ marginRight: 8 }}/>
                            Pausa
                        </MenuItem>
                        <MenuItem onClick={handleInstantVoiceAgentUnpause}>
                            <PlayIcon style={{ marginRight: 8 }}/>
                            Despausa
                        </MenuItem>
                    </Menu>
                    <Dialog open={openDialog} onClose={handleDialogClose}>
                        <DialogTitle>Pausa</DialogTitle>
                        <Divider/>
                        <DialogContent>
                            <FormControl sx={{ mt: 1, minWidth: 250 }} size="small">
                                <InputLabel id="pause-reasons-list">Motivo da pausa</InputLabel>
                                <Select
                                    labelId="pause-reasons-list"
                                    value={pauseReason}
                                    label="Motivo da pausa"
                                    onChange={(event) => setPauseReason(event.target.value)}
                                >
                                    {pauseReasons.map((each) => (
                                        <MenuItem value={each.number}>{each.location}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions sx={{ mr: 1 }}>
                            <Button onClick={handleDialogClose}>Cancelar</Button>
                            <Button onClick={handleReasonSubmit}>Pausar</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
    }

    return (<></>)
}

export default WebphoneMenu;
