import React, { useState, useEffect } from "react";

import {
    Box, 
    IconButton, 
    Tooltip, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails, 
    Divider,
    Table, 
    TableBody, 
    TableRow, 
    TableCell, 
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SaveIcon from '@mui/icons-material/Save'

import Profile from './Profile';
import LabelArea from './LabelArea';
import GroupArea from './GroupArea';

import History from "./History";
import NewHistory from "./History/newhistory";

import { getSession } from "../../../auth";
import api from "../../../api";
import useNotification from '../../Notification';
import ScoutContactHistory from "../../Scout/Pages/ScoutContactHistory";

import "./styles.css";

export default function ChatRightSideBar({
    closeChatRightSideBar,
    isManageCalls,
    contactData,
    updateField,
    fetchHistoryMessages,
    agentCanUpdateContact,
    allLabels,
    chatLabels,
    setChatLabels,
    chat,
    renderLabels,
    reloadContact,
    chatCursor,
    allGroups,
    setChatGroups,
    fetchScoutMessages,
}) {

    const enterprise = getSession().profile.enterprise;

    const [customFields, setCustomFields] = useState([])
    
    useEffect(async () => {
        const customFields = await api.get(`api/enterprises/${enterprise.id}/custom_fields/`)
        if (customFields.status === 200) {
            const contactCustomFields = []
            customFields.data.forEach((customField) => {
                contactCustomFields.push({ 
                    id           : contactData.custom_fields.find(field => field.custom_field.id == customField.id)?.id || null,
                    contact      : contactData.id,
                    custom_field : customField,
                    value        : contactData.custom_fields.find(field => field.custom_field.id == customField.id)?.value || ''
                })
            })
            setCustomFields(contactCustomFields)
        }
    }, [])

    const updateCustomField = (customField, value) => {
        const fields = [...customFields]
        fields.find(field => field.custom_field.id === customField.custom_field.id).value = value
        setCustomFields(fields)
    }

    const saveCustomField = (customField) => {
        const key = customField.id ? 'update_custom_fields' : 'create_custom_fields'
        api.patch(`api/enterprises/${enterprise.id}/contacts/${contactData.id}/`, { 
            [key]: [customField] 
        }).then((res) => {
            if ([200, 201].includes(res.status)) {
                if (key === 'create_custom_fields') {
                    const newFieldName = customField.custom_field.field_name
                    const newFieldId = res.data.custom_fields.find(field => field.custom_field.field_name === newFieldName).id
                    const fields = [...customFields]
                    fields.find(field => field.custom_field.field_name === newFieldName).id = newFieldId
                    setCustomFields(fields)
                }
                useNotification(
                    'Sucesso!',
                    'Campo salvo com sucesso',
                    'success'
                )
                return
            }
            throw res
        }).catch((err) => {
            let message = 'Algo deu errado, tente novamente.'
            if (err.status === 400) {
                const errorMessages = {
                    'Invalid data type. Expected an integer.'    : 'deve conter um número.',
                    'Invalid data type. Expected either 0 or 1.' : 'deve conter Sim ou Não.'
                };
                Object.keys(err.data).forEach((key) => {
                    err.data[key].forEach((error) => {
                        if (errorMessages[error])
                            message = `O campo ${key} ${errorMessages[error]}`;
                    });
                })
                useNotification('Atenção!', message, 'warning');
            }
        })
    }

    return (
        <Box className="chat-right-sidebar" style={{ height: isManageCalls ? '100%' : '100%' }}>
            <Box className="header">
                <Tooltip title="Fechar" placement="bottom" disableInteractive>
                    <IconButton
                        onClick={() => closeChatRightSideBar()}
                        className="contactHistory-iconButton"
                        size="large">
                        <ChevronRightIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Divider />
            <Accordion defaultExpanded={true} className='accordion'>
                <AccordionSummary className='accordionHeader' expandIcon={<ExpandMoreIcon />}>
                    Dados do contato
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                    <Profile
                        contactData={contactData}
                        agentCanUpdateContact={agentCanUpdateContact}
                        isManageCalls={isManageCalls}
                        updateField={updateField}
                        fetchHistoryMessages={fetchHistoryMessages}
                        chat={chat}
                        reloadContact={reloadContact}
                    />
                </AccordionDetails>
            </Accordion>
            {customFields.length !== 0 &&
                <>
                    <Divider />
                    <Accordion defaultExpanded={false} className='accordion'>
                        <AccordionSummary className='accordionHeader' expandIcon={<ExpandMoreIcon />}>
                            Campos customizados
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                            <Table className="contact-custom-field-table">
                                <TableBody>
                                    {customFields.map((field) => (
                                        <TableRow>
                                            <TableCell>{field.custom_field.field_name}</TableCell>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    {field.custom_field.field_type === 'string' &&
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            sx={{ mr: 1, flexGrow: 1 }}
                                                            value={field.value}
                                                            onChange={(event) => updateCustomField(field, event.target.value)}
                                                        />
                                                    }
                                                    {field.custom_field.field_type === 'integer' &&
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            sx={{ mr: 1, flexGrow: 1 }}
                                                            value={field.value}
                                                            type="number"
                                                            onChange={(event) => updateCustomField(field, event.target.value)}
                                                        />
                                                    }
                                                    {field.custom_field.field_type === 'boolean' &&
                                                        <FormControl fullWidth size="small">
                                                            <Select
                                                                sx={{ mr: 1, flexGrow: 1 }}
                                                                labelId="custom-field-type-select"
                                                                value={field.value}
                                                                onChange={(event) => updateCustomField(field, event.target.value)}
                                                            >
                                                                <MenuItem value=""></MenuItem>
                                                                <MenuItem value="1">Sim</MenuItem>
                                                                <MenuItem value="0">Não</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    }
                                                    <IconButton color="primary" aria-label="save" onClick={() => saveCustomField(field)}>
                                                        <SaveIcon />
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </>
            }
            <Divider />
            <Accordion defaultExpanded={false} className='accordion'>
                <AccordionSummary className='accordionHeader' expandIcon={<ExpandMoreIcon />}>
                    Histórico
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                    {enterprise.infinite_scroll ? <NewHistory
                        contactData={contactData}
                        fetchHistoryMessages={fetchHistoryMessages}
                    />
                        :
                        <History
                            contactData={contactData}
                            fetchHistoryMessages={fetchHistoryMessages}
                        />
                    }
                </AccordionDetails>
            </Accordion>
            {enterprise.platforms.scout && <>
            <Accordion defaultExpanded={false} className='accordion'>
                <AccordionSummary className='accordionHeader' expandIcon={<ExpandMoreIcon />}>
                   Contatos Scout 
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                    <ScoutContactHistory
                        contactData={contactData}
                        fetchHistoryMessages={fetchScoutMessages}
                    />
                </AccordionDetails>
            </Accordion>
                
            </>
            }

            {enterprise.show_chat_groups && 
                <>
                    <Divider />
                    <Accordion defaultExpanded={false} className='accordion'>
                        <AccordionSummary className='accordionHeader' expandIcon={<ExpandMoreIcon />}>
                            Segmentos
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                            <AccordionDetails>
                                <Box alignItems={'center'}>
                                    <GroupArea
                                        chat={chat}
                                        chatGroups={chat.contact?.groups ?? []}
                                        allGroups={allGroups}
                                        setChatGroups={setChatGroups}
                                    />
                                </Box>
                            </AccordionDetails>
                        </AccordionDetails>
                    </Accordion>
                </>
            }
            {renderLabels &&
                <>
                    <Divider />
                    <Accordion defaultExpanded={false} className='accordion'>
                        <AccordionSummary className='accordionHeader' expandIcon={<ExpandMoreIcon />}>
                            Etiquetas
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails >
                            <Box alignItems={'center'}>
                                <LabelArea
                                    chat={chat}
                                    chatLabels={chatLabels}
                                    allLabels={allLabels}
                                    setChatLabels={setChatLabels}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </>
            }
        </Box>
    );
}
