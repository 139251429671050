import React, { useState, useEffect } from "react"

import { 
    Box, 
    Typography, 
    TextField, 
    Button,
    Alert
} from "@mui/material"

import LoadingButton from '@mui/lab/LoadingButton'

import PasswordInput from "../../components/Input"

import { getSuperAdminToken, saveSuperAdminToken } from "../../auth"

import "./styles.css"

const AdminLogin = () => {

    const [state, setState] = useState({
        username     : "",
        password     : "",
        errorMessage : "",
        loading      : false
    })

    const handleLogin = () => {
        setState((prevState) => ({ ...prevState, errorMessage: "", loading: true }))
        fetch("/api/auth/token/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                username : state.username.toLowerCase(),
                password : state.password
            })
        }).then((res) => {
            setState((prevState) => ({ ...prevState, loading: false }))
            if (res.status === 200)
                return res.json()
            throw res
        }).then((data) => {
            if (data.user.profile.super_admin) {
                saveSuperAdminToken(data.access)
                window.location.href = "/products"
                return
            }
            throw { status: 403 }
        }).catch((err) => {
            if (err.status === 401)
                setState((prevState) => ({ ...prevState, errorMessage: "Usuário ou senha inválidos." }))
            if (err.status === 403)
                setState((prevState) => ({ ...prevState, errorMessage: "Você não tem permissão para acessar esta página." }))
        })
    }

    return (
        <Box className="admin-login-wrapper">
            <Box className="admin-login-background-line"></Box>
            <Box className="admin-login-container">
                <Typography variant="h5" className="admin-login-title">
                    SuperAdmin Kwik
                </Typography>
                <TextField
                    size="small"
                    label="Usuário"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={state.username}
                    onChange={(e) => setState((prevState) => ({ ...prevState, username: e.target.value }))}
                    onEnterPress={true}
                    onKeyPress={(event) => {
                        if (event && event.key === "Enter")
                            handleLogin()
                    }}
                    className="admin-login-input"
                />
                <PasswordInput 
                    label="Senha"
                    name="password"
                    defaultValue={state.password}
                    onChange={(e) => setState((prevState) => ({ ...prevState, password: e.target.value }))}
                    onEnterPress={true}
                    onKeyPress={(event) => {
                        if (event && event.key === "Enter")
                            handleLogin()
                    }}
                    className="admin-login-input"
                />
                {state.errorMessage && <Alert className="admin-login-alert" severity="error">{state.errorMessage}</Alert>}
                <LoadingButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleLogin}
                    className="admin-login-button"
                    loading={state.loading}
                >
                    Login
                </LoadingButton>
            </Box>
        </Box>
    )
}

export default AdminLogin
