import React, { useEffect, useReducer, useRef, useLayoutEffect } from 'react';

import { Box, Divider } from '@mui/material';
import { getSession } from '../../../../auth';
import defaultReducer from '../../../../utils/defaultReducer';
import api from '../../../../api';
import { ShowComponent } from '../../../../utils/isVisible';
import dayjs from 'dayjs'

const fetchDelay = 1000;

const getData = async (state, setState, contactData, enterprise) => {

    const res = await api.post(
      `api/enterprises/${enterprise.id}/scout_accounts/customer_contacts/`,
      {
        phone_number: contactData.telephone,
      },
    );
    if (res.status === 200) {
        return [res.data, 0];
    }
    return [[], 0]
}

const initialState = {
    rows: [],
    cursor: 0,
    last_cursor: false,
    fetching: false,
    loaded: false
};

const initializeHistory = async (state, setState, contactData, enterprise) => {

    const [chats, cursor] = await getData(state, setState, contactData, enterprise)
    setState({
        rows: chats.toSorted((x, y) => x.id - y.id),
        cursor: cursor,
        loaded: true
    })
}

const calcdate = (row) => {
    return dayjs.unix(row.last_message_timestamp).format('DD/MM/YYYY HH:mm')
}

const ScoutContactHistory = ({ contactData, fetchHistoryMessages }) => {
    const areaRef = useRef();

    const [state, setState] = useReducer(defaultReducer, {
        contact: contactData,
        chatCursor: 0,
        ...initialState
    });

    const enterprise = getSession().profile.enterprise;

    useLayoutEffect(() => {
        initializeHistory(state, setState, contactData, enterprise)
        window.receiveChatCursor = (e) => setState({ chatCursor: e })
        return () => { setState(initialState) }
    }, []);

    useEffect(() => {
        if (areaRef.current)
            areaRef.current.scrollTop = areaRef.current.scrollHeight
    }, [state.loaded]);

    return (
        <>
            <Box className="contactHistory-box-9">
                <Box
                    className="contactHistory-box-5"
                >
                    Conversas Scout 
                    <Divider className="contactHistory-divider" />
                    <Box ref={areaRef} className="contactHistory-box-7">
                        <Box sx={{ minHeight: '1rem', display: 'flex', justifyContent: 'center' }}>
                            {(!state.last_cursor && !state.fetching && state.loaded) &&
                                <ShowComponent
                                    parent={areaRef}
                                    loading={(!state.last_cursor && !state.fetching && state.loaded)}
                                    isVisibleHook={(e) => { }}>
                                </ShowComponent>}
                            {(state.fetching && !state.last_cursor && state.loaded) &&
                                <AutorenewIcon sx={{ width: '100%' }} className={'fileIcon spinner'} />}
                        </Box>
                        {state.rows.map((row, i) =>
                            <Box sx={{ display: 'flex' }} className={`history-row-cursor ${row._id === state.chatCursor ? 'selected': ''}`}>
                                <div>
                                    <Box
                                        key={i}
                                        className="itemHistory"
                                        onClick={() => fetchHistoryMessages(row)}
                                        style={{ display: "inline-flex", alignItems: "center" }}
                                    >
                                        {row.account.name} {calcdate(row)}
                                    </Box>
                                </div>
                            </Box>
                        )}
                        {(!state.rows || state.rows.length === 0) &&
                            <Box className="noHistory">Não possui histórico.</Box>
                        }
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ScoutContactHistory;