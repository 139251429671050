import React, { useState, useEffect, createContext, useMemo, useContext } from 'react'

import {
    Box,
} from '@mui/material';

import api from '../../api';
import Loading from '../Loading';
import useNotification from "../Notification";

import { getSession } from "../../auth";

import TableIcon from '@mui/icons-material/TableChart';
import CampaignIcon from '@mui/icons-material/Campaign';
import MessageIcon from '@mui/icons-material/Message';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ResponsiveButton from './ResponsiveButton';
import ClientsIcon from '@mui/icons-material/Groups';


import TabButtons from './TabButtons';
import TabTables from './TabTables';

import UserPermissions from '../User/Permissions';

import moment from 'moment';

import useMediaQuery from '@mui/material/useMediaQuery';

import './styles.css'
import { QueueSocket } from '../../routes';

import isMobile from '../../utils/isMobile';

var timeout = null

var nextGroupsUrl = null
var globalGroups  = []

export const ScreenContext = createContext()

const Crm = () => {

    const { cache, setCache } = useContext(QueueSocket)

    const isSmallScreen = useMediaQuery('(max-width: 60rem)');
    const [queues, updateQueues] = useState([])
    const [accountcode, updateAccountcode] = useState(0)
    const [enterpriseId, updateEnterpriseId] = useState(0)
    const [templates, updateTemplates] = useState([])
    const [ready, updateReady] = useState(false)
    const [clients, updateClients] = useState([])
    const [groups, updateGroups] = useState([])
    const [customFields, updateCustomFields] = useState([])
    const [settings, updateSettings] = useState([])
    const [campaigns, updateCampaigns] = useState([])
    const [originNumbers, updateOriginNumbers] = useState([])
    const [permissions, updatePermissions] = useState([])
    const [notifications, updateNotifications] = useState([])
    const [selectedTab, updateSelectedTab] = useState('clients')
    const [startDateTime, updateStartDateTime] = useState('')
    const [endDateTime, updateEndDateTime] = useState('')
    const [smallReload, updateSmallReload] = useState(false)
    const [selectedModel, updateSelectedModel] = useState([])
    const [selectedNumberModel, updateNumberSelectedModel] = useState([])
    const [loadingContent, setLoadingContent] = useState()
    const [ServerData, updateServerData] = useState({ clients: [], campaigns: [], notifications: [] })
    const [limitOffset, updateLimitOffset] = useState({ clients: [], campaigns: [], notifications: [] })
    const [filterModel, updateFilterModel] = useState({ clients: { items: [] }, campaigns: { items: [] }, notifications: { items: [] } })
    const [sortModel, updateSortModel] = useState({ clients: [], campaigns: [], notifications: [] })

    const handleRefresh = async () => {
        let promise = []
        updateSmallReload(true)
        switch (selectedTab) {
            case 'clients':
                promise = getClients()
                break
            case 'notifications':
                promise = fetchNotifications(startDateTime, endDateTime)
                break
            case 'campaigns':
                promise = fetchCampaigns()
                break
            default:
                ;

        }
        await promise
        updateSmallReload(false)
    }


    const getOriginNumbers = async () => {
        if (accountcode == 0) return

        let numbers = []

        await api.get(`/api/IS/account/${accountcode}/senders/`, { timeout: 0 })
            .then((res) => {
                if (res.status == 200) {
                    numbers = res.data
                    return true;
                }
                throw res;
            })
            .catch((err) => {
                console.error(err);
            });

        // Meta numbers
        const response = await api.get(`/api/enterprises/${enterpriseId}/whatsapp/`)
        if (response.status === 200) {
            const metaNumbers = []
            response.data.forEach((each) => {
                metaNumbers.push(each.phone_number)
            })
            numbers = [...numbers, ...metaNumbers]
        }

        updateOriginNumbers(numbers)

        return true
    }

    const getClients = async (command = 'get', limit = 25, offset = 0, filter = { items: [] }, sort = []) => {

        //settings
        if (enterpriseId == 0) return
        const user = getSession();
        const enterpriseSettings = user.profile.enterprise.settings;

        //limit + offset
        let newlimit = limit
        let newoffset = offset
        if (command == 'get' && limitOffset.clients.length > 0) {
            newlimit = limitOffset.clients[0]
            newoffset = limitOffset.clients[1]
        }

        //filter
        if (command == 'filter') {
            updateFilterModel(prevState => ({ ...prevState, clients: filter }))
        }
        else {
            filter = filterModel.clients
        }

        let filterString = filter.items.map(each => {
            if (each.value)
                return (`&${each.columnField}__${each.operatorValue}=${each.value}`)
        }).join('')

        if ('linkOperator' in filter && filter.linkOperator == 'or')
            filterString += '&or=True'

        //sort
        if (command == 'sort') {
            updateSortModel(prevState => ({ ...prevState, clients: sort }))
        }
        else {
            sort = sortModel.clients
        }

        const sortString = sort.map(each => (`&sort=${each.field}__${each.sort}`)).join('')

        let url = `/api/enterprises/${enterpriseId}/contacts/?limit=${newlimit}&offset=${newoffset}${filterString}${sortString}`;

        await api.get(url, { timeout: 5000 })
            .then(async (res) => {
                if (res.status === 200) {
                    updateServerData(prevState => ({ ...prevState, clients: res.data }))
                    let clientdata = res.data.results;
                    updateClients(clientdata)
                    return;
                }
                if ((res.status === 404 || res.status === 400) && user.profile.enterprise.name == 'Ativa Investimentos') {
                    useNotification(
                        'Ops!',
                        'O sistema não conseguiu identificar sua organização',
                        'danger'
                    );
                    return;
                }
                throw res;
            })
            .catch(async (err) => {
                useNotification(
                    'Ops!',
                    'Ocorreu um erro no sistema',
                    'danger'
                );
                console.error(err);
            });
        return true
    }

    const getCustomFields = async () => {
        if (enterpriseId == 0) return

        let url = `/api/enterprises/${enterpriseId}/custom_fields/`;
        
        const response = await api.get(url)
        if (response.status !== 200) {
            updateCustomFields([])
            return
        }

        updateCustomFields(response.data)
        return true
    }

    const loadMoreGroups = async () => {
        if (nextGroupsUrl)
            await getGroups(nextGroupsUrl)
    }

    const getGroups = async (groupsUrl, search) => {
        if (enterpriseId == 0) 
            return

        if (! groupsUrl && ! search) {
            nextGroupsUrl = null
            globalGroups  = []
        }

        let url
        if (groupsUrl)
            url = `/api/${groupsUrl.split('/api/')[1]}`
        else {
            url = `/api/enterprises/${enterpriseId}/contact_group/noserialize/`
            if (search !== undefined)
                url = `${url}?search=${search}`
        }
        
        const response = await api.get(url)

        if (response.status === 403) {
            useNotification(
                'Ops!',
                'Você não tem permissão para ver esse conteúdo',
                'danger'
            );
            updateGroups([])
            return
        }

        globalGroups = (search !== undefined) ? response.data.results : [...globalGroups, ...response.data.results]
        
        updateGroups(globalGroups)
        
        nextGroupsUrl = response.data.next
        
        return true
    }

    const getTemplates = async () => {
        if (enterpriseId == 0) return
        await api.get(`/api/enterprises/${enterpriseId}/templates/`, { timeout: 0 })
            .then((res) => {
                if (res.status == 200) {
                    let templates = res.data.waba_templates.filter((template) => {
                        return (template.status == 'approved' &&
                            template.language == 'pt_BR' &&
                            !template.name.includes('sample_'));
                    });
                    updateTemplates(templates);
                    return;
                }
                throw res;
            })
            .catch((err) => {
                console.error(err);
            });
        return true
    }

    const fetchNotifications = async (starttime, endtime, command = 'get', limit = 25, offset = 0, filter = { items: [] }, sort = []) => {

        let newlimit = limit
        let newoffset = offset
        if (command == 'get' && limitOffset.notifications.length > 0) {
            newlimit = limitOffset.notifications[0]
            newoffset = limitOffset.notifications[1]
        }


        //filter
        if (command == 'filter') {
            updateFilterModel(prevState => ({ ...prevState, notifications: filter }))
        }
        else {
            filter = filterModel.notifications
        }

        let filterString = filter.items.map(each => {
            if (each.value)
                return (`&${each.columnField}__${each.operatorValue}=${each.value}`)
        }).join('')

        if ('linkOperator' in filter && filter.linkOperator == 'or')
            filterString += '&or=True'

        //sort
        if (command == 'sort') {
            updateSortModel(prevState => ({ ...prevState, notifications: sort }))
        }
        else {
            sort = sortModel.notifications
        }

        const sortString = sort.map(each => (`&sort=${each.field}__${each.sort}`)).join('')

        const url = `/api/enterprises/${enterpriseId}/notifications/pre_filtered/?limit=${newlimit}&offset=${newoffset}${filterString}${sortString}`;

        const Start = moment(starttime).format('YYYY-MM-DD HH:mm').toString();
        const End = moment(endtime).format('YYYY-MM-DD HH:mm').toString();

        await api.post(url, {
            start_datetime: Start,
            end_datetime: End
        }).then((res) => {
            if (res.status == 200) {
                updateServerData(prevState => ({ ...prevState, notifications: res.data }))
                updateNotifications(res.data.results)
                return true;
            }
            throw res;
        }).catch( async (err) => {
            console.error(err);
            useNotification(
                'Ops!',
                err.data?.message || 'Não foi possível receber os dados.',
                'danger'
            );

        });
        return true;
    }

    const fetchCampaigns = async (command = 'get', limit = 25, offset = 0, filter = { items: [] }, sort = []) => {

        let newlimit = limit
        let newoffset = offset
        if (command == 'get' && limitOffset.campaigns.length > 0) {
            newlimit = limitOffset.campaigns[0]
            newoffset = limitOffset.campaigns[1]
        }

        //filter
        if (command == 'filter') {
            updateFilterModel(prevState => ({ ...prevState, campaigns: filter }))
        }
        else {
            filter = filterModel.campaigns
        }

        let filterString = filter.items.map(each => {
            if (each.value)
                return (`&${each.columnField}__${each.operatorValue}=${each.value}`)
        }).join('')

        if ('linkOperator' in filter && filter.linkOperator == 'or')
            filterString += '&or=True'

        //sort
        if (command == 'sort') {
            updateSortModel(prevState => ({ ...prevState, campaigns: sort }))
        }
        else {
            sort = sortModel.campaigns
        }

        const sortString = sort.map(each => (`&sort=${each.field}__${each.sort}`)).join('')

        const url = `/api/enterprises/${enterpriseId}/campaign/?limit=${newlimit}&offset=${newoffset}${filterString}${sortString}`;
        const response = await api.get(url)

        if (response.status != 200) {
            response.data = []
            response.data.results = []
        }

        updateServerData(prevState => ({ ...prevState, campaigns: response.data }))
        updateCampaigns(response.data.results)
    }

    const initSession = async () => {
        const user = getSession();
        if (user && user.profile) {
            const enterprise = user.profile.enterprise;
            if (enterprise) {
                updateEnterpriseId(enterprise.id);
                updateAccountcode(enterprise.accountcode)
                updateSettings(enterprise.settings)
            }
            const queues_set = user.profile.queues;
            if (queues_set) {
                let user_queues = {};
                queues_set.map(queue => {
                    user_queues[queue.description] = queue.name;
                });
                updateQueues(user_queues);
            }
            return true
        }
    }


    //Substitute or add data from objNew into objOld by their id
    //Data must be in the format [{id:1, ...}, {id:2, ...}, {id:3, ...}, ...]
    // Received data must be a unique object or an array of objects.
    const subsObj = (objOld, objNew) => {
        const parsed = {}
        objOld.map(each => { parsed[each.id] = each })
        const newobj = {}
        if (Array.isArray(objNew)) {
            objNew.map(each => { newobj[each.id] = each })
            return Object.values({ ...parsed, ...newobj })
        }
        newobj[objNew.id] = objNew
        return Object.values({ ...parsed, ...newobj })
    }
    //Remove data from an array by idlist, 
    //Data must be in the format [{id:1, ...}, {id:2, ...}, {id:3, ...}, ...]
    //Received data must be in the format [1,2,3,...] or a unique integer.
    const rmvObj = (oldArr, idList) => {
        if (Array.isArray(idList)) {
            return oldArr.filter(each => !idList.includes(each.id))
        }
        return oldArr.filter(each => idList !== (each.id))
    }


    // initialize
    if (!enterpriseId) {
        initSession()
        updatePermissions(UserPermissions())
    }


    const memo = useMemo(async () => {
        updateReady(false)

        if (enterpriseId) {
            const date = new Date()
            date.setHours(15)
            date.setMinutes(0)
            date.setSeconds(0)
            const yesterday = new Date()
            yesterday.setHours(5)
            yesterday.setMinutes(0)
            yesterday.setSeconds(0)
            const isoDate = date.toISOString().slice(0, 19)
            const isoYesterday = yesterday.toISOString().slice(0, 19)

            updateStartDateTime(isoYesterday)
            updateEndDateTime(isoDate)

            const promise1 = getTemplates()
            const promise2 = getClients()
            const promise3 = getGroups()
            const promise4 = fetchNotifications(isoYesterday, isoDate)
            const promise5 = getOriginNumbers()
            const promise6 = fetchCampaigns()
            const promise7 = getCustomFields()

            await Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7])

            if (promise1 && promise2 && promise3
                && promise4 && promise5 && promise6)
                updateReady(true)
        }

    }, [enterpriseId])

    useEffect(() => {
        if (cache == -1) {
            getClients()
            getGroups()
            setCache(null)
        }
        return
    }, [cache])

    useEffect(() => {
        clearTimeout(timeout)
        checkCache()
    }, [cache])

    const checkCache = async () => {
        if (cache) {
            const res = await api.get(`/api/enterprises/${enterpriseId}/contacts/progress/${cache}/`)

            if (res.status == 200) {
                setLoadingContent(res.data)
                if (res.data?.status == 8) {
                    setLoadingContent()
                    return
                }
                timeout = setTimeout(checkCache, 1000)
                return
            }
            setLoadingContent()
        }
    }

    const setTab = (tab) => {
        updateNumberSelectedModel([])
        updateSelectedTab(tab)
    }

    return (
        <>
            {!ready ? <Loading loading={true} /> : null}
            {ready ?
                <ScreenContext.Provider
                    value={{
                        updateStartDateTime, updateEndDateTime, getGroups, getCustomFields,
                        fetchCampaigns, fetchNotifications, getClients, updateClients,
                        updateReady, subsObj, rmvObj, updateCampaigns,
                        originNumbers, clients, groups, customFields, templates, queues,
                        accountcode, enterpriseId, selectedTab, permissions,
                        notifications, startDateTime, endDateTime, campaigns,
                        settings, campaigns, updateSelectedTab,
                        isSmallScreen, updateGroups, updateCustomFields, selectedModel,
                        updateSelectedModel, ServerData, updateLimitOffset,
                        limitOffset, filterModel,
                        updateFilterModel, sortModel, updateSortModel, setCache, cache,
                        selectedNumberModel, updateNumberSelectedModel
                    }}
                >
                    <Box className="screen-wrapper">
                        <Box className="outer-Screen">
                            <Box className="inner-Screen">
                                <TabButtons />
                            </Box>
                            <Box >
                                {! isMobile() &&
                                    <>
                                        <ResponsiveButton
                                            className="button-table"
                                            onClickFn={() => { setTab('notifications') }}
                                            id={'btn-notifications'}
                                            variant={"contained"}
                                            title={'Disparos'}
                                            setIcon={<MessageIcon />}
                                            selected={selectedTab === 'notifications'}
                                        />
                                        <ResponsiveButton
                                            className="button-table"
                                            onClickFn={() => { setTab('campaigns') }}
                                            id={'btn-campaigns'}
                                            variant={"contained"}
                                            title={'Campanhas'}
                                            setIcon={<CampaignIcon />}
                                            selected={selectedTab === 'campaigns'}
                                        />
                                    </>
                                }
                                <ResponsiveButton
                                    className="button-table"
                                    onClickFn={() => { setTab('clients') }}
                                    id={'btn-Clients'}
                                    variant={"contained"}
                                    title={'Clientes'}
                                    setIcon={<ClientsIcon />}
                                    selected={selectedTab === 'clients'}
                                />
                                <IconButton
                                    className="button-refresh"
                                    onClick={handleRefresh}
                                    disabled={smallReload}
                                    size={true ? "small" : "medium"}
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </Box>
                        </Box>

                        <Box className="table-screen">
                            <TabTables loadMoreGroups={loadMoreGroups}/>
                        </Box>
                    </Box>
                </ScreenContext.Provider>
                : null
            }
        </>
    )
}

export default Crm