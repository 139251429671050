import React, { useEffect, useReducer, useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  List,
  ListItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../../../api";
import Loading from "../../../Loading/index.js";
import { getSession } from "../../../../auth";
import { lockedReducer } from "../../../../utils/defaultReducer";
import ScoutMessageComponent from '../ScoutMessageComponent/index.js'
import useNotification from '../../../Notification';

export const ConvoDisplayer = ({ id, description }) => {
  return (
    <Box key={`message-date-${id}`} className="date-centralizer-box">
      <Box className="date-centralizer-line"></Box>
      <Box className="date-displayer">
        {description}
      </Box>
      <Box className="date-centralizer-line"></Box>
    </Box>
  );
};
const TextSearch = ({ searchQuery, handleClose, parent, setParent }) => {
  const enterprise = getSession().profile.enterprise;

  const [state, setState] = useReducer(lockedReducer, {
    loading: true,
    notFound: false,
    matched_messages: [],
  });

  const getMessages = async (enterprise_id) => {
    const res = await api.post(
      `api/enterprises/${enterprise_id}/scout_accounts/text_search/`,
      {
        text: searchQuery,
      },
      {
        timeout: 20000,
      }
    );

    if (res.status === 200) {
      if (res.data && Object.keys(res.data).length > 0) {
        setState({ 
          matched_messages: res.data,
          ntFound: false,
        });
      } else { 
        setState({ 
          notFound: true,
        });
      }
    } else {
      console.error("Error getting messages", res);
      useNotification(
        'Erro!',
        `[${res.status !== undefined? res.status: 408}] ${res.statusText ? res.statusText : 'Erro ao buscar mensagens'}`,
        'danger'
      );
      handleClose();
    }
    setState({ loading: false });
  };

  useEffect(() => {
    getMessages(enterprise.id);
  }, []);

  const MatchedConversation = ({ conversation, conversationKey, index }) => {
    return (
      <>
        <ConvoDisplayer
          id={0}
          description={conversationKey}
        />

        {conversation.messages.map((message, index) => (
            <ScoutMessageComponent 
              message={message} 
              index={index} 
              chat={conversation.type} 
              messages={[]} 
              parent={parent}
              setParent={setParent} 
              searchWidget={true}
              conversation={conversation}
              owner={conversation.owner} />
        ))}
      </>
    );
  };

  return (
    <>
      <Loading loading={state.loading} />
      <Dialog
        open={true}
        fullWidth
        disablePortal
        disableEnforceFocus
        maxWidth={"md"}
        sx={{ position: "absolute" }}
      >
        <DialogTitle className="group-flexbox">
          Busca
          <Button sx={{ minWidth: "2rem", padding: "0" }} onClick={handleClose}>
            <CloseIcon />
          </Button>
        </DialogTitle>
        <DialogContent>
          <Box className="modal-scout-search">
            <Box
              style={{ display: "block", height: "100%" }}
              id={"scout-search-area"}
              key={"scout-search-message-area"}
              // ref={scrollArea}
              // onScroll={(e) => detectPosition(e, state.gluedOnBottom, setState)}
            >

              {state.notFound === true ? 
                <Box sx={{ padding: '1rem 0rem' }}>Nenhuma mensagem com "{searchQuery}" encontrada.</Box>
              :
                <List
                  // onScroll={() => calcGlued(state.glued, setState, windowRef)}
                  // ref={windowRef}
                  className="scout-window-content-list"
                  key={`scout-search-message-list`}
                >
                  {Object.keys(state.matched_messages).map((key, index) => (
                    <MatchedConversation
                      conversation={state.matched_messages[key]}
                      conversationKey={state.matched_messages[key].conversationName}
                      index={index}
                    />
                  ))}
                </List>
              }
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TextSearch;
