import axios from "axios";

import { getAdminToken, getToken, saveToken, getSuperAdminToken } from "./auth";

const api = axios.create({ baseURL: '/api/' });

api.defaults.timeout = 5000;

const superAdminPaths = ["/plans", "/products", "/enterprises", "/managers", "/nfse", "/paymenthistory", "/sequences", "/templateemail", "/boletos"]

api.interceptors.request.use(async (config) => {
    let token = getToken()

    if (window.location.pathname === '/metrics') 
        token = getAdminToken()
    if (superAdminPaths.includes(window.location.pathname))
        token = getSuperAdminToken()

    if (token) 
        config.headers.Authorization = `Bearer ${token}`

    return config;
});

api.interceptors.response.use((response) => { return response }, 
    async function (err) {
        if (! err.response)
            return err;
        
        const originalRequest = err.config;

        if (err.response.status === 401) {
            // meta exists for 360dialog 401, we don't want to redo the request
            if (! (typeof err.response.data === 'object') || ! ('meta' in err.response.data)) {
                if (superAdminPaths.includes(window.location.pathname))
                    document.location.href = '/superadmin';    
                document.location.href = '/';
            }
        } 
        else if (err.response.status === 502)
            return api(originalRequest);

        return err.response;
    }
);

export default api;
