import React from "react"

import AdminDrawer from "../../components/AdminDrawer"
import EmailTemplatesComponent from "../../components/EmailTemplates";

const EmailTemplates = () => {
  
    return (
        <AdminDrawer child={<EmailTemplatesComponent/>}/>
    );
};

export default EmailTemplates
