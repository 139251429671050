import React from 'react';

import { ListItemText, ListItem, ThemeProvider, ListItemButton, List, Tooltip } from '@mui/material';
import { Box, Grid, Badge, StyledEngineProvider, IconButton } from '@mui/material';

import SmsIcon from '@mui/icons-material/Sms';
import { getSession } from '../../../../auth';

import QueueIcon from '@mui/icons-material/Group';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MicrosoftIcon from '@mui/icons-material/Groups';
import EmailIcon from '@mui/icons-material/Email';
import InstantChatIcon from '@mui/icons-material/Language';
import InfoIcon from '@mui/icons-material/Info';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import InboundIcon from '@mui/icons-material/Person';
import OutboundIcon from '@mui/icons-material/Apartment';
import ReactivationIcon from '@mui/icons-material/Update';
import TwentyFourHourWindowIcon from '@mui/icons-material/MoreTime';
import AgentIcon from '@mui/icons-material/SupportAgent';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ApiIcon from '@mui/icons-material/DataObject';

import theme from '../../../../assets/theme'
import Typography from '../../../Typography';
import LinearProgress from '../../../Progress/Linear'
import Popover from '../../../Popover';
import api from '../../../../api';

import OutsideClickHandler from 'react-outside-click-handler';
import PageVisibility from 'react-page-visibility';

import { emojiReplaceJSX } from '../../../../utils/emojiDict';

import * as moment from "moment";

import "./styles.css";


class ConversationItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            lastMessageText: '',
            lastMessageTime: '00:00',
            unreadMessages: [],
            infoConversation: false,
            pageVisibility: false,
            intervalId: null,
        }

        this.lastUserMessageTime = Math.round(Date.now() / 1000);
        this.maxWaitTime = 86400;
    }

    handleVisibilityChange = (isVisible) => {
        this.setState({ pageVisibility: !isVisible });
    }

    handlePopoverOpen = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    handlePopoverClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    getLastMessageText = () => {

        const lastMessage = this.getLastMessage();

        if (this.props.conversation.platform === 'EMAIL') {
            const subject = lastMessage.message.match(/\(emailsubject\)(.*?)\(\/emailsubject\)/g)?.map(match => match.replace(/\(emailsubject\)|\(\/emailsubject\)/g, ''))
            if (subject)
                return `Assunto: ${subject}`
            lastMessage.message = lastMessage.message.replace(/\(emailinbox\)(.*?)\(\/emailinbox\)|\(emailsubject\)(.*?)\(\/emailsubject\)/g, '')
        }


        let prefix;
        switch (lastMessage?.user_id) {
            case -1:
                prefix = 'ChatBot: ';
                break;
            case 0:
                prefix = 'Cliente: ';
                break;
            default:
                prefix = 'Você: ';
                break;
        }

        if (this.props.isManageCalls && lastMessage.message?.includes('[buttons title='))
            lastMessage.message = 'Menu de opções'

        return `${prefix}${lastMessage.message}`;
    }

    getLastMessage = () => {

        if (this.props.conversation.last_message)
            return this.props.conversation.last_message

        const messages = this.props.conversation.messages;
        return (messages && messages.length > 0) ? messages[messages.length - 1] : { message: 'Não encontrada', user_id: -1 };
    }

    getLastUserMessage = () => {

        if (this.props.conversation.last_user_message) {
            if (this.props.conversation.platform == "INSTANTCHAT" && !this.props.conversation.last_user_message.time) {
                return this.props.conversation.chatstartts
            }
            return this.props.conversation.last_user_message
        }

        const messages = this.props.conversation.messages;

        if (!messages) return ''

        for (var i = messages.length - 1; i >= 0; i--) {
            if (messages[i].user_id == 0) {
                return messages[i];
            }
        }
    }

    resetChatProgress = () => {
        if (this.linearProgress)
            this.linearProgress.resetProgress();
    }

    isAudio = (message) => {
        return message.indexOf('wpp-audio') != -1 ||
            (message.indexOf('[audio]') != -1 &&
                message.indexOf('[/audio]') != -1);
    }

    isStory = (message) => {
        return (message.indexOf('[story]') != -1 &&
            message.indexOf('[/story]') != -1);
    }

    isVideo = (message) => {
        return message.indexOf('wpp-video') != -1 ||
            (message.indexOf('[video]') != -1 &&
                message.indexOf('[/video]') != -1);
    }

    isImage = (message) => {
        return message.indexOf('wpp-image') != -1 ||
            (message.indexOf('[img]') != -1 &&
                message.indexOf('[/img]') != -1);
    }

    isDocument = (message) => {
        return message.indexOf('wpp-document') != -1 ||
            (message.indexOf('[url') != -1 &&
                message.indexOf('[/url]') != -1);
    }

    isLocation = (message) => {
        return message.indexOf('wpp-location') != -1;
    }

    removeSpecialMessage = (message) => {
        let prefix = '';

        if (this.isAudio(message)) {
            prefix = message.split(' ')[0] + ' ';
            message = 'Enviou um áudio';

        } else if (this.isVideo(message)) {
            prefix = message.split(' ')[0] + ' ';
            message = 'Enviou um vídeo';

        } else if (this.isStory(message)) {
            prefix = message.split(' ')[0] + ' ';
            message = 'Enviou um story';

        } else if (this.isImage(message)) {
            prefix = message.split(' ')[0] + ' ';
            message = 'Enviou uma imagem';

        } else if (this.isDocument(message)) {
            prefix = message.split(' ')[0] + ' ';
            message = 'Enviou um documento';

        } else if (this.isLocation(message)) {
            prefix = message.split(' ')[0] + ' ';
            message = 'Enviou uma localização';
        }

        // Removes string "<br>" from message if any
        const text = `${prefix}${message}`.replace(/<br> /g, '');

        return text;
    }

    MouseOver = (event) => {
        this.setState({ infoConversation: true })
    }

    MouseOut = (event) => {
        this.setState({ infoConversation: false });
    }

    generateBadgeClass = () => {
        return (this.state.unreadMessages >= 100) ? 'badgeItem-unreadMessages badgeItem-hundred'
            : (this.state.unreadMessages >= 10) ? 'badgeItem-unreadMessages badgeItem-ten'
                : 'badgeItem-unreadMessages badgeItem-zero';
    }

    displayMoreThan24hIcon = () => {
        const date24HoursAgo = moment(new Date()).subtract(1, 'day');

        const lastchat = this.props.conversation
        if (lastchat?.last_user_msg_time){
            return Date.parse(date24HoursAgo) >= lastchat.last_user_msg_time*1000 ? true : false;
        }
        const lastUserMessage = this.getLastUserMessage();

        if (!lastUserMessage)
            // Avoid icon display for web chats with no user messages
            return false;

        const lastUserMessageTime = lastUserMessage.time * 1000;

        return Date.parse(date24HoursAgo) >= lastUserMessageTime ? true : false;
    }

    platformIcon = () => {
        const conversation = this.props.conversation;
        if (conversation.lh_chat)
            conversation.platform = conversation.lh_chat.platform;

        let platform = conversation.notification_origin || conversation.platform
        
        switch (platform) {
            case 'INSTANTCHAT':
                return (
                    <Tooltip title="Chat Web" placement="bottom" disableInteractive>
                        <InstantChatIcon className="conversationItemIcon instantChatIcon" />
                    </Tooltip>
                );
                case 'SMS':
                    return (
                        <Tooltip title="SMS" placement="bottom" disableInteractive>
                           <SmsIcon className="conversationItemIcon instantChatIco" sx={{ color: '#757575' }} />
                        </Tooltip>
                    );
            case 'WHATSAPP_ATIVO':
            case 'WHATSAPP':
                return (
                    <Tooltip title="WhatsApp" placement="bottom" disableInteractive>
                        <WhatsAppIcon className="conversationItemIcon whatsAppIcon" />
                    </Tooltip>
                );
            case 'TELEGRAM':
                return (
                    <Tooltip title="Telegram" placement="bottom" disableInteractive>
                        <TelegramIcon className="conversationItemIcon telegramIcon" />
                    </Tooltip>
                );
            case 'INSTAGRAM':
                return (
                    <Tooltip title="Instagram" placement="bottom" disableInteractive>
                        <InstagramIcon className="conversationItemIcon instagramIcon" />
                    </Tooltip>
                );
            case 'MESSENGER':
                return (
                    <Tooltip title="Messenger" placement="bottom" disableInteractive>
                        <FacebookIcon className="conversationItemIcon messengerIcon" />
                    </Tooltip>
                );
            case 'MSTEAMS':
                return (
                    <Tooltip title="Microsoft Teams" placement="bottom" disableInteractive>
                        <MicrosoftIcon className="conversationItemIcon msteamsIcon" />
                    </Tooltip>
                );
            case 'EMAIL':
                return (
                    <Tooltip title="Email" placement="bottom" disableInteractive>
                        <EmailIcon className="conversationItemIcon emailIcon" />
                    </Tooltip>
                );
            case 'AUTOMATION':
                return (
                    <Tooltip title="Automação" placement="bottom" disableInteractive>
                        <SmartToyIcon className="conversationItemIcon emailIcon" />
                    </Tooltip>
                );
            case 'INTEGRATION':
                return (
                    <Tooltip title="Integração" placement="bottom" disableInteractive>
                        <IntegrationInstructionsIcon className="conversationItemIcon integration" />
                    </Tooltip>
                );
            case 'API':
                return (
                    <Tooltip title="API" placement="bottom" disableInteractive>
                        <ApiIcon className="conversationItemIcon emailIcon" />
                    </Tooltip>
                );
        }
    }

    setAgentName = () => {
        let agentName = null;

        if (this.props.conversation.agent && this.props.conversation.agent.name)
            agentName = this.props.conversation.agent.name;

        return agentName;
    }

    checkSupervisedMessages = () => {

        if (this.props.conversation.supervised)
            return this.props.isManageCalls

        const messages = this.props.conversation.messages;

        const hasSupervisedMsgs = messages?.filter(msg => msg.status === 'PENDING');

        return this.props.isManageCalls && hasSupervisedMsgs?.length;
    }

    componentDidUpdate() {
        let lastMessageText = this.removeSpecialMessage(this.getLastMessageText());
        let lastMessageTime = this.getLastMessage().iso_time;
        if (lastMessageText !== this.state.lastMessageText ||
            lastMessageTime !== this.state.lastMessageTime) {
            this.setState({
                lastMessageText: lastMessageText,
                lastMessageTime: lastMessageTime
            });
        }

        if (!this.props.isManageCalls) {
            const lastUserMessage = this.getLastUserMessage();
            if (lastUserMessage) {
                const lastUserMessageTime = lastUserMessage.time;
                if (this.lastUserMessageTime != lastUserMessageTime) {
                    this.lastUserMessageTime = lastUserMessageTime;
                    if (this.linearProgress)
                        this.linearProgress.resetProgress();
                }
            }
        }
    }

    componentWillMount() {
        this.state.lastMessageText = this.removeSpecialMessage(this.getLastMessageText());
        this.state.lastMessageTime = this.getLastMessage().iso_time;

        let lastUserMessage = this.getLastUserMessage();
        if (lastUserMessage)
            this.lastUserMessageTime = lastUserMessage.time;

        this.checkUnreadMessages();
        let intervalId = setInterval(this.checkUnreadMessages, 2000);
        this.setState({ intervalId: intervalId });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    checkUnreadMessages = () => {
        let unreadMessages = [];

        if (this.props.conversation.unread_messages) {
            unreadMessages = Array(this.props.conversation.unread_messages).fill(1)

            this.state.unreadMessages = unreadMessages;
            return
        }

        this.props.conversation.messages?.forEach((message) => {
            if (!message.is_read)
                unreadMessages.push(message);
        });

        if (this.props.activeId === this.props.conversation.uniqueid) {
            this.props.setReadMessages(unreadMessages);
            unreadMessages.forEach((message) => { message.is_read = true; });
            return;
        }

        this.state.unreadMessages = unreadMessages;
    }

    openChat = () => {
        // if (this.props.isManageCalls)
        //     this.props.setReadMessages(this.state.unreadMessages);

        this.props.onClick();
    }

    fillGroups = () => {
        const filteredGroups = this.props.conversation?.contact?.groups ?? []
        if (filteredGroups.length !== 0) {
            return (
                <Box className='wrapped-parent'>
                    <Box className='wrapped-relevant'>
                        <List className={'special-inline-flex'}>
                            {filteredGroups?.map((group) => (
                                <ListItem style={{ backgroundColor: "#757575" }} disableGutters className="label-item" onClick={() => {}}>
                                    <ListItemButton disableGutters sx={{ padding: "4px", overflow: "hidden", fontSize: "0.6rem", }}>
                                        <span>{group.name}</span>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>
            )
        }
    }

    fillLabels = () => {
        const filteredLabels = this.props.conversation?.labels
        if (filteredLabels.length !== 0) {
            return (
                <Box className='wrapped-parent'>
                    <Box className='wrapped-relevant'>
                        <List className={'special-inline-flex'}>
                            {filteredLabels?.map(label => (
                                <ListItem
                                    disableGutters
                                    className="label-item"
                                    onClick={() => { }}
                                    sx={{ background: `${label.color} !important` }}
                                >
                                    <ListItemButton disableGutters sx={{ padding: "4px", overflow: "hidden", fontSize: "0.6rem", }}>
                                        <span>{label.name}</span>
                                    </ListItemButton>
                                </ ListItem >
                            ))}
                        </List>
                    </Box>
                </Box>
            )   
        }
    }

    connectClickedChat = (event) => {
        this.props.connectEmailChat()
        event.stopPropagation()
    }

    messageTreatment = (message) => {
        try {
            if ('queue' in this.props.conversation) {
                if (!this.props.conversation.queue.settings.can_receive_url) {
                    const urlPattern = new RegExp(/(https?:\/\/[^\s]+)/g)
                    if (urlPattern.test(message))
                        return "-- URL Bloqueada --"
                }
            }
            return emojiReplaceJSX(message)
        } catch (error) {
            if (error instanceof TypeError)
                return emojiReplaceJSX(message)
        }
    }

    render() {
        const active = this.props.activeId === this.props.conversation.uniqueid || this.props.forceActive;

        const client_number = (this.props.conversation.notification && this.props.conversation.contact?.nick)
            ? this.props.conversation.contact.nick
            : this.props.conversation.callerid
            ;
        const client_name = (this.props.conversation.contact)
            ? this.props.conversation.contact.name
            : null
            ;

        let queueName = this.props.conversation.queue?.description;
        if (!queueName)
            queueName = 'Sem fila'

        const chatStatus = this.props.conversation.chatstatus;
        const agentName = this.props.conversation.agent_name;

        const conversation = this.props.conversation;
        if (conversation.lh_chat) {
            conversation.notification = conversation.lh_chat.notification;
            conversation.reactivation = conversation.lh_chat.reactivation;
        }

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <PageVisibility onChange={this.handleVisibilityChange}></PageVisibility>
                    <Box id="conversationItem-box-1" className={`${active ? "active" : ''} ${this.checkSupervisedMessages() ? 'supervised-msg' : ''}`}>
                        <ListItem id="conversationItem-listItem" onClick={() => this.openChat()}>
                            <ListItemText id="conversationItem-listItemText-1"
                                primary={
                                    <Box className={active ? "conversationItem-box-2 active" : "conversationItem-box-2"}>
                                        {!this.props.conversation.notification && !this.props.conversation.reactivation &&
                                            <Tooltip title="Conversa iniciada pelo cliente">
                                                <InboundIcon
                                                    className="direction-icon"
                                                />
                                            </Tooltip>
                                        }
                                        {this.props.conversation.notification && !this.props.conversation.reactivation &&
                                            <Tooltip title="Conversa iniciada pela empresa">
                                                <OutboundIcon
                                                    className="direction-icon"
                                                />
                                            </Tooltip>
                                        }
                                        {!this.props.conversation.notification && this.props.conversation.reactivation &&
                                            <Tooltip title="Conversa reativada pelo operador">
                                                <ReactivationIcon
                                                    className="direction-icon"
                                                />
                                            </Tooltip>
                                        }
                                        <Typography noWrap={true}
                                            text={(client_name) ? client_name : client_number}
                                            justifyContent="left"
                                            align="left"
                                        />
                                        {this.platformIcon()}
                                        {this.fillGroups()}
                                        {this.fillLabels()}
                                    </Box>
                                }
                                secondary={
                                    <Box style={{ color: active ? "" : "gray" }}>
                                        <Typography noWrap={true}
                                            text={this.messageTreatment(this.state.lastMessageText)}
                                            justifyContent="left"
                                            align="left"
                                        />
                                    </Box>
                                }
                            />
                            <ListItemText
                                secondary={
                                    <Box id="conversationItem-box-3">
                                        <Box id="conversationItem-box-4" color={active ? 'white' : '#21446c'}>
                                            <Box id="conversationItem-box-6"
                                                color={active ? 'white' : '#21446c'}>
                                                <Grid container>
                                                    <Grid sx={{ display: "flex", gap: ".25rem" }} xs={12}>
                                                        {this.displayMoreThan24hIcon() &&
                                                            <Tooltip title="Atendimento fora da janela de 24 horas" placement="bottom" disableInteractive>
                                                                <TwentyFourHourWindowIcon className="twenty-for-hour-window-icon"
                                                                    style={{ color: active ? "white" : "#21446c" }}
                                                                />
                                                            </Tooltip>
                                                        }
                                                        <Typography 
                                                            text={this.getLastMessage().time
                                                                ? moment(this.getLastMessage().time * 1000).format("DD/MM/YY").toString() + ' ' + this.state.lastMessageTime
                                                                : moment(this.props.conversation.time).format("DD/MM/YY").toString() + ' ' + this.state.lastMessageTime
                                                            }
                                                            fontWeight="500"
                                                            noWrap={true}
                                                        />
                                                        <CalendarIcon/>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            {this.state.infoConversation &&
                                                <Box className="infoConversation" onMouseOut={this.MouseOut}>
                                                    <Grid container>
                                                        <Grid item lg={10} md={10} sm={10} xs={10}>
                                                            <Box className="queue-name">
                                                                <Typography text={queueName}
                                                                    fontWeight="500"
                                                                    align="right"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={2} md={2} sm={2} xs={2}>
                                                            <QueueIcon aria-owns={this.state.anchorEl ? 'mouse-over-popover' : undefined}
                                                                aria-haspopup="true"
                                                                onMouseEnter={this.handlePopoverOpen}
                                                                onMouseLeave={this.handlePopoverClose}
                                                                className="queueIcon"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    {chatStatus !== 'QUEUE' && this.props.isManageCalls && agentName &&
                                                        <Grid container>
                                                            <Grid item lg={10} md={10} sm={10} xs={10}>
                                                                <Box className="queue-name">
                                                                    <Typography text={agentName}
                                                                        fontWeight="500"
                                                                        align="right"
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item lg={2} md={2} sm={2} xs={2}>
                                                                <AgentIcon aria-owns={this.state.anchorEl ? 'mouse-over-popover' : undefined}
                                                                    aria-haspopup="true"
                                                                    onMouseEnter={this.handlePopoverOpen}
                                                                    onMouseLeave={this.handlePopoverClose}
                                                                    className="queueIcon"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    <Grid container>
                                                        <Grid item lg={10} md={10} sm={10} xs={10}>
                                                            <Box marginLeft="10px" marginRight="5px">
                                                                <Typography
                                                                    text={this.getLastMessage().time
                                                                        ? moment(this.getLastMessage().time * 1000).format("DD/MM/YYYY").toString()
                                                                        : moment(this.props.conversation.time).format("DD/MM/YYYY").toString()
                                                                    }
                                                                    fontWeight="500"
                                                                    align="right"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={2} md={2} sm={2} xs={2}>
                                                            <CalendarIcon className="calendarIcon" />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            }
                                        </Box>
                                        <Box id="conversationItem-box-5" color={active ? 'white' : '#21446c'}>
                                            <Box className={this.generateBadgeClass()}>
                                                {this.props.conversation.chatstatus === 'ONCHAT' && !this.props.isManageCalls && this.state.unreadMessages.length !== 0 &&
                                                    <Tooltip title="Mensagens não lidas" placement="right" disableInteractive>
                                                        <Badge color="secondary"
                                                            badgeContent={this.state.unreadMessages.length}
                                                            max={99}
                                                        />
                                                    </Tooltip>
                                                }
                                                {active && this.props.conversation.platform === 'EMAIL' && this.props.conversation.chatstatus === 'QUEUE' &&
                                                    <Tooltip title="Iniciar atendimento" placement="bottom" disableInteractive>
                                                        <IconButton
                                                            onClick={(event) => this.connectClickedChat(event)}
                                                            className="connect-email-icon"
                                                            size="large"
                                                        >
                                                            <ArrowForwardIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </Box>
                                            <QueueIcon/>
                                            <Typography text={queueName} fontWeight="500" noWrap/>
                                        </Box>
                                        {chatStatus !== 'QUEUE' && this.props.isManageCalls && agentName &&
                                            <Box id="conversationItem-box-5" color={active ? 'white' : '#21446c'}>
                                                <Box className={this.generateBadgeClass()}></Box>
                                                <AgentIcon/>
                                                <Typography text={agentName} fontWeight="500" noWrap/>
                                            </Box>
                                        }
                                    </Box>
                                }
                            />
                        </ListItem>
                    </Box>
                </ThemeProvider>
            </StyledEngineProvider>
        );

    }
}

export default ConversationItem;
