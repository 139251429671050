import React, { useEffect, useMemo } from 'react'
import { ListItem, Box, ListItemButton, Divider, Typography, Tooltip, ListItemText, ListItemAvatar, Avatar } from "@mui/material"
import useVisibility from '../../../../utils/isVisible'
import { CircularProgress } from "@mui/material"
import { transformTimeData, parseSender, parseLastMessage, groupIcons } from '../ChatList'
import { chatName } from '../../../../utils/scout'

const ChatRow = ({ parent, isVisibleHook = () => { }, shouldAlwaysCheck = false, chat, onClickItem, activeChat, style }) => {

    const [isVisible, currentElement, calcVisibility] = useVisibility(100, 100, parent)

    useEffect(() => {
        calcVisibility()
    }, [])

    if (shouldAlwaysCheck) {
        useEffect(() => {
            isVisibleHook(isVisible)
        }, [isVisible])
    }

    const active = activeChat?.id === chat.id


    const memoItem = useMemo(() => {
        return (
            <ListItem>
                <ListItemAvatar>
                    <Avatar alt={chat.id} src={chat.profile_picture} />
                </ListItemAvatar>
                <ListItemText

                    primary={
                        <Box className={`scout-title-header ${activeChat?.id === chat.id ? 'active' : ''}`}>
                            <span className='icon'>{groupIcons[chat.type]?.icon}</span>
                            <span className='scout-preview-message'>{chatName(chat)}</span>
                        </Box>
                    } secondary={
                        <Typography
                            component="span"
                            variant="body2"
                            color={`${activeChat?.id === chat.id ? '#dadada' : '#6d6d6dde'}`}
                        >
                            <Box className='scout-info-bearer'>
                                <span className='scout-preview-message'>{parseSender(chat.fromMe, chat.sender)}: {parseLastMessage(chat.message, chat.message_type)}</span>
                                <Box className='scout-time-bearer'>{transformTimeData(chat.last_message_timestamp)}</Box>
                            </Box>
                        </Typography>
                    }
                />
            </ListItem>
        )
    }, [chat.id, active])

    return (
        <>
            <Tooltip title={groupIcons[chat?.type]?.name}>
                <ListItemButton
                    ref={currentElement}
                    onClick={() => onClickItem(chat)}
                    className={`scout-list-item-chat ${active ? 'active' : ''}`}
                    disableGutters
                    key={`chat-item-main-id-${chat?.id}`}
                    style={style}
                >
                    {(isVisible && chat?.id !== -100) ?
                        memoItem
                        :
                        <Box className={'loader-skeleton'}
                            sx={{ height: '80px', justifyContent: "center" }}
                        >
                            <CircularProgress />
                        </Box>
                    }
                </ListItemButton>
            </Tooltip>
        </>
    )
}

export default ChatRow