import React from "react"

import AdminDrawer from "../../components/AdminDrawer"
import ProductsComponent from "../../components/Products"

const Products = () => {
  
    return (
        <AdminDrawer child={<ProductsComponent/>}/>
    );
};

export default Products
