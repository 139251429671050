import React, { useEffect, useReducer, useState } from "react";
import { Box, Alert, TextField, Button, Paper, Tooltip, InputBase, InputAdornment } from "@mui/material";
import { getSession } from "../../../auth.js";
import api from "../../../api";
import Loading from "../../Loading/index.js";
import { lockedReducer } from '../../../utils/defaultReducer.js'
import './styles.css'
import ChatWindow from "./ChatWindow/index.js";
import AccountList from "./AccountList/index.js";
import ScoutSocketComponent, { treatEvents } from "./SocketComponent/index.js";
import SearchIcon from "@mui/icons-material/Search";
import TextSearch from "./TextSearch/index.js";
import CRMSideBar from "./CRMSideBar/index.js";
import HistoryMessagesDialog from "../../Dialogs/HistoryMessagesDialog";
import { useLocation , useHistory } from 'react-router-dom';

const getAccounts = async (enterprise_id, setState) => {

  setState({loading: true})
  const res = await api.get(
    `api/enterprises/${enterprise_id}/scout_accounts/`
  );
  if (res.status === 200) {
    setState({accounts:res.data, loading:false})
    return
  }
  setState({loading:false})
}

const MonitorPage = () => {

  const windowRef = {}

  const enterprise = getSession().profile.enterprise
  const location = useLocation();
  const history = useHistory();

  const [state, setState] = useReducer(lockedReducer, {
    chats: [],
    accounts: [],
    activeAccount: null,
    activeChat: null,
    chatMessageId: null,
    loading: false,
    socket: null,
    showSearch: false,
    showCRMSidebar: false,
    //History dialog state
    showChatHistory: false,
    historyChat: null,
    historyMessages: [],
    uniqueId: null,
    lastDateTime: null,
    platform: null,
    uniqueId: null,
    nick: null,
    accountcode: null,

  })

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    getAccounts(enterprise.id, setState)
  }, []);

  useEffect(async () => {
    const queryParams = new URLSearchParams(location.search);
    const account = queryParams.get('account'); 
    const chat = queryParams.get('chat'); 
    
    if (account && chat) {
      const res = await api.get(
        `api/enterprises/${enterprise.id}/scout_accounts/?${queryParams.toString()}`
      );

      if (res.status === 200 && res.data.length > 0) {
        const account = res.data[0];
        const resc = await api.get(
            `api/enterprises/${enterprise.id}/scout_accounts/${account.id}/conversation/${chat}/`
        );
        if (resc.status === 200 && resc.data.length > 0) {
          const activeChat = { account_id: account.id, ...resc.data[0] };
          setState({
            activeAccount: account,
            activeChat: activeChat,
            chatMessageId: null,
            showSearch: false,
            showCRMSidebar: true,
          });

        } 
      }
      const newParams = new URLSearchParams();
      history.replace({ search: newParams.toString() }); // Update the URL without the query parameter


    }
  }, [location]);

  const handleCloseHistoryMessages = () => {
      let state = {
          showChatHistory: false
      };
      setState(state);
  }

  window.setState = setState

  return (
    <>
      {state.showChatHistory &&
          <HistoryMessagesDialog
              activeChat={state.historyChat}
              open={state.showChatHistory}
              uniqueId={state.uniqueId}
              nick={state.nick}
              lastDateTime={state.lastDateTime}
              historyMessages={state.historyMessages}
              platform={state.platform}
              accountcode={state.accountcode}
              handleClose={() => handleCloseHistoryMessages()}
          />
      }
      <Loading loading={state.loading} />
      <ScoutSocketComponent parent={state} setParent={setState} windowRef={windowRef} />
      {enterprise.platforms.scout ? <>

        <Box className="scout-wrapper">
          <Box className="scout-searchbar">
              <Paper
                  component="form"
                  sx={{ display: 'flex', alignItems: 'center', width: 500, height: 50, bgcolor: '#efefef' }}
              >
                  <Tooltip title="Procure nas conversas." placement="bottom" disableInteractive >
                      <InputBase
                          sx={{ ml: 2, flex: 1 }}
                          placeholder="Busca"
                          value={searchQuery}
                          onChange={e => setSearchQuery(e.target.value)}
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                setState({ showSearch: true });
                            }
                            
                          }}
                          startAdornment={
                              <InputAdornment position="start">
                                  <SearchIcon />
                              </InputAdornment>
                          }
                      />
                  </Tooltip >
              </Paper>
          </Box>
          <Box className="scout-accounts">
            <Box className='scout-accountlist'><AccountList parent={state} setParent={setState} /></Box>
            <Box className='scout-chatwindow'><ChatWindow parent={state} setParent={setState} innerRef={windowRef} /></Box>
            { state.showCRMSidebar &&
              <Box className='scout-rightsidebar'>
                <CRMSideBar parent={state} setParent={setState} />
              </Box>
            }
          </Box>
        </Box>
        { state.showSearch && <TextSearch searchQuery={searchQuery} handleClose={() => setState({ showSearch: false })} parent={state} setParent={setState} /> }
        </>
        :
        <Box className='scout-alert-padding'>
          <Alert severity="info">
            Entre em contato com seu gerente de contas para contratar o Kwik Scout - O canal de "Compliance"  e gravacao de WhatsApp do Kwik - <a href="https://wa.link/0bs73j">Clique aqui.</a>
          </Alert>
        </Box>
      }
    </>
  );
};

export default MonitorPage;
