import React from 'react';

import './styles.css';

import { Dialog, DialogTitle, DialogContent, InputAdornment, Tooltip, Divider } from '@mui/material';
import { List, ListItem, ListItemText, DialogActions } from '@mui/material';
import { Box, Button, Grid, TextField } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import Loading from '../../Loading';
import api from '../../../api';
import { getSession } from "../../../auth";
import useNotification from '../../Notification';
import useValidationError from '../../ErrorValidation';
import ParameterFiller from './ParameterFiller';
import CloseIcon from '@mui/icons-material/Close';

import SearchIcon from '@mui/icons-material/Search';
import { alphabeticalSort } from '../../../utils/alphabeticalSort';

class Templates extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedTemplate  : null,
            showParameters    : false,
            parameters        : [],
            queues            : [],
            queue             : null,
            originNumber      : null,
            loading           : false,
            mediaParameters   : {},
            allParamsFilled   : true,
            filteredTemplates : [],
            numberTemplates   : [],
            numberLoading     : false
        }
    }

    onChangeQueues = (event) => {
        this.setState({ queue: event.target.value });
    }

    onChangeOriginNumbers = (value) => {
        if (getSession().profile.enterprise.abstraction_layer) {
            this.setState({ numberLoading: true })
            
            const enterpriseId = getSession().profile.enterprise.id
            const number = value.replace(/\D/g, '');
            
            const setTemplates = (attempt = 0) => {
                api.get(`api/enterprises/${enterpriseId}/templates/${number}/`)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data === 'caching...') {
                            if (attempt < 2) {
                                setTimeout(() => setTemplates(attempt + 1), 2000)
                            } else {
                                this.setState({ numberLoading: false })
                                useNotification(
                                    'Atenção!',
                                    'Não foi possível encontrar os templates do número, tente novamente em alguns instantes.',
                                    'warning'
                                );
                            }
                        } else {
                            const templates = res.data.waba_templates.filter((t) => t.status.toLowerCase() === 'approved')
                            this.setState({
                                numberLoading     : false,
                                numberTemplates   : templates,
                                originNumber      : value,
                                filteredTemplates : templates
                            })   
                        }
                        return
                    }
                    throw err
                })
                .catch((err) => { this.setState({ numberLoading: false }) })
            }
            setTemplates()
        } else { this.setState({ originNumber: value }); }
    }

    submit = () => {

        if (this.state.showParameters) {
            this.sendMediaNotification()
            return
        }


        const requiredParameters = []
        if (this.state.selectedTemplate.components.length == 1 && this.state.selectedTemplate.components[0].type == 'BODY') {
            let amount = this.matchRegLen(this.state.selectedTemplate.components[0].text)
            if (amount)
                requiredParameters.push(
                    {
                        type: 'BODY',
                        amount: amount
                    }
                )
        }
        else {
            this.state.selectedTemplate.components.map(component => {
                let amount = 0
                if (component.type == 'HEADER') {
                    amount = 1
                    if (component.format == 'TEXT')
                        amount = this.matchRegLen(component.text)
                    if (amount)
                        requiredParameters.push({
                            type: component.type,
                            format: component.format,
                            amount: amount
                        })
                }
                else if (component.type == 'BODY') {
                    amount = this.matchRegLen(component.text)
                    if (amount)
                        requiredParameters.push(
                            {
                                type: 'BODY',
                                amount: amount
                            }
                        )
                }

                else if (component.type == 'BUTTONS') {
                    amount = 0
                    component.buttons.map(button => {
                        if (button.type === 'URL')
                            amount += this.matchRegLen(button.url)
                    })
                    if (amount)
                        requiredParameters.push(
                            {
                                type: 'BUTTONS',
                                amount: amount
                            }
                        )
                }

            })
        }

        if (requiredParameters.length > 0) {
            this.setState({
                showParameters: true,
                parameters: requiredParameters
            });
        }
        else {
            this.sendNotification();
        }

    }

    matchRegLen = value => {
        if (!value)
            return
        const matcher = value.match(/{{\d+}}/g)
        if (matcher)
            return matcher.length
        return 0
    }

    getCountTemplateParameters = () => {
        return (this.state.selectedTemplate.components[0].text.match(/{{/g) || []).length;
    }
    postNotification = (body) => {
        this.setState({ loading: true });

        api.post(`api/whatsapp/notification/`, body)
            .then(async (res) => {
                await this.setState({ loading: false });
                if (res.status === 201) {
                    await this.props.handleClose();
                    useNotification(
                        'Sucesso!',
                        'Notificação enviada aos clientes selecionados.',
                        'success'
                    );
                    await this.props.handlePostSuccess();

                    return;
                }
                throw res;
            })
            .catch((err) => {
                useValidationError(err.status, err.data?.message);
                console.error(err);
            });
    }

    sendMediaNotification = async () => {
        const user = getSession()
        // Must validate parameters.
        this.setState({ loading: true });

        let formdata = new FormData()
        const parameters = this.state.mediaParameters

        const clients = this.props.clients;
        const selectionIds = this.props.selectionIds;

        let parsed = {}
        let recipients = [];
        if (this.props.selectedNumberModel) {
            recipients = this.props.selectedNumberModel
        }
        else {
            clients.map(each => { parsed[each.id] = each })
            selectionIds.forEach((id) => {
                if (id in parsed && 'telephone' in parsed[id])
                    recipients.push(parsed[id].telephone);
            });
        }

        if (!('headerfile' in parameters) && 'headerURL' in parameters) {
            // add json format
            formdata = {
                'headerURL' : parameters.headerURL,
                'body'      : parameters?.body ? Object.values(parameters.body) : '',
                'buttons'   : parameters.buttons,
                'from'      : this.state.originNumber,
                'to'        : recipients,
                'queue_name': this.state.queue.split('_').pop(),
                'template'  : this.state.selectedTemplate.name,
                'agent'     : user.profile.agent.user.split('_').pop()
            }
        } else {
            if ('headerfile' in parameters)
                formdata.append('headerfile', parameters.headerfile)
            if ('headerbody' in parameters)
                formdata.append('headerbody', parameters.headerbody)
            if ('body' in parameters)
                Object.values(parameters.body).map(p => formdata.append('body', p))
            if ('buttons' in parameters)
                formdata.append('buttons', parameters.buttons)

            formdata.append('from', this.state.originNumber)
            formdata.append('to', recipients)
            formdata.append('queue_name', this.state.queue.split('_').pop())
            formdata.append('template', this.state.selectedTemplate.name)
            formdata.append('agent', user.profile.agent.user.split('_').pop())
        }

        localStorage.setItem("last_template_queue", this.state.queue)
        localStorage.setItem("last_template_number", this.state.originNumber)
        localStorage.setItem("last_template_name", this.state.selectedTemplate.name)

        api.post(`api/whatsapp/medianotification/`, formdata, {timeout:30000})
            .then(async (res) => {
                await this.setState({ loading: false });
                if (res.status === 201) {
                    await this.props.handleClose();
                    useNotification(
                        'Sucesso!',
                        'Notificação enviada aos clientes selecionados.',
                        'success'
                    );
                    await this.props.handlePostSuccess();
                    this.setState({
                        showParameters: false,
                        mediaParameters: {},
                        parameters: []
                    })
                    return;
                }
                throw res;
            })
            .catch((err) => {
                let message = 'Ocorreu uma falha no sistema'
                if (err.status === 400 && err.data.file)
                    message = 'Formato de arquivo não aceito.'
                else if (err.status === 400 && err.data.format)
                    message = 'Imcompatibilidade na contagem de parâmetros.'
                useNotification(
                    'Falha',
                    message,
                    'danger'
                );
                console.error(err);
            });
    }

    sendNotification = () => {
        const clients = this.props.clients;
        const selectionIds = this.props.selectionIds;
        const user = getSession()

        let parsed = {}
        let recipients = [];
        if (this.props.selectedNumberModel) {
            recipients = this.props.selectedNumberModel
        }
        else {
            clients.map(each => { parsed[each.id] = each })
            selectionIds.forEach((id) => {
                if (id in parsed && 'telephone' in parsed[id])
                    recipients.push(parsed[id].telephone);
            });
        }

        localStorage.setItem("last_template_queue", this.state.queue)
        localStorage.setItem("last_template_number", this.state.originNumber)
        localStorage.setItem("last_template_name", this.state.selectedTemplate.name)

        const body = {
            'from': this.state.originNumber,
            'to': recipients,
            'queue_name': this.state.queue.split('_').pop(),
            'template': this.state.selectedTemplate.name,
            'parameters': this.state.parameters.map(p => p.value),
            'agent': user.profile.agent.user.split('_').pop()
        };

        this.postNotification(body);
    }

    handleInputChange = (e, i) => {
        const { value } = e.target;
        const parameters = this.state.parameters;

        parameters[i] = {};
        parameters[i]['value'] = value;

        this.setState({
            parameters: parameters
        });
    }

    allParametersFilled = (value) => {
        this.setState({ allParamsFilled: value })
    }

    getQueues = () => {
        let queues = this.props.queues;
        let newQueues = [];
        if (Object.keys(queues) && Object.keys(queues).length) {
            Object.keys(queues).map(key => {
                newQueues.push(key);
            });
        }
        this.state.queues = newQueues.sort((x,y) => alphabeticalSort(x,y,''));
    }

    componentWillMount = () => {
        this.getQueues();

        const storedQueue    = localStorage.getItem("last_template_queue")
        const storedNumber   = localStorage.getItem("last_template_number")
        const storedTemplate = localStorage.getItem("last_template_name") ?? ""
        this.setState({
            filteredTemplates : this.props.templates,
            queue             : storedQueue,
            originNumber      : storedNumber
        })
        this.onChangeOriginNumbers(storedNumber)
        this.handleStoredTemplate(storedTemplate)
    }

    handleFilterChange = (value) => {
        let templates = this.props.templates
        if (getSession().profile.enterprise.abstraction_layer)
            templates = this.state.numberTemplates
        this.setState({
            filteredTemplates: (templates.filter((template) => {
                const body = template.components?.find(each =>
                    each.type == 'BODY')?.text?.toLowerCase().includes(value.toLowerCase())
                const name = template.name?.toLowerCase().includes(value.toLowerCase())
                if (value && (body || name))
                    return true
                else if (value === "")
                    return true
            }))
        })
    }

    handleStoredTemplate = (value) => {
        let templates = this.props.templates
        if (getSession().profile.enterprise.abstraction_layer)
            templates = this.state.numberTemplates
        
        const template = templates.find((t) => t.name === value)
        const templatePosition = templates.findIndex((t) => t.name === value)
        if (templatePosition > -1) {
            const [foundTemplate] = templates.splice(templatePosition, 1)
            templates.unshift(foundTemplate)
        }

        this.setState({ selectedTemplate: template })
    }

    canSendTemplates = () => {

        if (this.state.showParameters){
            return this.state.allParamsFilled ? this.state.allParamsFilled : ''
        }

        let msg = []
        if (!this.props.selectionIds.length)
            msg.push(<Box>Selecione quais contatos receberão a mensagem.</Box>)
        if (this.state.queue === null)
            msg.push(<Box>Selecione a Fila.</Box>)
        if (this.state.originNumber === null)
            msg.push(<Box>Selecione o Número de origem.</Box>)
        if (this.state.selectedTemplate === null)
            msg.push(<Box>Selecione o Template.</Box>)
        if (msg.length == 0)
            return ''
        return (msg)
    }

    render = () => {
        return (
            <div>
                <Loading loading={this.state.loading} />
                <Dialog keepMounted
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    className={this.state.showParameters ? 'PreviewTemplateDialog' : null}
                    disablePortal
                    disableEnforceFocus
                    sx={{position:'absolute'}}
                >
                    <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                        <span>Enviar notificação aos clientes selecionados</span>
                        <Tooltip title="Fechar" >
                            <Button className="action-button"  >
                                <CloseIcon onClick={this.props.handleClose} />
                            </Button>
                        </Tooltip>
                    </DialogTitle>
                    <Divider />
                    <DialogContent 
                        ref={instance => { this.content = instance; }}
                    >
                        {!this.state.showParameters &&
                            <>
                                <Grid container spacing={3}
                                    className="selections-template"
                                >
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <FormControl
                                            fullWidth
                                            size="small"
                                        >
                                            <InputLabel id="demo-simple-select-label">Fila</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Fila"
                                                onChange={this.onChangeQueues}
                                                value={this.state.queue}
                                            >
                                                {this.state.queues.map((queue, index) => (
                                                    <MenuItem key={index} value={this.props.queues[queue]}>{queue}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <FormControl
                                            fullWidth
                                            size="small"
                                        >
                                            <InputLabel id="demo-simple-select-label">Número de origem</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Número de origem"
                                                onChange={(event) => this.onChangeOriginNumbers(event.target.value)}
                                                value={this.state.originNumber}
                                            >
                                                {this.props.originNumbers.map((number, index) => (
                                                    <MenuItem key={index} value={number}>{number}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {getSession().profile.enterprise.abstraction_layer && this.state.numberTemplates.length === 0 &&
                                    <>
                                        {this.state.numberLoading &&
                                            <Loading loading={this.state.numberLoading}/>
                                        }
                                        {! this.state.numberLoading &&
                                            <Box style={{ marginTop: '1rem', marginLeft: '1rem' }}>
                                                Selecione o número de origem na lista acima
                                            </Box>
                                        }
                                    </>
                                }
                                {getSession().profile.enterprise.abstraction_layer && this.state.numberTemplates.length !== 0 &&
                                    <>
                                        <TextField
                                            className={'inputPlaceholder'}
                                            placeholder='Buscar template'
                                            onChange={(event) => this.handleFilterChange(event.target.value)}
                                            variant="outlined"
                                            size="small"
                                            sx={{ marginBottom: '.4rem', backgroundColor: '#EFEFEF' }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            fullWidth
                                        />
                                        <List sx={{
                                            'overflow'     : 'auto',
                                            'maxHeight'    : '20rem',
                                            'border'       : '1px solid #C4C4C4',
                                            'borderRadius' :  '5px'
                                        }}>
                                            {this.numberTemplates?.length !== 0 && this.state.filteredTemplates && this.state.filteredTemplates?.length > 0 ?
                                                this.state.filteredTemplates.map((template, i) => (
                                                    <ListItem className={this.state.selectedTemplate == template
                                                        ? "template-item selected"
                                                        : "template-item"}
                                                        key={`${i}-template-list`}
                                                    >
                                                        <ListItemText primary={template.name}
                                                            secondary={
                                                                (template.components[0] &&
                                                                    template.components[0].type) == 'BODY' ?
                                                                    template.components[0].text :
                                                                    '[MIDIA] ' + template.components[1].text
                                                            }
                                                            onClick={() => this.setState({ selectedTemplate: template })} />
                                                    </ListItem>
                                                ))
                                                :
                                                <Box>Não há templates que correspondem a sua busca.</Box>
                                            }
                                        </List>
                                    </>
                                }
                                {! getSession().profile.enterprise.abstraction_layer &&
                                    <>
                                        <TextField
                                            className={'inputPlaceholder'}
                                            label={"Buscar Templates"}
                                            defaultValue=""
                                            sx={{ margin: '.5rem 0rem' }}
                                            onChange={(event) => this.handleFilterChange(event.target.value)}
                                            variant="standard"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <List sx={{
                                            'overflow': 'auto',
                                            'maxHeight': '30rem'
                                        }}>
                                            {this.props.templates?.length !== 0 && this.state.filteredTemplates && this.state.filteredTemplates?.length > 0 ?
                                                this.state.filteredTemplates.map((template, i) => (
                                                    <ListItem className={this.state.selectedTemplate == template
                                                        ? "template-item selected"
                                                        : "template-item"}
                                                        key={`${i}-template-list`}
                                                    >
                                                        <ListItemText primary={template.name}
                                                            secondary={
                                                                (template.components[0] &&
                                                                    template.components[0].type) == 'BODY' ?
                                                                    template.components[0].text :
                                                                    '[MIDIA] ' + template.components[1].text
                                                            }
                                                            onClick={() => this.setState({ selectedTemplate: template })} />
                                                    </ListItem>
                                                ))
                                                :
                                                <Box>Não há templates que correspondem a sua busca.</Box>
                                            }
                                        </List>       
                                    </>
                                }
                            </>
                        }
                        {this.state.showParameters &&
                            <Box>
                                <ParameterFiller
                                    mediaValues={this.state.selectedTemplate.components}
                                    parameters={this.state.parameters}
                                    submitParameters={this.state.mediaParameters}
                                    setReady={this.allParametersFilled}
                                />
                                {/* {this.state.selectedTemplate.components[0].text}
                                <h6 className="push-top">
                                    Preencha as variáveis do template abaixo:
                                </h6>
                                {this.state.parameters.map((parameter, i) => {
                                    const variableIndex = i + 1;
                                    return (
                                        <TextField
                                            size="small"
                                            label={"{{" + variableIndex + "}}"}
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            value={parameter.value}
                                            onChange={e => this.handleInputChange(e, i)}
                                            className="input-parameter"
                                        />
                                    )   
                                })} */}
                            </Box>
                        }
                    </DialogContent>
                    <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                        {this.state.showParameters &&
                            <Button
                                onClick={() => this.setState({
                                    selectedTemplate: null,
                                    showParameters: false,
                                    parameters: [],
                                    mediaParameters: {},
                                    allParamsFilled: true,
                                    originNumber: null,
                                    queue: null
                                })}
                                color="primary"
                            >
                                Voltar
                            </Button>
                        }
                        <LoadingButton
                            onClick={this.props.handleClose}
                            color="primary"
                            loading={this.state.loading}
                        >
                            Cancelar
                        </LoadingButton>
                        <Tooltip title={this.canSendTemplates()} placement="top" describeChild={true}>
                            <span><LoadingButton
                                onClick={() => this.submit()}
                                color="primary"
                                disabled={this.canSendTemplates()}
                                loading={this.state.loading}
                            >
                                Enviar
                            </LoadingButton></span>
                        </Tooltip>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

}

export default Templates;
