import React, { useMemo } from 'react'

import { Box } from '@mui/material'
import './styles.css'
import GraphToolbar from '../default-toolbar'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { graph_theme, convert_time, defaultNames } from '../default-toolbar'

const columns = graph => {
  const columns = []
  if (graph === 'agent')
    columns.push({ field: 'agent_name', headerName: 'Operador', flex: 1, renderCell: defaultNames })
  else columns.push({ field: 'queue_description', headerName: 'Fila', flex: 1, renderCell: defaultNames, forceQueue: true })
  columns.push({
    field: 'name',
    headerName: 'Etiqueta',
    flex: 1,
    renderCell: (params) => <Box className='graph-label-bubble' sx={{backgroundColor: params.row.color}}>{params.value}</Box>
  })
  columns.push({
    field: 'count',
    headerName: 'Quantidade',
    flex: .5
  })
  return columns
}

const groupedData = (data, option) => data.reduce((acc, item, index) => {
  // If the category doesn't exist in the accumulator, create it

  const category = option === 'agent' ? `${item.label_chat__name}_${item.agent_name}` : `${item.label_chat__name}_${item.queue_description}`

  if (!acc[category]) {
      acc[category] = { id: index+1, count: 0, items: [], name: item.label_chat__name, 
        color: item.label_chat__color, agent_name: item.agent_name, queue_description: item.queue_description };
  }
  // Increment the count and add the item to the category
  acc[category].count += 1;
  acc[category].items.push(item);
  return acc;
}, {});


const Labels = ({ data, option }) => {

  const rows = useMemo(()=>{
    return Object.values(groupedData(data.rows, option))
  }, [data.rows])

  return (
    <Box className='graph-main-divisor'>
      <Box className='graph-main-table'>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={graph_theme}>
            <DataGridPro
              density='compact'
              columns={columns(option)}
              rows={rows}
              hideFooter={true}
              components={{
                Toolbar: GraphToolbar
              }}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      </Box>
      <Box className='graph-main-dashboards'>
        <Box className='graph-time-rows'></Box>
      </Box>
    </Box>
  )
}

export default Labels
